import React, { useEffect } from 'react';
import { color } from 'theme';
import { Text } from 'Components/Text';
import {
  AddDescriptionButton,
  PoapDescriptionButton,
} from 'Components/Inputs/style';
import { SvgIcon } from 'Components/svg-icon';
import {
  CustomInput,
  InputWrapp,
} from 'Components/CreateInputModal/styles/style';
import { TableData } from 'Pages/form-builder/formShcema';
import { style } from 'Components/CreateInputModal/styles/styleReact';
import { inputFullType } from 'Components/CreateInputModal/index';

type InputProps = {
  typeInput: inputFullType;
  setValue: (val: string[]) => void;
  values: string[];
  edit?: boolean;
  defaultValues: TableData;
  descriptions: string[];
  setDescriptions: (val: string[]) => void;
  footerInputTexts: string[];
  setFooterInputTexts: (val: string[]) => void;
};

export const CheckboxInputSwitchElement = (props: InputProps) => {
  const correctDefaultValue = () => {
    const title = props.defaultValues.columns.map((i) => i.title);
    const dataSource = props.defaultValues.dataSource.map((i) => i.valueCol);
    return [...title, ...dataSource].flat();
  };

  useEffect(() => {
    props.setValue(correctDefaultValue());
  }, [props.defaultValues]);

  const handleAddDescription = () => {
    props.setValue([...props.values, '']);
  };

  const handleDeleteDescription = (index: number) => {
    props.setValue(props.values.filter((el, i) => index !== i));
  };

  const handleChangeInputCustom = (valuesInput: string, index: number) => {
    const currentValues = [...props.values];
    currentValues[index] = valuesInput;
    props.setValue([...currentValues]);
  };

  return (
    <>
      {props.values.map((el, index) => (
        <InputWrapp key={index}>
          <label htmlFor={`description${index}`} style={{ width: '100%' }}>
            <Text style={style.description} color={color.text}>
              answer
            </Text>
            <CustomInput
              id={`description${index}`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInputCustom(e.target.value, index)
              }
              value={el}
              type="text"
              placeholder="User's answer"
            />
          </label>

          {props.values.length > 1 && (
            <div>
              <AddDescriptionButton
                onClick={() => handleDeleteDescription(index)}
              >
                <SvgIcon
                  width={14}
                  height={14}
                  icon="delete"
                  color={color.primary}
                />
              </AddDescriptionButton>
            </div>
          )}
        </InputWrapp>
      ))}

      <div>
        <AddDescriptionButton onClick={handleAddDescription}>
          <PoapDescriptionButton>Add line</PoapDescriptionButton>
          <SvgIcon width={14} height={14} icon="plus" color={color.primary} />
        </AddDescriptionButton>
        <Text style={style.buttonText}>add more {props.typeInput}</Text>
      </div>
    </>
  );
};
