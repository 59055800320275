import styled from 'styled-components';
import { color, spacing } from 'theme';

export const HeaderHighlighter = styled.div`
  height: 2px;
  width: ${(p) => p.width}px;
  background-color: ${color.primary};
  margin-top: ${spacing.tiny};
  position: relative;
  &:after {
    position: absolute;
    content: '';
    display: block;
    top: -2px;
    left: -1px;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    border-right: 3px solid ${color.primary};
    transform: rotate(-50deg);
  }

  &:before {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    right: -1px;
    width: 0;
    height: 0;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    border-left: 3px solid ${color.primary};
    transform: rotate(-53deg);
  }
`;

export const FormProjectContainer = styled.div`
  margin-top: ${spacing.small};
  width: 100%;
`;

export const FooterNodeWrapper = styled.div`
  margin-top: ${spacing.smaller};
  align-items: center;
  justify-self: center;
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-gap: 20px;
  padding: ${spacing.tinyPlus};
  background-color: ${color.backgroundForms};
`;
