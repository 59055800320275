import React, { CSSProperties } from 'react';
import { format } from 'date-fns';
import { Text } from 'Components/Text';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { color, spacing } from 'theme';
import { AvatarParticipants, Subscriber } from 'Blocks/Project/styles';
import { URL } from 'Utils/Api';
import { IMessagesUser } from 'hooks/useChat/type';
import { AvatarContainer, Bubble } from './style';

interface IMessageProps {
  message: IMessagesUser;
}

export const Messages = ({ message }: IMessageProps) => {
  const currentUserEmail = useTypedSelector((state) => state.user.email);
  const me = !message.user.email || currentUserEmail === message.user.email;
  const time = format(new Date(message.time), 'hh:mm a');

  return (
    <div>
      <AvatarContainer me={me}>
        <Subscriber static={true}>
          {message.user && message.user.avatar ? (
            <AvatarParticipants
              alt="avatar"
              src={`${URL}/${message.user.avatar}`}
            />
          ) : (
            <Text
              style={{ textTransform: 'uppercase' }}
              color={color.background}
            >
              {message.user && message.user.name && message.user.surname
                ? `${message.user.name[0]}${message.user.surname[0]}`
                : ''}
            </Text>
          )}
        </Subscriber>
      </AvatarContainer>
      <Bubble me={me}>
        {message.user.name && (
          <Text
            preset="header4"
            text={`${message.user.name} ${message.user.surname}`}
            style={styles.text}
          />
        )}
        <Text
          preset="bodyRegularPale"
          text={message.message}
          color={color.textPale}
          style={styles.text}
        />
        <Text preset="bodyRegularPale" text={time} style={styles.time} />
      </Bubble>
    </div>
  );
};

const styles = {
  text: {
    marginTop: spacing.tiny,
  } as CSSProperties,
  time: {
    marginTop: spacing.tiny,
    width: '100%',
    textAlign: 'right',
  } as CSSProperties,
};
