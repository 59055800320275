import styled from 'styled-components';
import { color } from 'theme';

export const ButtonSubmit = styled.button`
  cursor: pointer;
  background: ${color.primary};
  border-radius: 10px;
  width: 100%;
  height: 40px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: ${color.background};
  border: none;
  outline: none;
  text-align: center;
`;

export const CheckboxHidden = styled.input.attrs({ type: 'checkbox' })``;

export const Form = styled.form`
  margin-top: 22px;
  width: 340px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const InformationForm = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 34px;
`;

export const ButtonForgotPassword = styled.div`
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const InputField = styled.input`
  box-shadow: inset 0 0 0 50px #fff;
  width: 100%;
  height: ${(props) => props.heightElement || 'content-box'};
  border-radius: 10px;
  font-size: 14px;
  color: ${color.secondary};
  padding: 10px 15px;
  margin-bottom: 20px;
  outline: none;
  border: 1px solid ${(p) => (!p.error ? '#E9EFF4;' : 'red')};
  :invalid {
    box-shadow: none;
  }
`;

export const KeepLogin = styled.label`
  margin-left: 7px;
  padding: 5px 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${color.secondary};
`;

export const InputFieldPassword = styled(InputField)`
  border: none;
  margin: 0;
`;
