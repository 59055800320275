import React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => {
  const { color, width, height } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.96083 13.3947L14.074 1.52077C15.6325 -0.00692409 18.1603 -0.00692409 19.7188 1.52077L21.3367 3.10674C22.8952 4.63444 22.8952 7.11231 21.3367 8.64L9.17576 20.5607C8.48038 21.2424 7.53759 21.6249 6.55352 21.6249H1.57318C1.11052 21.6249 0.738731 21.2512 0.750261 20.7979L0.875539 15.8718C0.900447 14.9408 1.28909 14.0532 1.96083 13.3947ZM20.1726 4.24788L18.5546 2.66191C17.6391 1.76445 16.1537 1.76445 15.2381 2.66191L14.2978 3.58369L19.2327 8.42012L20.1726 7.49886C21.0881 6.6014 21.0881 5.14535 20.1726 4.24788ZM3.12495 14.5358L13.1337 4.72483L18.0686 9.56126L8.0116 19.4196L7.87783 19.5397C7.50836 19.8431 7.04026 20.0111 6.5535 20.0111L2.41679 20.0105L2.52132 15.9131C2.53515 15.3961 2.75156 14.9018 3.12495 14.5358ZM23.25 20.8181C23.25 20.3724 22.8814 20.0112 22.4268 20.0112H13.8387L13.727 20.0185C13.3252 20.072 13.0155 20.4096 13.0155 20.8181C13.0155 21.2637 13.3841 21.625 13.8387 21.625H22.4268L22.5385 21.6176C22.9403 21.5642 23.25 21.2266 23.25 20.8181Z"
        fill={color || '#666666'}
      />
    </svg>
  );
};

export const Edit = React.memo(SvgComponent);
