import React, { CSSProperties } from 'react';
import { Text } from 'Components/Text';
import { WelcomeWrapper, WrapperHelper } from './style';
import { spacing } from 'theme';
import { useTypedSelector } from 'hooks/useTypedSelector';

export const Welcome = () => {
  const nameUser = useTypedSelector((state) => state.user.name);
  return (
    <WelcomeWrapper>
      <WrapperHelper>
        <Text style={paragraph} preset="header2">
          Welcome to the MSF Telehealth Toolkit Platform, {nameUser}!
        </Text>
        <Text style={paragraph} preset="header3">
          What is the purpose of this Toolkit?
        </Text>

        <Text style={paragraph}>
          The MSF Telehealth Toolkit was launched in August 2020 as a
          collaborative effort between OCG, MSF Canada&apos;s Telemedicine team
          and the MSF Sweden Innovation Unit. The objective of this project is
          to provide resources to support the effective implementation of
          Telehealth Hotlines, Call Centres and other forms of telehealth
          two-way communication. The toolkit was informed by interviews with MSF
          experts from across the movement, both field and OC based, and
          research into best practices externally.
        </Text>
        <Text style={paragraph}>
          The MSF Telehealth Toolkit contains three main sections that were
          informed by MSF teams who have previously worked on these types of
          interventions:
        </Text>
        <Text style={paragraph} preset="header4">
          Pre-launch - e.g. is a telehealth solution even relevant to the
          challenge you have identified in the field?
        </Text>
        <Text style={paragraph} preset="header4">
          Implementation - e.g. what type of training and guidance should be
          delivered to support a successful telehealth implementation?
        </Text>
        <Text style={paragraph} preset="header4">
          Evaluation - e.g. how to measure if your telehealth intervention is
          reaching the target cohort?
        </Text>
        <Text style={paragraph}>
          We have created this online platform to help field teams move through
          this process in the easiest possible manner, collaborate with your
          colleagues, and access the support you need from different MSF experts
          along the way.
        </Text>
        <Text style={paragraph}>
          If you have already been invited to join a project this will appear
          under the ‘My Projects’ section. If not you can create your own and
          invite you team to join.
        </Text>
        <Text style={paragraph}>
          If you have any questions before or during this process please feel
          free to contact the toolkit Team on the right or on
          siu@stockholm.msf.org.
        </Text>
        <Text style={paragraph}>
          You can also view all background information on the MSF SharePoint
          here including a short introductory video.
        </Text>
        <Text style={paragraph}>
          Below you will find any similar projects that are in progress or
          completed to give you a better idea of how your coleagues in different
          missions have approached challenges similar to the ones you face.
        </Text>
      </WrapperHelper>
    </WelcomeWrapper>
  );
};

const paragraph = {
  fontSize: '18px',
  lineHeight: '1.8em',
  marginTop: spacing.small,
} as CSSProperties;
