import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Progress } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { color, spacing } from 'theme';
import {
  getAllMessagesForProject,
  saveAllProjects,
  setCurrentProjects,
  updateStatusProject,
} from 'Redux/projects/thunk';
import { useTypedSelector } from 'hooks/useTypedSelector';
import {
  changeReadyAsync,
  editForms,
  editFormSendToBack,
  saveForm,
} from 'Redux/form/thunk';
import { LeftSiderShown } from 'Router/AppRoute';
import { useChat } from 'hooks/useChat';
import { Text } from 'Components/Text';
import { SvgIcon } from 'Components/svg-icon';
import { Chat } from 'Components/Chat';
import { Modal } from 'Components/Modal/Modal';
import { ModalAreUSure } from 'Components/Inputs/ModalDelete';
import { ModalCreateStep } from 'Components/ModalCreateStep';
import { Document } from './document';
import { IntroductionContent } from './introduction';
import { DocumentPaginator } from './DocumentPaginator';
import {
  BackButton,
  ButtonComplete,
  ComponentsWrapper,
  ComponentsWrapperCenter,
  FormWrapper,
  LeftSider,
  LeftSiderContainer,
  NameProject,
  NavTitle,
  NeedHelp,
  PageProjectContainer,
  ProgressProcent,
  ProgressProcentWrapper,
  ProgressTitle,
  RightSider,
  Stage,
  TextNeedHelp,
} from './style.js';

export interface MatchParams {
  projectID: string;
}

export const CreateProject: React.FC = () => {
  const match = useRouteMatch<MatchParams>('/project/:projectID');
  const history = useHistory();
  const [startPressed, setStartPressed] = useState(false);
  const [modalCompleteProject, setModalCompleteProject] = useState(false);
  const [modalCreateStep, setModalCreateStep] = useState(false);
  const { show, hide } = useContext(LeftSiderShown);
  const { forms, currentForm } = useTypedSelector((state) => state.formBuilder);
  const currentUser = useTypedSelector((state) => state.user);
  const { admin } = useTypedSelector((state) => state.user);
  const { projects, currentProjects } = useTypedSelector(
    (state) => state.projects,
  );
  const formsCurrent = useTypedSelector(
    (state) => state.formBuilder.currentForm,
  );

  const {
    msg: messagesChat,
    sendMessage,
    messagesMissed,
    setMessageMissed,
    editChange,
  } = useChat();

  const dispatch = useDispatch();

  useEffect(() => {
    hide();
    return () => {
      show();
    };
  }, []);

  useEffect(() => {
    dispatch(saveAllProjects({ history: (s) => history.push(s) }));
  }, []);

  useEffect(() => {
    const currentP = projects.filter(
      (x) => x.id === Number(match?.params.projectID),
    )[0];
    let intervalMissMessages: ReturnType<typeof setTimeout>;
    if (currentP) {
      dispatch(
        setCurrentProjects({
          project: currentP,
          history: (s) => history.push(s),
        }),
      );

      const intervalMissMessagesFuncrion = () => {
        intervalMissMessages = setInterval(() => {
          dispatch(
            getAllMessagesForProject({
              history: (s) => history.push(s),
              setMessagesMissed: (s) => setMessageMissed(s),
              projectId: currentP.id,
            }),
          );
        }, 1000 * 60);
      };
      intervalMissMessagesFuncrion();
    }

    () => {
      clearInterval(intervalMissMessages);
    };
  }, [projects]);

  const isMissedMessages = (string: string): number => {
    if (messagesMissed) {
      const s = messagesMissed.filter((x) => x.chatId === string)[0];
      return s ? s.messageStatus : 0;
    }
    return -1;
  };

  const handleClickToChangeForm = (id: number) => {
    if (
      startPressed &&
      (!formsCurrent.isEditing.edit ||
        (formsCurrent.isEditing.edit &&
          formsCurrent.isEditing.user.id !== currentUser.id))
    ) {
      dispatch(
        saveForm({
          projectId: currentProjects.id,
          pieceId: id,
          history: (s) => history.push(s),
        }),
      );
    }
  };

  const handleChangeReady = () => {
    if (currentProjects.status === 'complete') return;
    dispatch(
      changeReadyAsync({
        history: (s) => history.push(s),
        id: currentForm.id,
        projectId: currentProjects.id,
        isDone: !currentForm.isDone,
      }),
    );
  };

  const handleChangeEdit = () => {
    if (!formsCurrent.isEditing.edit) {
      editChange(true, currentForm.isEditing.id);
      dispatch(
        editForms({
          isEditingId: currentForm.isEditing.id,
          history: (s) => history.push(s),
          projectId: currentProjects.id,
          id: currentForm.id,
          user: currentUser,
          isEditing: true,
        }),
      );
    } else {
      if (!currentProjects?.id) return;

      editChange(false, currentForm.isEditing.id);
      dispatch(
        editFormSendToBack({
          user: currentUser,
          history: (s) => history.push(s),
          step: currentForm,
          currentProjectsId: currentProjects.id,
        }),
      );
    }
  };

  const isProgress = () => {
    const number = (forms.filter((x) => x.isDone).length * 100) / forms.length;
    return Math.round(number);
  };

  const handleCloseModalClickCompleteProject = useCallback(() => {
    setModalCompleteProject(true);
  }, [modalCompleteProject]);

  const handleClickCompleteProject = () => {
    dispatch(
      updateStatusProject({
        projectId: currentProjects.id,
        status: 'complete',
        history: (s) => history.push(s),
      }),
    );
  };

  const handleCloseModalFroAddNewStep = useCallback(() => {
    setModalCreateStep(true);
  }, [modalCreateStep]);

  return (
    <>
      <Modal
        toggle={() => setModalCompleteProject(!modalCompleteProject)}
        isModalVisible={modalCompleteProject}
        title={''}
        childrenContent={
          <ModalAreUSure
            text="Are you sure you want to complete this project? This action cannot be undone."
            toggle={() => setModalCompleteProject(false)}
            actionTrue={handleClickCompleteProject}
          />
        }
      />

      <Modal
        toggle={() => setModalCreateStep(!modalCreateStep)}
        isModalVisible={modalCreateStep}
        title={''}
        childrenContent={
          <ModalCreateStep toggle={() => setModalCreateStep(false)} />
        }
      />
      <PageProjectContainer>
        <LeftSider>
          <div>
            <ComponentsWrapper>
              <BackButton to="/">
                <SvgIcon icon="back" color={color.text} size={14} /> Back
              </BackButton>
            </ComponentsWrapper>
            <ComponentsWrapperCenter>
              {currentProjects && (
                <NameProject>{currentProjects.name}</NameProject>
              )}
              <ProgressProcentWrapper>
                <ProgressTitle>Complete</ProgressTitle>
                <ProgressProcent>
                  {!isNaN(isProgress()) && isProgress()}%
                </ProgressProcent>
              </ProgressProcentWrapper>
              <Progress
                showInfo={false}
                percent={isProgress()}
                status={'active'}
                strokeColor={color.primary}
              />
              {isProgress() === 100 && (
                <ButtonComplete
                  disabled={
                    currentProjects && currentProjects.status === 'complete'
                  }
                  onClick={handleCloseModalClickCompleteProject}
                >
                  Project complete
                </ButtonComplete>
              )}
            </ComponentsWrapperCenter>
            <LeftSiderContainer>
              <Text
                preset="subtitle"
                text="Project Overview"
                color={color.primary}
                style={{
                  marginTop: spacing.smaller,
                  marginBottom: spacing.small,
                }}
              />
              <Stage>
                <NavTitle>Pre-launch</NavTitle>
                {forms
                  .filter((x) => x.content.name === 'Pre-Launch')
                  .map((step) => (
                    <Document
                      missedMessage={isMissedMessages(
                        `${currentProjects ? currentProjects.id : 0}-${
                          step ? step.id : 0
                        }`,
                      )}
                      pieceId={step.id}
                      current={formsCurrent.id === step.id}
                      ready={step.isDone}
                      changeForm={() => handleClickToChangeForm(step.id)}
                      title={step.content.title.head}
                      key={step.id}
                    />
                  ))}
              </Stage>

              <Stage>
                <NavTitle>Implementation</NavTitle>
                {forms
                  .filter((x) => x.content.name === 'Implementation')
                  .map((step) => (
                    <Document
                      missedMessage={isMissedMessages(
                        `${currentProjects ? currentProjects.id : 0}-${
                          step ? step.id : 0
                        }`,
                      )}
                      pieceId={step.id}
                      current={formsCurrent.id === step.id}
                      ready={step.isDone}
                      changeForm={() => handleClickToChangeForm(step.id)}
                      title={step.content.title.head}
                      key={step.id}
                    />
                  ))}
              </Stage>

              <Stage>
                <NavTitle>Monitoring/Eval</NavTitle>
                {forms
                  .filter((x) => x.content.name === 'Monitoring & Evaluation')
                  .map((step) => (
                    <Document
                      missedMessage={isMissedMessages(
                        `${currentProjects ? currentProjects.id : 0}-${
                          step ? step.id : 0
                        }`,
                      )}
                      pieceId={step.id}
                      current={formsCurrent.id === step.id}
                      ready={step.isDone}
                      changeForm={() => handleClickToChangeForm(step.id)}
                      title={step.content.title.head}
                      key={step.id}
                    />
                  ))}
              </Stage>

              <Stage>
                <NavTitle>Other</NavTitle>
                {forms
                  .filter(
                    (x) =>
                      x.content.name !== 'Monitoring & Evaluation' &&
                      x.content.name !== 'Implementation' &&
                      x.content.name !== 'Pre-Launch',
                  )
                  .map((step) => (
                    <Document
                      missedMessage={
                        currentProjects
                          ? isMissedMessages(`${currentProjects.id}-${step.id}`)
                          : 0
                      }
                      pieceId={step.id}
                      current={formsCurrent.id === step.id}
                      ready={step.isDone}
                      changeForm={() => handleClickToChangeForm(step.id)}
                      title={step.content.title.head}
                      key={step.id}
                    />
                  ))}
              </Stage>
              {admin && (
                <ButtonComplete onClick={handleCloseModalFroAddNewStep}>
                  Add new step
                </ButtonComplete>
              )}
            </LeftSiderContainer>
          </div>
          <NeedHelp>
            <div />
            <TextNeedHelp>Need some help?</TextNeedHelp>
          </NeedHelp>
        </LeftSider>

        <FormWrapper>
          {!startPressed ? (
            <IntroductionContent setStartPressed={setStartPressed} />
          ) : (
            <DocumentPaginator
              setEdit={handleChangeEdit}
              setReady={handleChangeReady}
            />
          )}
          <RightSider>
            <Chat
              messagesChat={messagesChat}
              sendMessage={(s) => sendMessage(s)}
              startPressed={startPressed}
            />
          </RightSider>
        </FormWrapper>
      </PageProjectContainer>
    </>
  );
};
