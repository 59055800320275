import styled from 'styled-components';
import { color } from 'theme';

export const Arrow = styled.span`
  border-radius: 2px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  border-color: ${(p) => (p.current ? color.primary : color.background)};
  transform: rotate(45deg);
  ${(props) => {
    return props.ready
      ? 'width: 4px; height: 7px;'
      : 'width: 6px;  height: 10px;';
  }};
`;

export const Checkbox = styled.div`
  border: 1px solid red;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 5px;
  background: ${(p) => p.colorBg};
  border: 1px solid ${(p) => p.colorBorder || p.colorBg};
  width: ${(p) => p.size};
  height: ${(p) => p.size};
  margin-left: ${(p) => (p.ready ? '5px' : '0')};
`;
