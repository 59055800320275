import React, { useMemo, useState } from 'react';
import { Text } from 'Components/Text';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { Map } from 'Components/Map';
import { TableProjectWrapper } from './style';
import { ProjectTable } from './ProjectTable';

const defaultMapWidth = 587;
const paddingForSmallerScreen = 100;

export interface IProjectHover {
  code: string;
  name: string;
}

export const Interventions = () => {
  const [projectHover, setProjectHover] = useState<IProjectHover>({
    code: '',
    name: '',
  });
  const { width } = useWindowDimensions();

  const mapWidth = useMemo(() => {
    if (width > defaultMapWidth) {
      return defaultMapWidth;
    }
    return width - paddingForSmallerScreen;
  }, [width]);

  return (
    <>
      <Text preset="header2" text="MSF Telehealth Interventions Overview" />
      <TableProjectWrapper>
        <ProjectTable
          projectHover={projectHover}
          setProjectHover={(s) => setProjectHover(s)}
        />
        <Map projectHover={projectHover} />
      </TableProjectWrapper>
    </>
  );
};
