import React, { CSSProperties, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Select } from 'antd';
import { color } from 'theme';
import { ModalComponent } from 'Blocks/Modal';
import { createInputs, createTable } from 'Redux/form/thunk';
import {
  inputTypes,
  optionsInput,
  TableData,
} from 'Pages/form-builder/formShcema';
import { Text } from 'Components/Text';
import { CreateCheckbox } from 'Components/ItselfInput/CreateCheckbox';
import { ButtonCreate, ButtonCreateInput, CustomInput } from './style';
import { CreateTable } from './CreateTable';

const Option = Select.Option;

type TPropsModalWrapper = {
  closeModal: () => void;
};

export const FormCreateInput = ({ closeModal }: TPropsModalWrapper) => {
  const [typeInput, setTypeInput] = useState<inputTypes>();
  const [valueInput, setValueInput] = useState<string[]>([]);
  const [table, setTable] = useState<TableData>({
    columns: [
      { title: '', dataIndex: 'col1', key: 'col1' },
      { title: '', dataIndex: 'col2', key: 'col2' },
    ],
    dataSource: [],
  });
  const dispatch = useDispatch();

  const handleClickToCreateInputs = () => {
    if (!typeInput) return;
    if (typeInput === optionsInput.table) {
      dispatch(
        createTable({
          type: typeInput,
          tableData: table,
          descriptions: [''],
          footerInputTexts: [''],
        }),
      );
    } else {
      dispatch(
        createInputs({
          type: typeInput,
          values: valueInput,
          descriptions: ['descriptions'],
          footerInputTexts: ['footerInputTexts'],
        }),
      );
    }
    setValueInput([]);
    setTypeInput('Input');
    closeModal();
  };

  const handleChangeInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    valueInput[index] = e.target.value;
    setValueInput(valueInput);
  };

  const handleSelect = (value: inputTypes) => {
    setValueInput([]);
    setTypeInput(value);
  };

  return (
    <div>
      <Select
        style={style.select}
        placeholder="Select type"
        onChange={handleSelect}
      >
        <Option key={12} value={''}>
          <Text style={style.options}>default</Text>
        </Option>
        {Object.values(optionsInput).map((el: inputTypes, index) => (
          <Option key={index} value={el}>
            <Text style={style.options}>{el}</Text>
          </Option>
        ))}
      </Select>
      {(typeInput === optionsInput.checkbox ||
        typeInput === optionsInput.switch) && (
        <CreateCheckbox
          values={valueInput}
          setValue={(v: string[]) => setValueInput(v)}
        />
      )}
      {typeInput === optionsInput.input && (
        <label htmlFor={'description'}>
          <Text style={style.description} color={color.text}>
            placeholder
          </Text>
          <CustomInput
            id={'description'}
            type="text"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeInput(e, 0)
            }
          />
        </label>
      )}
      {typeInput === optionsInput.table && (
        <CreateTable
          table={table}
          setTable={(table: TableData) => setTable(table)}
        />
      )}

      <ButtonCreate
        onClick={handleClickToCreateInputs}
        style={{ width: '100%' }}
      >
        <Text>Create</Text>
      </ButtonCreate>
    </div>
  );
};

export const ItselfInput = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleClickToCloseButton = () => {
    setIsModalVisible(false);
  };
  return (
    <div>
      <ModalComponent
        toggle={() => setIsModalVisible(false)}
        title={'Create new input'}
        childrenContent={
          <FormCreateInput closeModal={handleClickToCloseButton} />
        }
        isModalVisible={isModalVisible}
      />
      <ButtonCreateInput onClick={() => setIsModalVisible(!isModalVisible)}>
        create input
      </ButtonCreateInput>
    </div>
  );
};

export const style = {
  select: {
    width: '100%',
    marginBottom: '20px',
    borderRadius: '5px',
    color: color.secondary,
    display: 'flex',
    alignContent: 'center',
  } as CSSProperties,
  options: { display: 'inline-block' } as CSSProperties,
  description: { fontWeight: 'bold', marginBottom: '7px' } as CSSProperties,
  buttonText: {
    display: 'inline-block',
    marginLeft: '4px',
  } as CSSProperties,
};
