import React, { useEffect, useMemo } from 'react';
import { Text } from 'Components/Text';
import { NewProjectButton } from 'Components/NewProject';
import { Project } from 'Blocks/Project';
import { Page } from 'Components/Page';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { saveAllProjects } from 'Redux/projects/thunk';
import { ProjectWrapper } from './style';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

export const Dashboard = () => {
  const { width } = useWindowDimensions();
  const projects = useTypedSelector((state) => state.projects.projects);
  const history = useHistory();
  const dispatch = useDispatch();

  const pagePadding = useMemo(() => (width < 576 ? 0 : 25), [width]);

  useEffect(() => {
    dispatch(saveAllProjects({ history: (s) => history.push(s) }));
  }, []);

  return (
    <Page padding={pagePadding} style={{ height: '100%' }}>
      <Text preset="header1">Your Telehealth Projects</Text>
      <NewProjectButton />
      <ProjectWrapper>
        {projects.map((project) => (
          <Project project={project} key={project.id} />
        ))}
      </ProjectWrapper>
    </Page>
  );
};
