import React from 'react';
import { Table } from 'antd';
import { color } from 'theme';
import { TableData } from './formShcema';

interface TableProps {
  tableData: TableData | undefined;
  edit: boolean;
}

interface IData {
  key: string;
  value?: string | boolean;
  col1: string;
  col2: string;
  col3?: string;
  col4?: string;
}

export const TableRender = ({ tableData, edit }: TableProps) => {
  const dataSourceCurrect = tableData?.dataSource.map((x) => {
    const data: IData = {
      key: x.key,
      col1: '',
      col2: '',
    };
    x.valueCol.forEach((y, index) => {
      if (index === 0) data.col1 = y;
      if (index === 1) data.col2 = y;
      if (index === 2) data.col3 = y;
      if (index === 3) data.col4 = y;
    });
    return {
      ...data,
      color: color.backgroundSecondary,
      style: { textAlign: 'center' },
    };
  });

  const currentColumns = tableData?.columns.map((c) => ({
    ...c,
    render(text: any, record: any) {
      return {
        props: {
          style: { background: record.color, textAlign: 'center' },
        },
        children: <div>{text}</div>,
      };
    },
  }));

  return (
    <div
      style={{
        opacity: edit ? '1' : '0.7',
        marginTop: '20px',
        textAlign: 'center',
      }}
    >
      <Table
        dataSource={dataSourceCurrect}
        columns={currentColumns}
        pagination={false}
      />
    </div>
  );
};
