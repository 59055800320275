import React from 'react';
import * as yup from 'yup';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ButtonSubmit, Form, InputField } from 'Components/FormAuth/style';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { createNewStep } from 'Redux/form/thunk';

interface IModalCreateStepProps {
  toggle: () => void;
}

interface IFormModalCreateStep {
  position: number;
}

const schema = yup.object().shape({
  position: yup.number().required('this field is required'),
});

export const ModalCreateStep = ({ toggle }: IModalCreateStepProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: projectId } = useTypedSelector(
    (state) => state.projects.currentProjects,
  );

  const {
    register,
    handleSubmit,
    errors,
    control,
  } = useForm<IFormModalCreateStep>({
    resolver: yupResolver(schema),
  });

  const handleSubmitCreateStep = (value: IFormModalCreateStep) => {
    dispatch(
      createNewStep({
        projectId: projectId,
        position: value.position,
        closeModal: () => toggle(),
        history: (s) => history.push(s),
      }),
    );
  };

  return (
    <Form onSubmit={handleSubmit(handleSubmitCreateStep)}>
      <Controller
        control={control}
        name="position"
        render={() => (
          <InputField
            error={errors.position}
            placeholder="Position Step"
            type="position"
            name="position"
            ref={register}
            defaultValue=""
          />
        )}
      />
      <ButtonSubmit type="submit">Create new step</ButtonSubmit>
    </Form>
  );
};
