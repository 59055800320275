import React from 'react';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { ContainerItem, Item, LoadingContainer, LoadingWrapper } from './style';

export const Loading = () => {
  const loading = useTypedSelector((state) => state.loading.loading);
  return (
    <>
      {loading && (
        <LoadingContainer>
          <LoadingWrapper>
            <ContainerItem className="container">
              <Item className="object" />
            </ContainerItem>
            <ContainerItem className="container">
              <Item className="object" />
            </ContainerItem>
            <ContainerItem className="container">
              <Item className="object" />
            </ContainerItem>
            <ContainerItem className="container">
              <Item className="object" />
            </ContainerItem>
            <ContainerItem className="container">
              <Item className="object" />
            </ContainerItem>
            <ContainerItem className="container">
              <Item className="object" />
            </ContainerItem>
            <ContainerItem className="container">
              <Item className="object" />
            </ContainerItem>
            <ContainerItem className="container">
              <Item className="object" />
            </ContainerItem>
            <ContainerItem className="container">
              <Item className="object" />
            </ContainerItem>
            <ContainerItem className="container">
              <Item className="object" />
            </ContainerItem>
            <ContainerItem className="container">
              <Item className="object" />
            </ContainerItem>
            <ContainerItem className="container">
              <Item className="object" />
            </ContainerItem>
            <ContainerItem className="container">
              <Item className="object" />
            </ContainerItem>
            <ContainerItem className="container">
              <Item className="object" />
            </ContainerItem>
            <ContainerItem className="container">
              <Item className="object" />
            </ContainerItem>
            <ContainerItem className="container">
              <Item className="object" />
            </ContainerItem>
            <ContainerItem className="container">
              <Item className="object" />
            </ContainerItem>
            <ContainerItem className="container">
              <Item className="object" />
            </ContainerItem>
            <ContainerItem className="container">
              <Item className="object" />
            </ContainerItem>
            <ContainerItem className="container">
              <Item className="object" />
            </ContainerItem>
          </LoadingWrapper>
        </LoadingContainer>
      )}
    </>
  );
};
