import styled from 'styled-components';
import { color, spacing } from 'theme';

export const TableProjectWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 60vh;
  position: relative;
`;

export const InterventionsTable = styled.div`
  border-collapse: collapse;
  margin-top: 20px;
  margin-right: 20px;
`;

export const RowBodyWrapp = styled.div`
  height: 60vh;
  overflow-y: scroll;
`;

export const Cell = styled.div`
  cursor: ${(p) => (p.isCursor ? 'pointer' : 'auto')};
  padding: ${spacing.tinyMinus};
  background-color: ${(p) => (p.header ? color.primary : color.background)};
  color: ${(p) => (p.header ? color.background : color.text)};
  font-weight: ${(p) => (p.header ? '800' : '400')};
  width: 120px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(p) =>
    p.header ? 'none' : `1px solid ${color.backgroundPaginator}`};
  text-align: center;
`;

export const RowTable = styled.div`
  display: flex;

  &:hover ${Cell} {
    background-color: ${(p) => !p.header && color.backgroundPaginator};
    border: none;
  }
`;
