import React from 'react';
import { useHistory } from 'react-router';
import { AuthContainer } from 'Components/AuthContainer';
import { SingUpForm } from 'Components/FormAuth/singUpForm';
import { Text } from 'Components/Text';

const dataForm = {
  subtitle: 'Sign up at get started',
  button: 'Sign up',
};

export const SingUp = () => {
  const history = useHistory();
  return (
    <AuthContainer toLoginButton={true} toLogin={() => history.push('/login')}>
      <Text
        style={{
          fontSize: '14px',
          marginTop: '10px',
          textAlign: 'center',
        }}
        color="#666666"
      >
        {dataForm.subtitle}
      </Text>
      <SingUpForm buttonText={dataForm.button} />
    </AuthContainer>
  );
};
