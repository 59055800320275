import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={25} cy={25} r={25} fill="#E00" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.004 15c-2.934 0-5.312 2.389-5.312 5.335 0 2.947 2.378 5.335 5.312 5.335s5.313-2.388 5.313-5.335c0-2.946-2.379-5.335-5.313-5.335zm0 1.448a3.88 3.88 0 013.871 3.887 3.88 3.88 0 01-3.87 3.888 3.88 3.88 0 01-3.872-3.888 3.88 3.88 0 013.871-3.887zM22.83 27.82a16.72 16.72 0 00-2.34.331c-1.496.308-2.693.924-3.203 1.948a2.93 2.93 0 00-.007 2.515c.49 1.012 1.548 1.585 2.976 1.902l.256.053c.755.17 1.54.285 2.332.339.068.02.229.038.404.047l.144.005c.074.002.159.003.284.003 1.138.062 2.317.044 3.492-.056a15.658 15.658 0 001.88-.244l.467-.097c1.543-.305 2.698-.883 3.204-1.95a2.927 2.927 0 000-2.504c-.505-1.065-1.645-1.639-3.216-1.962a16.926 16.926 0 00-1.872-.291l-.461-.04a24.685 24.685 0 00-4.34 0zm4.214 1.442l.012.001c.724.05 1.443.152 2.153.304 1.166.24 1.958.639 2.208 1.167.189.398.189.86 0 1.26-.234.493-.945.874-1.973 1.107l-.224.048c-.724.161-1.44.265-2.162.315a24.06 24.06 0 01-3.343.052l-.394-.007a1.94 1.94 0 01-.287-.033 15.522 15.522 0 01-1.874-.249l-.352-.074c-1.17-.23-1.969-.63-2.228-1.165a1.474 1.474 0 01.001-1.251c.252-.506 1.098-.94 2.205-1.169a15.389 15.389 0 012.157-.305 23.304 23.304 0 014.1-.001z"
        fill="#fff"
      />
    </svg>
  );
}

export const NeedHelp = React.memo(SvgComponent);
