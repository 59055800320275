import React, { CSSProperties, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Input, Space } from 'antd';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { color, spacing } from 'theme';
import { Text } from 'Components/Text';
import {
  changePartDescriptionPoints,
  deleteFooterDescription,
  deleteFooterNode,
  deleteFormDescription,
  deleteInputDescription,
  deletePartDescriptionPoints,
  editFooterDescription,
  editFooterNode,
  editFormDescription,
  editInputDescription,
} from 'Redux/form/thunk';
import {
  AddDescriptionButton,
  ButtonIconWrapper,
  PoapDescriptionButton,
} from 'Components/Inputs/style';
import { SvgIcon } from 'Components/svg-icon';
import { Modal } from 'Components/Modal/Modal';
import { ModalAreUSure } from 'Components/Inputs/ModalDelete';

export type DescriptionTypeForm =
  | 'step'
  | 'input'
  | 'footer'
  | 'form'
  | 'headerPart'
  | 'footerNode';

export interface DescriptionsProps {
  description: string;
  id: {
    descriptionIndex: number;
    stepId: number;
    inputIndex: number;
  };
  type: DescriptionTypeForm;
  editForm: boolean;
  style?: CSSProperties;
  isEdit?: boolean;
  children?: React.ReactNode;
}

export const FormDescriptions = ({
  description,
  id,
  isEdit,
  type,
  editForm,
  style,
  children,
}: DescriptionsProps) => {
  const { admin } = useTypedSelector((state) => state.user);
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [idDeleteInput, setIsDeleteInput] = useState(false);
  const [content, setContent] = useState(description);

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.target.value);
  };

  const save = () => {
    setEdit(false);
    if (type === 'form') {
      dispatch(editFormDescription({ ...id, content }));
    } else if (type === 'input') {
      dispatch(
        editInputDescription({
          inputIndex: id.inputIndex,
          descriptionIndex: id.descriptionIndex,
          value: content,
        }),
      );
    } else if (type === 'footer') {
      dispatch(
        editFooterDescription({
          inputIndex: id.inputIndex,
          descriptionIndex: id.descriptionIndex,
          value: content,
        }),
      );
    } else if (type === 'footerNode') {
      dispatch(
        editFooterNode({
          footerIndex: id.descriptionIndex,
          inputIndex: id.stepId,
          value: content,
        }),
      );
    } else if (type === 'headerPart') {
      dispatch(
        changePartDescriptionPoints({
          value: content,
          valueNumber: id.descriptionIndex,
        }),
      );
    }
    setIsDeleteInput(false);
  };

  const deleteDescription = () => {
    setEdit(false);

    // dispatch different actions depends on what part of the form came from
    if (type === 'form') {
      dispatch(deleteFormDescription({ ...id }));
    } else if (type === 'input') {
      dispatch(deleteInputDescription({ ...id }));
    } else if (type === 'footerNode') {
      dispatch(deleteFooterNode());
    } else if (type === 'footer') {
      dispatch(deleteFooterDescription({ ...id }));
    } else if (type === 'headerPart') {
      dispatch(
        deletePartDescriptionPoints({
          valueNumber: id.descriptionIndex,
        }),
      );
    }
  };

  return (
    <>
      <Modal
        toggle={() => setIsDeleteInput(!idDeleteInput)}
        isModalVisible={idDeleteInput}
        title="Participants"
        childrenContent={
          <ModalAreUSure
            text=" Are you sure you want to delete this? This action cannot be undone."
            toggle={() => setIsDeleteInput(false)}
            actionTrue={deleteDescription}
          />
        }
      />

      <Space
        style={
          style || {
            display: 'flex',
            flexDirection: 'row',
          }
        }
      >
        {children}
        {!edit ? (
          <Space>
            <Text
              text={content}
              preset="bodyRegularPale"
              color={isEdit ? color.text : color.textPale}
              style={{ marginTop: spacing.tinyPlus }}
            />
            {admin && editForm && (
              <>
                <ButtonIconWrapper>
                  <AddDescriptionButton onClick={() => setEdit(true)}>
                    <PoapDescriptionButton>
                      Edit description
                    </PoapDescriptionButton>

                    <SvgIcon
                      width={12}
                      height={12}
                      icon="edit"
                      color={color.secondary}
                    />
                  </AddDescriptionButton>
                  <AddDescriptionButton onClick={() => setIsDeleteInput(true)}>
                    <PoapDescriptionButton>
                      Delete description
                    </PoapDescriptionButton>
                    <SvgIcon
                      width={12}
                      height={12}
                      icon="delete"
                      color={color.primary}
                    />
                  </AddDescriptionButton>
                </ButtonIconWrapper>
              </>
            )}
          </Space>
        ) : (
          <Space
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Input.TextArea
              value={content}
              onChange={onChange}
              autoSize
              style={style || { width: 330 }}
            />

            <AddDescriptionButton onClick={save}>
              <SvgIcon
                width={12}
                height={12}
                icon="edit"
                color={color.secondary}
              />
            </AddDescriptionButton>
          </Space>
        )}
      </Space>
    </>
  );
};
