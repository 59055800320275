import React from 'react';
import { Text } from 'Components/Text';
import { ButtonSubmit } from 'Components/FormAuth/style';
import { ButtonWrapper } from './style';

export interface IModalContentProps {
  toggle: () => void;
  actionTrue: () => void;
  text: string;
}

export const ModalAreUSure = ({
  toggle,
  actionTrue,
  text,
}: IModalContentProps) => {
  const currentActionTrue = () => {
    actionTrue();
    toggle();
  };

  return (
    <>
      <Text preset="bodyRegularPale">{text}</Text>
      <ButtonWrapper>
        <ButtonSubmit onClick={toggle}>No</ButtonSubmit>
        <ButtonSubmit onClick={currentActionTrue}>Yes</ButtonSubmit>
      </ButtonWrapper>
    </>
  );
};
