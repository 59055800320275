import React, { useState } from 'react'; // we need this to make JSX compile
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { color } from 'theme';
import { formatDistanceStrict } from 'date-fns';
import { ProjectProps } from 'Utils/interfaces';
import { Text } from 'Components/Text';
import { Modal } from 'Components/Modal/Modal';
import imgProject from 'Assets/images/project-img.png';
import { downloadProject } from 'Redux/projects/thunk';
import { SvgIcon } from 'Components/svg-icon';
import { setCurrentForm } from 'Redux/form/thunk';
import {
  ProjectWrapper,
  ProjectWrapperTop,
  ProjectWrapperBottom,
  ProjectWrapperBottomLeft,
  ProjectWrapperBottomRight,
  Title,
  SubscriberBlock,
  Time,
  SvgIconWrapper,
  SvgIconItem,
} from './styles.js';
import { ModalParticipants } from './ModalParticipants';
import { ParticipantsSubscriber } from './PartisipentsSubscriber';

export const Project = ({ project, small }: ProjectProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggle = () => setIsModalVisible(false);

  const handleClickForDownloadPdf = () => {
    if (project.status === 'complete') {
      dispatch(
        downloadProject({
          projectId: project.id,
          history: () => history.push('/login'),
        }),
      );
    }
  };

  const delayRedirect = (event: React.ChangeEvent<HTMLElement>) => {
    if (event.target.id !== 'noLink') {
      dispatch(setCurrentForm({ data: project.content }));
      history.push(`project/${project.id}`);
    }
  };

  return (
    <>
      {!small && (
        <Modal
          toggle={toggle}
          isModalVisible={isModalVisible}
          title="Participants"
          childrenContent={
            <ModalParticipants
              participants={project.participants || []}
              projectId={project.id || 0}
            />
          }
        />
      )}
      <ProjectWrapper>
        <ProjectWrapperTop image={imgProject} onClick={delayRedirect}>
          <SvgIconWrapper>
            <SvgIconItem height="20" id="noLink">
              <SvgIcon
                id="noLink"
                size={20}
                icon="setting"
                color={color.background}
              />
            </SvgIconItem>
            <SvgIconItem
              height="20"
              id="noLink"
              onClick={handleClickForDownloadPdf}
            >
              <SvgIcon
                id="noLink"
                size={20}
                icon="download"
                color={color.background}
              />
            </SvgIconItem>
          </SvgIconWrapper>
        </ProjectWrapperTop>
        <ProjectWrapperBottom>
          <ProjectWrapperBottomLeft>
            <Title to={`project/${project.id}`}>{project.name}</Title>
            <Time>
              {formatDistanceStrict(new Date(project.date), new Date(), {
                addSuffix: true,
              })}
            </Time>
          </ProjectWrapperBottomLeft>
          <ProjectWrapperBottomRight onClick={() => setIsModalVisible(true)}>
            {project.participants.map(
              (el, index) =>
                index < 3 && (
                  <ParticipantsSubscriber
                    key={el.id}
                    participant={el}
                    index={index}
                    small={small}
                    participantsLength={project.participants.length}
                  />
                ),
            )}

            {project.participants.length > 3 && (
              <SubscriberBlock small={small} left={small ? -30 : -36}>
                <Text color={color.background}>
                  {project.participants.length - 3}+
                </Text>
              </SubscriberBlock>
            )}
          </ProjectWrapperBottomRight>
        </ProjectWrapperBottom>
      </ProjectWrapper>
    </>
  );
};
