import styled from 'styled-components';
import { color } from 'theme';

export const CustomSelectWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const SelectField = styled.div`
  position: relative;
  box-shadow: inset 0 0 0 50px #fff;
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
  color: ${color.secondary};
  padding: 10px 15px;
  margin-bottom: 20px;
  outline: none;
  border: 1px solid ${(p) => (!p.error ? color.backgroundPaginator : 'red')};
  ${(p) => !p.border && 'border: none'};
`;

export const OptionsCustom = styled.div`
  width: 100%;
  z-index: 100;
  font-size: 14px;
  color: ${color.secondary};
  padding: 10px 15px;

  &:first-child {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  &:last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &:hover {
    background-color: ${color.backgroundPaginator};
  }
`;

export const OptionsCustomWrapper = styled.div`
  overflow: scroll;
  max-height: 200px;
  z-index: 100;
  display: ${(props) => (props.active ? 'block' : 'none')};
  position: absolute;
  top: 44px;
  left: 0;
  width: 100%;
  border-radius: 10px;
  background-color: ${color.background};
  box-shadow: 0 8px 10px gray;
`;
