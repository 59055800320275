import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { CreateCheckbox } from 'Components/ItselfInput/CreateCheckbox';
import { ButtonCreate } from 'Components/ItselfInput/style';
import { useDispatch } from 'react-redux';
import { Text } from 'Components/Text';
import { changeInput, changeTable } from 'Redux/form/thunk';
import {
  Input,
  inputTypes,
  optionsInput,
  TableData,
} from 'Pages/form-builder/formShcema';
import { CreateTable } from 'Components/ItselfInput/CreateTable';
import { style } from 'Components/ItselfInput';

const { Option } = Select;

interface IPropsInForm {
  input: Input;
  index: number;
  closeModal: () => void;
  footer: React.ReactNode;
  formDescription: React.ReactNode;
}

export const FormForEdit = ({
  input,
  index,
  closeModal,
  footer,
  formDescription,
}: IPropsInForm) => {
  const [inputData, setInputData] = useState<Input>({ ...input });
  const dispatch = useDispatch();
  useEffect(() => {
    if (input) {
      setInputData({ ...input });
    }
  }, [input]);
  const handleSelect = (value: inputTypes) => {
    if (
      inputData.type === optionsInput.table &&
      (value === optionsInput.input ||
        value === optionsInput.checkbox ||
        value === optionsInput.switch)
    ) {
      if (!inputData.tableData) return;
      setInputData({
        ...inputData,
        values: [
          ...inputData.tableData.columns.map((x) => ({
            label: x.title,
            value: false,
          })),
        ],
        type: value,
      });
      return;
    } else if (
      value === optionsInput.table &&
      (inputData.type === optionsInput.input ||
        inputData.type === optionsInput.checkbox ||
        inputData.type === optionsInput.switch)
    ) {
      setInputData({
        ...inputData,
        values: [{ label: 'table', value: '' }],
        tableData: {
          dataSource: [{ key: '1', valueCol: inputData.values.map(() => '') }],
          columns: inputData.values.map((x, index) => ({
            title: x.label,
            key: `col${index + 1}`,
            dataIndex: `col${index + 1}`,
          })),
        },
        type: value,
      });
      return;
    } else {
      setInputData({
        ...inputData,
        type: value,
      });
    }

    return;
  };

  const handleClickToChangeInputs = () => {
    const values = [...inputData.values];
    if (inputData.type === optionsInput.table) {
      if (!inputData.tableData) return;
      dispatch(changeTable({ inputIndex: index, data: inputData.tableData }));
      closeModal();
      return;
    }
    const data = { ...inputData, values: values };
    dispatch(changeInput({ inputIndex: index, data: data }));
    closeModal();
  };

  return (
    <div>
      {formDescription}
      <Select
        style={style.select}
        placeholder="Select type"
        defaultValue={inputData.type}
        onChange={handleSelect}
      >
        <Option key={12} value={''}>
          <Text style={style.options}>default</Text>
        </Option>
        {Object.values(optionsInput).map((el: inputTypes, index) => (
          <Option key={index} value={el}>
            <Text style={style.options}>{el}</Text>
          </Option>
        ))}
      </Select>

      {(inputData.type === optionsInput.checkbox ||
        inputData.type === optionsInput.switch ||
        inputData.type === optionsInput.input) && (
        <CreateCheckbox
          key={index}
          values={Object.values(inputData.values.map((x) => x.label))}
          setValue={(v: string[]) =>
            setInputData({
              ...inputData,
              values: v.map((x) => ({ label: x, value: false })),
            })
          }
        />
      )}
      {inputData.type === optionsInput.table && (
        <CreateTable
          table={inputData.tableData}
          setTable={(table: TableData) =>
            setInputData({ ...inputData, tableData: table })
          }
        />
      )}
      {footer}
      <ButtonCreate
        onClick={handleClickToChangeInputs}
        style={{ width: '100%' }}
      >
        <Text>Change</Text>
      </ButtonCreate>
    </div>
  );
};
