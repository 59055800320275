import React from 'react';
import { Text } from 'Components/Text';
export const Graph = () => {
  return (
    <>
      <Text preset="header1">Graph</Text>
      <Text preset="header2" color="red" text="Graph" />
    </>
  );
};
