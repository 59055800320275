import React from 'react';
import { color } from 'theme';
import { Text } from 'Components/Text';
import {
  AddDescriptionButton,
  PoapDescriptionButton,
} from 'Components/Inputs/style';
import { SvgIcon } from 'Components/svg-icon';
import {
  CustomInput,
  InputWrapp,
} from 'Components/CreateInputModal/styles/style';
import { style } from 'Components/CreateInputModal/styles/styleReact';

type HeaderFooterElementProps = {
  setValue: (val: string[]) => void;
  values: string[];
  label: string;
  placeholder: string;
  title: string;
};

export const HeaderFooterElement = (props: HeaderFooterElementProps) => {
  const handleAddDescription = () => {
    props.setValue([...props.values, '']);
  };

  const handleDeleteDescription = (index: number) => {
    props.setValue(props.values.filter((el, i) => index !== i));
  };

  const handleChangeInputCustom = (valuesInput: string, index: number) => {
    const currentValues = [...props.values];
    currentValues[index] = valuesInput;
    props.setValue([...currentValues]);
  };

  return (
    <>
      {props.values.map((el, index) => (
        <InputWrapp key={index}>
          <label htmlFor={`description${index}`} style={{ width: '100%' }}>
            <Text style={style.description} color={color.text}>
              {props.title}
            </Text>
            <CustomInput
              id={`description${index}`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInputCustom(e.target.value, index)
              }
              value={el}
              type="text"
              placeholder="User's answer"
            />
          </label>

          {props.values.length > 1 && (
            <div>
              <AddDescriptionButton
                onClick={() => handleDeleteDescription(index)}
              >
                <SvgIcon
                  width={14}
                  height={14}
                  icon="delete"
                  color={color.primary}
                />
              </AddDescriptionButton>
            </div>
          )}
        </InputWrapp>
      ))}

      <div>
        <AddDescriptionButton onClick={handleAddDescription}>
          <PoapDescriptionButton>{props.label}</PoapDescriptionButton>
          <SvgIcon width={14} height={14} icon="plus" color={color.primary} />
        </AddDescriptionButton>
        <Text style={style.buttonText}>{props.placeholder}</Text>
      </div>
    </>
  );
};
