import React, { useEffect, useRef } from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IMessagesUser } from 'hooks/useChat/type';
import { Messages } from './Messages';
import {
  ChatForm,
  ChatInputField,
  ContainerChat,
  MessageContainer,
  MessageWrapper,
  TitleChat,
  WrapperChat,
} from './style';

const schemaText = yup.object().shape({
  text: yup.string().required(),
});

interface IChatText {
  text: string;
}

interface IChatProps {
  startPressed: boolean;
  messagesChat: IMessagesUser[];
  sendMessage: (s: string) => void;
}

export const Chat = ({
  startPressed,
  messagesChat,
  sendMessage,
}: IChatProps) => {
  const { register, handleSubmit, control, reset } = useForm<IChatText>({
    resolver: yupResolver(schemaText),
    defaultValues: { text: '' },
  });

  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current === null) return;
    else
      messagesEndRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
  };

  useEffect(() => scrollToBottom(), [messagesChat]);

  const handlerSendText = (value: IChatText) => {
    if (startPressed) {
      sendMessage(value.text);
      reset();
    }
  };

  return (
    <ContainerChat>
      <TitleChat>Collaboration</TitleChat>
      <WrapperChat>
        <MessageContainer>
          {messagesChat && startPressed && (
            <MessageWrapper>
              {messagesChat.map((message) => (
                <Messages key={message.id} message={message} />
              ))}
              <div ref={messagesEndRef} />
            </MessageWrapper>
          )}
        </MessageContainer>
        <ChatForm onSubmit={handleSubmit(handlerSendText)}>
          <Controller
            control={control}
            name="text"
            render={() => (
              <ChatInputField
                placeholder="Type here to chat with your team"
                type="text"
                name="text"
                ref={register}
              />
            )}
          />
        </ChatForm>
      </WrapperChat>
    </ContainerChat>
  );
};
