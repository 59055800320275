import React, { CSSProperties, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { color, spacing } from 'theme';
import { useTypedSelector } from 'hooks/useTypedSelector';
import {
  AddDescriptionButton,
  PoapDescriptionButton,
} from 'Components/Inputs/style';
import { deleteStep, saveForm } from 'Redux/form/thunk';
import { ModalAreUSure } from 'Components/Inputs/ModalDelete';
import { Modal } from 'Components/Modal/Modal';
import { SvgIcon } from 'Components/svg-icon';
import { CheckboxArrow } from 'Components/CheckboxArrow/CheckboxArrow';
import { Text } from 'Components/Text';
import {
  DocumentContainer,
  DocumentTitle,
  DocumentWrapper,
  MissMessagesWrapper,
  StepText,
  WrapperTitle,
} from './style.js';

interface DocumentProps {
  title: string;
  ready?: boolean;
  current?: boolean;
  changeForm?: () => void;
  pieceId: number;
  missedMessage?: number;
}

export const Document = ({
  title,
  current,
  ready,
  changeForm,
  pieceId,
  missedMessage,
}: DocumentProps) => {
  const admin = useTypedSelector((state) => state.user.admin);
  const project = useTypedSelector((state) => state.projects.currentProjects);
  const currentForm = useTypedSelector(
    (state) => state.formBuilder.currentForm,
  );
  const [idDeleteStep, setIsDeleteStep] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const colorText = () => {
    if (current) return color.background;
    if (ready) return color.textDarkGrey;
    return color.text;
  };

  const handleDeleteStep = () => {
    if (pieceId === currentForm.id) {
      dispatch(
        saveForm({
          history: (s) => history.push(s),
          projectId: project.id + 1,
          pieceId: pieceId,
        }),
      );
    }
    dispatch(
      deleteStep({
        history: (s) => history.push(s),
        projectId: project.id,
        pieceId: pieceId,
      }),
    );
  };

  return (
    <>
      <Modal
        toggle={() => setIsDeleteStep(!idDeleteStep)}
        isModalVisible={idDeleteStep}
        title="Participants"
        childrenContent={
          <ModalAreUSure
            text={
              ' Are you sure you want to delete this? This action cannot be undone.'
            }
            toggle={() => setIsDeleteStep(false)}
            actionTrue={handleDeleteStep}
          />
        }
      />
      <DocumentContainer>
        {admin && (
          <AddDescriptionButton onClick={() => setIsDeleteStep(true)}>
            <PoapDescriptionButton>Delete step</PoapDescriptionButton>
            <SvgIcon
              width={14}
              height={14}
              icon="delete"
              color={color.primary}
            />
          </AddDescriptionButton>
        )}
        <DocumentWrapper
          role="button"
          tabIndex={0}
          onClick={changeForm}
          onKeyDown={changeForm}
          current={current}
        >
          <DocumentTitle>
            {current && (
              <SvgIcon
                icon="back"
                size={14}
                color={color.background}
                style={styles.svgIcon}
              />
            )}
            <WrapperTitle>
              <StepText color={colorText()}>{title}</StepText>
            </WrapperTitle>
          </DocumentTitle>
          {ready && (
            <CheckboxArrow
              size="12px"
              colorBg={ready && !current ? color.primary : color.background}
              current={current}
              ready={true}
              checked={ready}
            />
          )}
          {missedMessage > 0 && (
            <MissMessagesWrapper>
              <SvgIcon
                icon={'missMessages'}
                color={current ? color.background : color.textDarkGrey}
                size={14}
              />
              <Text
                style={{ marginLeft: '3px' }}
                color={current ? color.background : color.primary}
              >
                +{missedMessage}
              </Text>
            </MissMessagesWrapper>
          )}
        </DocumentWrapper>
      </DocumentContainer>
    </>
  );
};

const styles = {
  documentTitle: {
    marginLeft: spacing.tiny,
  } as CSSProperties,
  svgIcon: {
    transform: 'rotate(180deg)',
  } as CSSProperties,
};
