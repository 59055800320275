import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useTypedSelector } from 'hooks/useTypedSelector';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const userIsAuth = useTypedSelector((state) => state.user.isAuth);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML =
      "(function(d, src, c) { var t=d.scripts[d.scripts.length - 1],s=d.createElement('script');s.id='la_x2s6df8d';s.async=true;s.src=src;s.onload=s.onreadystatechange=function(){var rs=this.readyState;if(rs&&(rs!='complete')&&(rs!='loaded')){return;}c(this);};t.parentElement.insertBefore(s,t.nextSibling);})(document,\n" +
      "'https://zenbit.ladesk.com/scripts/track.js',\n" +
      "function(e){ LiveAgent.createButton('96zpb9sp', e); });";
    document.body.appendChild(script);

    () => {
      script.remove();
    };
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        userIsAuth ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
};
