import React, { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { AuthContainer } from 'Components/AuthContainer';
import { Controller, useForm } from 'react-hook-form';
import { resetPassword } from 'Redux/user/thunk';
import {
  ButtonSubmit,
  Form,
  InputFieldPassword,
} from 'Components/FormAuth/style';
import { Text } from 'Components/Text';
import { useQuery } from 'hooks/useQuery';
import { color } from 'theme';
import { IFormRegister, IResetPassword } from 'Utils/Api/type';
import { InputPassword } from 'Components/InputPassword/InputPassword';

const schema = yup.object().shape({
  password: yup.string().min(6, 'min 6').required('this field is required'),
  confirmPassword: yup
    .string()
    .min(6, 'min 6')
    .required('this field is required')
    .oneOf([yup.ref('password'), null], 'The passwords do not match'),
});

const defaultValue = {
  password: '',
  confirmPassword: '',
};

export const ForgetPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const email = useQuery('email');
  const token = useQuery('token');
  const [errorLogin, setErrorLogin] = useState('');
  const [showPass, setShowPass] = useState(false);
  const [showPassConfirm, setShowPassConfirm] = useState(false);

  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
  } = useForm<IFormRegister>({
    resolver: yupResolver(schema),
    defaultValues: defaultValue,
  });

  const submitForm = (data: IResetPassword) => {
    const value = {
      ...data,
      email: email,
      token: token,
    };
    dispatch(
      resetPassword({
        value: value,
        history: () => history.push('/login'),
        setErrorLogin: (s) => setErrorLogin(s),
      }),
    );
  };

  return (
    <AuthContainer>
      <Form onSubmit={handleSubmit(submitForm)}>
        <Controller
          control={control}
          name="password"
          render={() => (
            <InputPassword
              setShowPass={(b) => setShowPass(b)}
              showPass={showPass}
              error={errors.confirmPassword}
              input={
                <InputFieldPassword
                  error={errors.password}
                  placeholder="Password"
                  type={showPass ? 'input' : 'password'}
                  name="password"
                  ref={register}
                  defaultValue=""
                />
              }
            />
          )}
        />
        <Controller
          control={control}
          name="confirmPassword"
          render={() => (
            <InputPassword
              setShowPass={(b) => setShowPassConfirm(b)}
              showPass={showPassConfirm}
              error={errors.confirmPassword}
              input={
                <InputFieldPassword
                  error={errors.confirmPassword}
                  placeholder="Confirm password"
                  type={showPassConfirm ? 'input' : 'password'}
                  name="confirmPassword"
                  ref={register({
                    validate: (value) =>
                      value === getValues('password') ||
                      'The passwords do not match',
                  })}
                  defaultValue=""
                />
              }
            />
          )}
        />
        {!!errorLogin && (
          <Text style={{ marginBottom: '10px' }} color={color.primary}>
            {errorLogin}
          </Text>
        )}
        <ButtonSubmit type="submit">
          <Text>Change password</Text>
        </ButtonSubmit>
      </Form>
    </AuthContainer>
  );
};
