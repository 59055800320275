import { Select } from 'antd';
import React from 'react';
import { optionsInput, TableData } from 'Pages/form-builder/formShcema';
import { Text } from 'Components/Text';
import { ButtonCreate } from 'Components/CreateInputModal/styles/style';
import { TableElement } from 'Components/CreateInputModal/modalElements/TableElement';
import { style } from 'Components/CreateInputModal/styles/styleReact';
import { inputFullType } from 'Components/CreateInputModal/index';
import { CheckboxInputSwitchElement } from 'Components/CreateInputModal/modalElements/CheckboxInputSwitchElement';
import { HeaderFooterElement } from 'Components/CreateInputModal/modalElements/HeaderFooterElement';

const Option = Select.Option;

interface IMainFormBodyProps {
  closeModal: () => void;
  typeInput: inputFullType;
  setTypeInput: (val: inputFullType) => void;
  table: TableData;
  setTable: (val: TableData) => void;
  valueInput: string[];
  setValueInput: (val: string[]) => void;
  descriptions: string[];
  setDescriptions: (val: string[]) => void;
  footerInputTexts: string[];
  setFooterInputTexts: (val: string[]) => void;
}

export const MainFormBody = (props: IMainFormBodyProps) => {
  const selectValues = [
    'Checkbox',
    'Input',
    'Table',
    'Switch',
    'default',
  ] as const;

  const handleClickToCreateInputs = () => {
    props.closeModal();
  };

  const handleSelect = (value: inputFullType) => {
    props.setTypeInput(value);
  };

  return (
    <div>
      <Select
        style={style.select}
        placeholder="Select type"
        value={props.typeInput}
        onChange={handleSelect}
      >
        {selectValues.map((el, index) => (
          <Option key={index} value={el}>
            <Text style={style.options}>{el}</Text>
          </Option>
        ))}
      </Select>
      {props.typeInput !== 'default' && (
        <HeaderFooterElement
          setValue={props.setDescriptions}
          values={props.descriptions}
          label={'add new description'}
          title={'Description'}
          placeholder={'add more descriptions'}
        />
      )}
      {(props.typeInput === optionsInput.checkbox ||
        props.typeInput === optionsInput.switch ||
        props.typeInput === optionsInput.input) && (
        <CheckboxInputSwitchElement
          typeInput={props.typeInput}
          values={props.valueInput}
          setValue={props.setValueInput}
          defaultValues={props.table}
          descriptions={props.descriptions}
          setDescriptions={props.setDescriptions}
          footerInputTexts={props.footerInputTexts}
          setFooterInputTexts={props.setFooterInputTexts}
        />
      )}
      {props.typeInput === optionsInput.table && (
        <TableElement
          table={props.table}
          setTable={props.setTable}
          defaultValues={props.valueInput}
          descriptions={props.descriptions}
          setDescriptions={props.setDescriptions}
          footerInputTexts={props.footerInputTexts}
          setFooterInputTexts={props.setFooterInputTexts}
        />
      )}
      {props.typeInput !== 'default' && (
        <HeaderFooterElement
          setValue={props.setFooterInputTexts}
          values={props.footerInputTexts}
          label={'add new footer'}
          title={'Footer'}
          placeholder={'add more footers'}
        />
      )}
      <ButtonCreate
        onClick={handleClickToCreateInputs}
        style={{ width: '100%' }}
      >
        <Text>Create</Text>
      </ButtonCreate>
    </div>
  );
};
