import React, { CSSProperties } from 'react';

interface PageProps {
  padding?: number;
  children: React.ReactNode;
  style?: CSSProperties;
}

export const Page = ({ padding = 25, children, style }: PageProps) => {
  return (
    <div style={{ width: '99%', boxSizing: 'border-box', padding, ...style }}>
      {children}
    </div>
  );
};
