import React, { useEffect, useState } from 'react';
import { Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { Text } from 'Components/Text';
import { color, spacing } from 'theme';
import { changeValueInput } from 'Redux/form/thunk';
import { EntryProps } from './render-step';

export const Switcher = ({
  entry,
  edit,
  value,
  inputNumber,
  valueNumber,
}: EntryProps) => {
  const dispatch = useDispatch();
  const [valueCurrent, setValueCurrent] = useState(!!value);

  useEffect(() => {
    setValueCurrent(!!value);
  }, [value]);

  const handleChange = () => {
    if (!edit) return setValueCurrent(valueCurrent);
    setValueCurrent(!valueCurrent);

    dispatch(
      changeValueInput({
        inputNumber: inputNumber,
        valueNumber: valueNumber,
        value: !valueCurrent,
      }),
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: spacing.tinyPlus,
      }}
    >
      <Switch
        checked={valueCurrent}
        onChange={handleChange}
        checkedChildren={'Yes'}
        unCheckedChildren={'No'}
        defaultChecked={false}
      />
      <Text
        color={edit ? color.text : color.textPale}
        text={entry}
        style={{ marginLeft: spacing.tinyPlus }}
      />
    </div>
  );
};
