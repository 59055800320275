import React, { useCallback, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LogoImg from 'Assets/images/logo-header.png';
import { SvgIcon } from 'Components/svg-icon';
import { downloadProject } from 'Redux/projects/thunk';
import { MatchParams } from 'Pages/createProject';
import { logOut } from 'Redux/user/thunk';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { ModalParticipants } from 'Blocks/Project/ModalParticipants';
import { Modal } from 'Components/Modal/Modal';
import { Text } from 'Components/Text';
import { AvatarParticipants, Subscriber } from 'Blocks/Project/styles';
import { URL } from 'Utils/Api';
import {
  HeaderLogo,
  NavWrapper,
  NavItem,
  LogOutLink,
  HeaderContainer,
  AvatarWrapper,
  MenuWrapper,
  MenuItem,
  LinkMenu,
} from './styles.js';
import { ModalDownloadAvatar } from './ModalDownloadAvatar';

export const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const projectId = useRouteMatch<MatchParams>('/project/:projectID');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isOpenMenuAvatar, setIsOpenMenuAvatar] = useState(false);
  const [isOpenModalAvatar, setIsOpenModalAvatar] = useState(false);
  const location = useLocation();
  const currentProjects = useTypedSelector(
    (state) => state.projects.currentProjects,
  );

  const user = useTypedSelector((state) => state.user);

  const handleClickForDownloadPdf = () => {
    if (currentProjects.status === 'complete') {
      dispatch(
        downloadProject({
          history: (s) => history.push(s),
          projectId: Number(projectId?.params.projectID),
        }),
      );
    }
  };

  const handleLogOut = () => {
    dispatch(logOut());
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    history.push('/login');
  };

  const handleClickOnAvatar = useCallback(() => {
    setIsOpenMenuAvatar(!isOpenMenuAvatar);
  }, [isOpenMenuAvatar]);

  const handleClickForOpenModalAvatarDownload = useCallback(() => {
    setIsOpenModalAvatar(!isOpenModalAvatar);
    setIsOpenMenuAvatar(false);
  }, [isOpenMenuAvatar]);

  const handleIsModalVisible = useCallback(() => setIsModalVisible(true), [
    isModalVisible,
  ]);

  return (
    <>
      {!!currentProjects && (
        <Modal
          toggle={() => setIsModalVisible(!isModalVisible)}
          isModalVisible={isModalVisible}
          title="Participants"
          childrenContent={
            <ModalParticipants
              participants={currentProjects.participants || []}
              projectId={currentProjects.id || 0}
            />
          }
        />
      )}

      {
        <Modal
          toggle={() => setIsOpenModalAvatar(!isOpenModalAvatar)}
          isModalVisible={isOpenModalAvatar}
          title="Download avatar"
          childrenContent={
            <ModalDownloadAvatar close={() => setIsOpenModalAvatar(false)} />
          }
        />
      }

      <HeaderContainer>
        <HeaderLogo src={LogoImg} />

        <NavWrapper>
          {location.pathname.includes('/project/') && (
            <NavItem onClick={handleIsModalVisible}>
              <SvgIcon icon="addUser" width={20} />
            </NavItem>
          )}

          <NavItem>
            <LinkMenu to="/">
              <SvgIcon icon="plasts" width={20} />
            </LinkMenu>
          </NavItem>

          <NavItem>
            <SvgIcon icon="setting" width={20} />
          </NavItem>
          {location.pathname.includes('/project/') && (
            <NavItem onClick={handleClickForDownloadPdf}>
              <SvgIcon icon="download" width={20} />
            </NavItem>
          )}
          <NavItem>
            <SvgIcon icon="notification" width={20} />
          </NavItem>
          <LogOutLink onClick={handleLogOut}>Log out</LogOutLink>
          <AvatarWrapper>
            <Subscriber onClick={handleClickOnAvatar} static={true}>
              {user && user.avatar ? (
                <AvatarParticipants
                  alt="avatar"
                  src={`${URL}/${user.avatar}`}
                />
              ) : (
                <>
                  {user && user.name && user.surname
                    ? `${user.name[0]}${user.surname[0]}`
                    : ''}
                </>
              )}
            </Subscriber>
            {isOpenMenuAvatar && (
              <MenuWrapper>
                <MenuItem onClick={handleClickForOpenModalAvatarDownload}>
                  <Text>Upload avatar</Text>
                </MenuItem>
              </MenuWrapper>
            )}
          </AvatarWrapper>
        </NavWrapper>
      </HeaderContainer>
    </>
  );
};
