import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formBuilderReducer } from 'Redux/form/reducer';
import { reducer as userReducer } from 'Redux/user/reducer';
import { reducer as loadingReducer } from 'Redux/loading/reduser';
import { reducer as projectsReducer } from 'Redux/projects/reducer';

export const rootReducer = combineReducers({
  formBuilder: formBuilderReducer,
  user: userReducer,
  loading: loadingReducer,
  projects: projectsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
