import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { color } from 'theme';
import { IUser } from 'Redux/user/type';
import { deleteParticipant } from 'Redux/projects/thunk';
import { Text } from 'Components/Text';
import {
  OptionsCustom,
  OptionsCustomWrapper,
} from 'Components/CustomSelect/style';
import { useTypedSelector } from 'hooks/useTypedSelector';
import {
  AvatarParticipants,
  OptionsMenu,
  ParticipantData,
  ParticipantsWrapper,
  Point,
  RoleWrapper,
  Subscriber,
} from './styles';
import { URL } from '../../Utils/Api';

interface IItemProps {
  projectId: number;
  status: string;
  user: IUser;
  projectOwner: number;
}

export const ParticipantsItem = ({
  projectId,
  status,
  user,
  projectOwner,
}: IItemProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentRole, setCurrentRole] = useState(false);
  const currentUser = useTypedSelector((state) => state.user);

  const handleDeleteParticipant = () => {
    if (!projectId) return;

    dispatch(
      deleteParticipant({
        history: (s) => history.push(s),
        projectId: projectId,
        userEmail: user.email,
        userId: user.id,
      }),
    );
  };

  return (
    <ParticipantsWrapper>
      <ParticipantData>
        <Subscriber static={true}>
          {user && user.avatar ? (
            <AvatarParticipants alt="avatar" src={`${URL}/${user.avatar}`} />
          ) : (
            <Text
              style={{ textTransform: 'uppercase' }}
              color={color.background}
            >
              {user && user.name && user.surname
                ? `${user.name[0]}${user.surname[0]}`
                : ''}
            </Text>
          )}
        </Subscriber>
        <Text style={{ marginLeft: '10px' }}>
          {user && user.name && user.surname
            ? `${user.name} ${user.surname}`
            : ''}
        </Text>
      </ParticipantData>

      <RoleWrapper>
        {status}
        {(projectOwner !== currentUser.id || currentUser.admin) && (
          <>
            <OptionsMenu onClick={() => setCurrentRole(!currentRole)}>
              <Point />
              <Point />
              <Point />
            </OptionsMenu>
            <OptionsCustomWrapper active={currentRole}>
              <OptionsCustom onClick={handleDeleteParticipant}>
                delete
              </OptionsCustom>
            </OptionsCustomWrapper>
          </>
        )}
      </RoleWrapper>
    </ParticipantsWrapper>
  );
};
