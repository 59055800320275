import * as React from 'react';
import { IconProps } from './icon.props';
import { icons } from './icons';
import { color as colors } from 'theme';

export function SvgIcon({
  icon,
  size = 20,
  width,
  height,
  color = colors.secondary,
  id,
  ...props
}: IconProps): React.FunctionComponentElement<IconProps> {
  const Icon = icons[icon];

  return (
    <Icon
      id={id}
      width={width || size}
      height={height || size}
      color={color}
      {...props}
    />
  );
}
