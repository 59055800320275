import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { Checkbox } from 'antd';
import { color, spacing } from 'theme';
import { SubscriberBlock } from 'Blocks/Project/styles';
import { ButtonSubmit } from 'Components/FormAuth/style';

export const Stage = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${spacing.tiny} 0 0 ${spacing.tiny};
`;

export const DocumentWrapper = styled.div`
  width: 100%;
  outline: none;
  cursor: pointer;
  display: grid;
  grid-template-columns: 7fr 1fr;
  align-items: center;
  justify-items: start;
  padding: ${spacing.tiny};
  background-color: ${(p) => p.current && color.primary};
  color: ${(p) => p.current && color.background};

  &:disabled {
    cursor: not-allowed;
  }
`;

export const DocumentContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DocumentTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const MissMessagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-left: ${spacing.tiny};
  margin-top: ${spacing.tiny};
  color: ${(p) => (p.edit ? color.text : color.textPale)}};
  ${() => css`
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${color.primary};
      border-color: ${color.primary};
    }

    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin: 0;
    }
  `}
`;

export const LeftSider = styled.div`
  min-height: 100vh;
  height: 100%;
  background-color: ${color.background};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 260px;
`;

export const LeftSiderContainer = styled.div`
  background-color: ${color.background};
  padding: ${spacing.smaller};
`;

export const RightSider = styled(LeftSider)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  position: relative;
  max-width: 320px;
`;

export const BackButton = styled(Link)`
  color: ${color.text};
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${spacing.massive};
  border: 0;

  &:hover {
    color: ${color.text};
  }
`;

export const NeedHelp = styled.button`
  border: 0;
  border-top: 1px solid ${color.backgroundPaginator};
  cursor: pointer;
  position: sticky;
  bottom: 0;
  left: 20px;
  height: 70px;
  width: 260px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 10px;
  justify-items: center;
  background-color: ${color.background};
`;

export const SubscriberBlockHelp = styled(SubscriberBlock)`
  justify-self: end;
  align-self: center;
  width: 50px;
  height: 50px;
`;

export const TextNeedHelp = styled.p`
  margin: 0;
  justify-self: start;
  align-self: center;
  font-size: 14px;
  color: ${color.primary};
`;

export const DocumentPaginatorWrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 0.98;
  padding: ${spacing.tinyPlus};
  flex-direction: column;
  justify-content: space-between;
  background-color: ${color.background};
`;

export const FormWrapper = styled.div`
  padding: 25px 25px 0 25px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  width: 100%;
`;

export const Paginator = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: ${color.backgroundPaginator};
  padding: ${spacing.tinyPlus};
`;

export const BackNext = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${spacing.tinyPlus};
  margin-right: ${spacing.tinyPlus};

  &:disabled {
    cursor: auto;
    opacity: 0.1;
  }
`;

export const LeftGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const WrapperTitle = styled.div`
  outline: none;
`;

export const ComponentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${color.background};
  padding: ${spacing.smaller};
  border-bottom: 1px solid ${color.backgroundPaginator};
  border-top: 1px solid ${color.backgroundPaginator};
`;

export const ComponentsWrapperCenter = styled(ComponentsWrapper)`
  align-items: center;
`;

export const PageProjectContainer = styled.div`
  display: grid;
  grid-template-columns: 260px 1fr;
  width: 100%;
  height: 100%;
`;

export const IntroductionContentContainer = styled.div`
  margin-right: 20px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  background-color: ${color.background};
`;

export const NameProject = styled.h1`
  width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
`;

export const ProgressTitle = styled.h3`
  margin-bottom: 5px;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
`;

export const ProgressProcent = styled.p`
  font-size: 13px;
  line-height: 15px;
  color: ${color.primary};
`;

export const ProgressProcentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const TitleStartProject = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
`;

export const TextStartProject = styled(TitleStartProject)`
  font-weight: 400;
`;

export const ParagrafStartProject = styled.div`
  margin-bottom: 30px;
`;

export const ButtonProject = styled(ButtonSubmit)`
  font-size: 14px;
  width: 85px;
  padding: 8px 5px;
  height: 36px;
  color: ${(p) => (p.edit ? color.background : color.primary)};
  background-color: ${(p) => (p.edit ? color.primary : 'transparent')};
  border: 1px solid ${(p) => (p.edit ? 'transparent' : color.primary)};
  &:disabled {
    cursor: auto;
    opacity: 0.3;
    border: 1px solid ${color.textDarkGrey};
    background-color: transparent;
    color: ${color.textDarkGrey};
  }
`;

export const ButtonComplete = styled(ButtonSubmit)`
  margin-top: 10px;
  font-size: 14px;
  line-height: 16px;
  width: 166px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    border: 1px solid ${color.textDarkGrey};
    background-color: transparent;
    color: ${color.textDarkGrey};
  }
`;

export const NavTitle = styled.h4`
  margin: 0;
  color: ${color.primary};
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`;
export const StepText = styled.p`
  margin: 0;
  color: ${(p) => p.color};
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
`;
