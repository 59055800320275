import styled from 'styled-components';

export const Page = styled.div`
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  padding: 25px;
`;

export const HomeWrapper = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 40px;
`;

export const RightSide = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 125px;
`;

export const ProjectAndTeam = styled.div`
  width: 100%;
`;

export const WelcomeWrapper = styled.div`
  width: 100%;
  text-align: justify;
  display: contents;
`;

export const WrapperHelper = styled.div`
  padding: 0px 30px 30px 40px;
`;
