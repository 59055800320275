import styled from 'styled-components';
import { color } from 'theme';

export const ModalContainer = styled.div`
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.62);
  z-index: 10000;
`;

export const ModalWrapper = styled.div`
  border-radius: 5px;
  width: 400px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${color.background};
`;

export const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Close = styled.span`
  cursor: pointer;
  display: block;
  width: 20px;
  height: 20px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-color: ${color.primary};
    width: 4px;
    height: 100%;
    border-radius: 5px;
    transform: rotate(-45deg);
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-color: ${color.primary};
    width: 4px;
    height: 100%;
    border-radius: 5px;
    transform: rotate(45deg);
  }
`;
