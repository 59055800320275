import React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => {
  const { color, width, height } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.9239 2.23798C16.5654 1.18284 15.6343 0.5 14.5799 0.5H9.41992L9.22376 0.507914C8.18652 0.591866 7.30141 1.33621 7.02882 2.38166L6.71931 3.96016L6.68304 4.09911C6.53483 4.5491 6.12047 4.86141 5.64716 4.86141H1.66424L1.54018 4.86997C1.09394 4.93205 0.75 5.32431 0.75 5.79894C0.75 6.31673 1.15932 6.73648 1.66424 6.73648H5.64716H18.3526H22.3358L22.4598 6.72792C22.9061 6.66584 23.25 6.27358 23.25 5.79894C23.25 5.28116 22.8407 4.86141 22.3358 4.86141H18.3526L18.2126 4.85221C17.7532 4.7915 17.3734 4.4359 17.2804 3.95979L16.9843 2.44011L16.9239 2.23798ZM15.6429 4.86141C15.6013 4.75557 15.5653 4.64651 15.5353 4.5346L15.4875 4.3276L15.2045 2.866C15.1384 2.61239 14.9342 2.42673 14.6878 2.38428L14.5799 2.37507H9.41992C9.1634 2.37507 8.93538 2.52903 8.84067 2.72253L8.80855 2.80755L8.51226 4.32798C8.47611 4.5131 8.42376 4.69139 8.3569 4.86141H15.6429ZM20.723 8.90021C21.1843 8.93836 21.538 9.32144 21.5624 9.78257L21.5506 10.0397L21.1575 14.8537L20.7451 19.5517C20.6578 20.4896 20.5797 21.2806 20.5123 21.9033C20.278 24.0735 18.8693 25.4154 16.7457 25.4551C13.4367 25.5163 10.2559 25.5156 7.16713 25.4488C5.10494 25.4056 3.71705 24.0491 3.4869 21.9121L3.32784 20.3377L3.0499 17.2838L2.76517 13.9326L2.43962 9.90985C2.39916 9.39372 2.77436 8.94169 3.27765 8.9002C3.73901 8.86216 4.14785 9.1823 4.24395 9.63346L4.28136 10.0022L4.58704 13.7734L4.92083 17.6823C5.07054 19.3745 5.20039 20.744 5.3043 21.706C5.43545 22.9238 6.07669 23.5505 7.20511 23.5742C10.2698 23.6405 13.4268 23.6411 16.7126 23.5804C17.9096 23.558 18.561 22.9374 18.695 21.6967L18.8533 20.1307C18.8997 19.6481 18.9492 19.1153 19.0017 18.5366L19.3358 14.6922L19.7382 9.75937C19.7754 9.28627 20.149 8.92363 20.5987 8.89852L20.723 8.90021Z"
        fill={color || '#EE0000'}
      />
    </svg>
  );
};

export const Delete = React.memo(SvgComponent);
