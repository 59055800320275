import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { Input } from 'Pages/form-builder/formShcema';
import { FormDescriptions } from 'Pages/form-builder/form-descriptions';
import { color } from 'theme';
import {
  addInputDescription,
  addInputDescriptionFooter,
  deleteInput as deleteInputAction,
} from 'Redux/form/thunk';
import { FormForEdit } from 'Components/FormForEdit/FormForEdit';
import { ModalComponent } from 'Blocks/Modal';
import { Modal } from 'Components/Modal/Modal';
import { SvgIcon } from 'Components/svg-icon';
import { GenerateInputsByType } from './GenerateInputsByType';
import {
  AddDescriptionButton,
  ButtonIconWrapper,
  PoapDescriptionButton,
  WrapperInput,
} from './style';
import { ModalAreUSure } from './ModalDelete';
import { FormForEditMember } from '../FormForEdit/FormForEditMember';

interface IProps {
  input: Input;
  indexInput: number;
  stepId: number;
}

export const Inputs = ({ input, indexInput, stepId }: IProps) => {
  const [editTypeInput, setEditTypeInput] = useState(false);
  const [idDeleteInput, setIsDeleteInput] = useState(false);
  const { admin } = useTypedSelector((state) => state.user);
  const { id: userId } = useTypedSelector((state) => state.user);
  const { isEditing } = useTypedSelector(
    (state) => state.formBuilder.currentForm,
  );
  const dispatch = useDispatch();

  const deleteInput = () => {
    dispatch(deleteInputAction({ index: indexInput }));
  };

  const addDescriptionFooter = () => {
    dispatch(addInputDescriptionFooter({ index: indexInput }));
  };

  const handleClickForChangeTypeInput = useCallback(() => {
    setEditTypeInput(!editTypeInput);
  }, [editTypeInput]);

  const formDescription = (isEdit: boolean) => (
    <>
      {input.descriptions.map((paragraph, index) => (
        <FormDescriptions
          isEdit={isEditing.edit && isEditing.user.id === userId}
          editForm={isEdit}
          description={paragraph}
          key={index}
          id={{
            descriptionIndex: index,
            stepId: stepId,
            inputIndex: indexInput,
          }}
          type={'input'}
        />
      ))}
      {isEdit && (
        <AddDescriptionButton
          onClick={() => dispatch(addInputDescription({ index: indexInput }))}
        >
          <PoapDescriptionButton>Add header description </PoapDescriptionButton>
          <SvgIcon width={14} height={14} icon="plus" color={color.primary} />
        </AddDescriptionButton>
      )}
    </>
  );

  const content = (
    <WrapperInput>
      {input.values.map((entry, key) => (
        <GenerateInputsByType
          inputNumber={indexInput}
          valueNumber={key}
          value={entry.value}
          edit={isEditing.edit && isEditing.user.id === userId}
          key={key}
          entry={entry.label}
          input={input}
        />
      ))}
    </WrapperInput>
  );

  const footer = (isEdit: boolean) => (
    <div>
      {input.footerInputTexts.map((paragraph, index) => (
        <FormDescriptions
          key={index}
          isEdit={isEditing.edit && isEditing.user.id === userId}
          editForm={isEdit}
          description={paragraph}
          id={{
            descriptionIndex: index,
            stepId: stepId,
            inputIndex: indexInput,
          }}
          type={'footer'}
        />
      ))}
      {isEdit && (
        <AddDescriptionButton onClick={addDescriptionFooter}>
          <PoapDescriptionButton>Add footer description</PoapDescriptionButton>
          <SvgIcon width={14} height={14} icon="plus" color={color.primary} />
        </AddDescriptionButton>
      )}
    </div>
  );

  return (
    <>
      <Modal
        toggle={() => setIsDeleteInput(true)}
        isModalVisible={idDeleteInput}
        title="Participants"
        childrenContent={
          <ModalAreUSure
            text={
              ' Are you sure you want to delete this? This action cannot be undone.'
            }
            toggle={() => setIsDeleteInput(false)}
            actionTrue={deleteInput}
          />
        }
      />
      <div key={indexInput}>
        {editTypeInput && admin ? (
          <ModalComponent
            width={'80%'}
            key={indexInput}
            toggle={() => setEditTypeInput(!editTypeInput)}
            title={'Create new input'}
            childrenContent={
              <>
                <FormForEdit
                  footer={footer(true)}
                  formDescription={formDescription(true)}
                  closeModal={() => setEditTypeInput(!editTypeInput)}
                  index={indexInput}
                  input={input}
                />
              </>
            }
            isModalVisible={editTypeInput}
          />
        ) : (
          <div key={indexInput}>
            {formDescription(false)}
            {content}
            {footer(false)}
          </div>
        )}

        {editTypeInput && !admin && (
          <ModalComponent
            width={'80%'}
            key={indexInput}
            toggle={() => setEditTypeInput(!editTypeInput)}
            title={'Create new input'}
            childrenContent={
              <>
                <FormForEditMember
                  footer={footer(true)}
                  formDescription={formDescription(true)}
                  closeModal={() => setEditTypeInput(!editTypeInput)}
                  index={indexInput}
                  input={input}
                />
              </>
            }
            isModalVisible={editTypeInput}
          />
        )}

        {isEditing.edit && isEditing.user.id === userId && admin && (
          <ButtonIconWrapper>
            <AddDescriptionButton onClick={handleClickForChangeTypeInput}>
              <PoapDescriptionButton>Edit input</PoapDescriptionButton>
              <SvgIcon
                width={14}
                height={14}
                icon="edit"
                color={color.secondary}
              />
            </AddDescriptionButton>
            <AddDescriptionButton onClick={() => setIsDeleteInput(true)}>
              <PoapDescriptionButton>Delete input</PoapDescriptionButton>
              <SvgIcon
                width={14}
                height={14}
                icon="delete"
                color={color.primary}
              />
            </AddDescriptionButton>
          </ButtonIconWrapper>
        )}

        {isEditing.edit && isEditing.user.id === userId && !admin && (
          <ButtonIconWrapper>
            <AddDescriptionButton onClick={handleClickForChangeTypeInput}>
              <PoapDescriptionButton>Edit input</PoapDescriptionButton>
              <SvgIcon
                width={14}
                height={14}
                icon="edit"
                color={color.secondary}
              />
            </AddDescriptionButton>
          </ButtonIconWrapper>
        )}
      </div>
    </>
  );
};
