import React, { useState } from 'react';
import { ModalComponent } from 'Blocks/Modal';
import { ButtonCreateInput } from 'Components/CreateInputModal/styles/style';
import { MainFormBody } from 'Components/CreateInputModal/modalElements/MainFormBody';
import {
  inputTypes,
  optionsInput,
  TableData,
} from 'Pages/form-builder/formShcema';
import { useDispatch } from 'react-redux';
import { createInputs, createTable } from 'Redux/form/thunk';

export type inputFullType = inputTypes | 'default';

export const CreateInputModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [typeInput, setTypeInput] = useState<inputFullType>('default');
  const [valueInput, setValueInput] = useState<string[]>(['']);
  const [table, setTable] = useState<TableData>({
    columns: [
      { title: '', dataIndex: 'col1', key: 'col1' },
      { title: '', dataIndex: 'col2', key: 'col2' },
    ],
    dataSource: [],
  });
  const [descriptions, setDescriptions] = useState<string[]>(['']);
  const [footerInputTexts, setFooterInputTexts] = useState<string[]>(['']);

  const dispatch = useDispatch();

  const handleClickCreateInput = () => {
    setIsModalVisible(!isModalVisible);
  };

  const clear = () => {
    setValueInput(['']);
    setTable({
      columns: [
        { title: '', dataIndex: 'col1', key: 'col1' },
        { title: '', dataIndex: 'col2', key: 'col2' },
      ],
      dataSource: [],
    });
    setTypeInput('default');
    setIsModalVisible(false);
    setDescriptions(['']);
    setFooterInputTexts(['']);
  };

  const handleClickToCloseButton = () => {
    if (typeInput === optionsInput.table) {
      dispatch(
        createTable({
          type: typeInput,
          tableData: table,
          descriptions: descriptions,
          footerInputTexts: footerInputTexts,
        }),
      );
    }
    if (
      typeInput === optionsInput.input ||
      typeInput === optionsInput.switch ||
      typeInput === optionsInput.checkbox
    ) {
      dispatch(
        createInputs({
          type: typeInput,
          values: valueInput,
          descriptions: descriptions,
          footerInputTexts: footerInputTexts,
        }),
      );
    }
    clear();
  };

  return (
    <div>
      <ModalComponent
        toggle={clear}
        title={'Create new input'}
        childrenContent={
          <MainFormBody
            closeModal={handleClickToCloseButton}
            typeInput={typeInput}
            setTypeInput={setTypeInput}
            table={table}
            setTable={setTable}
            valueInput={valueInput}
            setValueInput={setValueInput}
            descriptions={descriptions}
            setDescriptions={setDescriptions}
            footerInputTexts={footerInputTexts}
            setFooterInputTexts={setFooterInputTexts}
          />
        }
        isModalVisible={isModalVisible}
      />
      <ButtonCreateInput onClick={handleClickCreateInput}>
        create input
      </ButtonCreateInput>
    </div>
  );
};
