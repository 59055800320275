import { IUser } from '../../Redux/user/type';

export type inputTypes = 'Checkbox' | 'Input' | 'Table' | 'Switch';

export interface DataSource {
  key: string;
  valueCol: string[];
}

export interface Column {
  title: string;
  dataIndex: string;
  key: string;
}

export interface TableData {
  dataSource: DataSource[];
  columns: Column[];
}

interface IValuesInput {
  id?: number;
  label: string;
  value: boolean | string;
}

export interface Input {
  id?: number;
  descriptions: string[];
  type: inputTypes;
  values: IValuesInput[];
  tableData?: TableData;
  footerInputTexts: string[];
}

export interface Header {
  name: string;
  points: { id: number; label: string }[];
  title: string;
}

export interface Content {
  name: string;
  title: {
    head: string;
    text: string;
  };
  id: number;
  descriptions: string[];
  inputs: Input[];
  footerStepNote: string[] | null;
  header?: Header;
  ready: boolean;
  edit: boolean;
}

export interface IIsEditing {
  id: number;
  edit: boolean;
  user: IUser | null;
}

export interface Step {
  content: Content;
  isEditing?: IIsEditing;
  project?: number;
  isDone: boolean;
  position: number;
  id: number;
}

interface IOptionsInput {
  input: inputTypes;
  checkbox: inputTypes;
  table: inputTypes;
  switch: inputTypes;
}

export const optionsInput: IOptionsInput = {
  input: 'Input',
  checkbox: 'Checkbox',
  table: 'Table',
  switch: 'Switch',
};

interface IPoint {
  id: number;
  label: string;
}

export interface IFormDescription {
  title: string;
  name?: string;
  points: IPoint[];
}
