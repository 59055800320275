import { createReducer, current } from '@reduxjs/toolkit';
import {
  assignUser,
  createProjects,
  deleteParticipant,
  lastTwoProjects,
  saveAllProjects,
  sendEmailUser,
  setCurrentProjects,
} from './thunk';
import { IProjects } from './type';
import { IUser } from '../user/type';

interface IInitialStateProjects {
  lastTwo: IProjects[];
  projects: IProjects[];
  currentProjects: IProjects | null;
}

export const initialState: IInitialStateProjects = {
  lastTwo: [],
  projects: [],
  currentProjects: null,
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(lastTwoProjects.fulfilled, (state, { payload }) => {
      state.lastTwo = payload.reverse();
    })
    .addCase(setCurrentProjects.fulfilled, (state, { payload }) => {
      state.currentProjects = payload.project;
    })
    .addCase(sendEmailUser.fulfilled, (state, { payload }) => {
      const { currentProjects, projects } = current(state);

      const indexProject = projects.findIndex(
        (p) => p.id === payload.projectId,
      );
      if (indexProject) {
        state.projects[indexProject].participants = [
          ...currentProjects.participants,
          {
            id: currentProjects.participants.length + 2,
            status: 'pudding',
            role: 'member',
            user: payload.user,
            projectId: currentProjects.id,
          },
        ];
      }

      if (currentProjects) {
        state.currentProjects.participants = [
          ...currentProjects.participants,
          {
            id: currentProjects.participants.length + 2,
            status: 'pudding',
            role: 'member',
            user: payload.user,
            projectId: currentProjects.id,
          },
        ];
      }
    })
    .addCase(deleteParticipant.fulfilled, (state, { payload }) => {
      const { projectId, userId } = payload;
      const stateCurrent = current(state);
      const p = stateCurrent.projects.map((x) => {
        if (x.id === projectId) {
          return {
            ...x,
            participants: x.participants.filter((p) => p.user.id !== userId),
          };
        }
        return x;
      });

      state.projects = [...p];
    })
    .addCase(assignUser.fulfilled, (state, { payload }) => {
      console.log('assignUser.fulfilled');
    })
    .addCase(saveAllProjects.fulfilled, (state, { payload }) => {
      state.projects = payload.sort((a, b) => {
        if (new Date(a.date).getTime() > new Date(b.date).getTime()) return -1;
        if (new Date(a.date).getTime() === new Date(b.date).getTime()) return 0;
        if (new Date(a.date).getTime() < new Date(b.date).getTime()) return 1;
      });
    })
    .addCase(createProjects.fulfilled, (state, { payload }) => {
      const { projects } = current(state);
      state.projects = [...projects, payload.project];
    });
});
