import React, { CSSProperties, useState } from 'react';
import { Button, Input, Space } from 'antd';
import { useDispatch } from 'react-redux';
import { editFormTitleAndText } from 'Redux/form/thunk';
import { Text } from 'Components/Text';
import { ModalComponent } from 'Blocks/Modal';
import { ModalWrapper } from 'Components/NewProject/style';
import { color, spacing } from 'theme';
import {
  AddDescriptionButton,
  PoapDescriptionButton,
} from 'Components/Inputs/style';
import { SvgIcon } from 'Components/svg-icon';
import { useTypedSelector } from '../../hooks/useTypedSelector';

interface StepTitleProps {
  title: {
    head: string;
    text: string;
  };
  editForm: boolean;
}

export const StepTitle = ({ title, editForm }: StepTitleProps) => {
  const { admin } = useTypedSelector((state) => state.user);
  const [edit, setEdit] = useState(false);
  const toggle = () => {
    setEdit(!edit);
  };

  const ModalContent = () => {
    const dispatch = useDispatch();
    const [modalHead, setModalHead] = useState(title.head);
    const [modalText, setModalText] = useState(title.text);

    const done = () => {
      toggle();
      dispatch(editFormTitleAndText({ head: modalHead, text: modalText }));
    };
    return (
      <ModalWrapper>
        <Space
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
          }}
        >
          <Input.TextArea
            value={modalHead}
            autoSize
            style={{ width: 450 }}
            onChange={(e) => setModalHead(e.target.value)}
          />
          <Input.TextArea
            value={modalText}
            autoSize
            onChange={(e) => setModalText(e.target.value)}
            style={{ width: 450, marginTop: spacing.tinyPlus }}
          />
          <Space style={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              onClick={toggle}
              danger
              style={{ marginTop: spacing.small }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              style={{ marginTop: spacing.small }}
              onClick={done}
            >
              Done
            </Button>
          </Space>
        </Space>
      </ModalWrapper>
    );
  };
  return !edit ? (
    <div>
      <Text
        color={editForm ? color.text : color.textPale}
        style={styles.title}
        preset="bodyStrong"
        text={`${title.head}:`}
      />
      <Text
        color={editForm ? color.text : color.textPale}
        preset="bodyRegular"
        text={title.text}
        style={styles.subtitle}
      />
      {editForm && admin && (
        <AddDescriptionButton onClick={toggle}>
          <PoapDescriptionButton>Edit title description</PoapDescriptionButton>
          <SvgIcon width={14} height={14} icon="edit" color={color.secondary} />
        </AddDescriptionButton>
      )}
    </div>
  ) : (
    <ModalComponent
      toggle={toggle}
      isModalVisible={edit}
      title="Edit Step Title"
      childrenContent={<ModalContent />}
    />
  );
};

const styles = {
  title: {
    display: 'inline-block',
  } as CSSProperties,
  subtitle: {
    display: 'inline',
    marginLeft: spacing.tiny,
    marginRight: spacing.tiny,
  } as CSSProperties,
};
