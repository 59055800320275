import styled from 'styled-components';
import { color } from 'theme';

const logoHeight = '76px';

export const WrapperPage = styled.div`
  box-sizing: border-box;
  height: 100vh;
`;

export const LogoCompany = styled.img`
  height: 78px;
`;

export const WrapperLogoHeader = styled.div`
  margin-left: 21px;
  height: ${logoHeight};
`;

export const WrapperLogo = styled.div`
  width: 250px;
  height: 100px;
`;

export const ContainerPage = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: calc(100vh - ${logoHeight});
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LeftSide = styled.div`
  width: 100%;
  height: 100%;
`;

export const ImgRegister = styled.img`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  object-fit: contain;
`;

export const ButtonToLogin = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 50%;
`;

export const TextTitle = styled.h3`
  font-weight: 500;
  font-size: 30px;
  color: ${color.text};
  margin: 0;
`;
export const TextSubtitle = styled(TextTitle)`
  font-size: 18px;
  color: ${color.text};
  text-transform: uppercase;
`;
