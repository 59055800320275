import React, { useEffect, useState } from 'react';
import { spacing } from 'theme';
import { Input } from 'antd';
import { EntryProps } from './render-step';
import { changeValueInput } from '../../Redux/form/thunk';
import { useDispatch } from 'react-redux';

export const InputTextArea = ({
  entry,
  edit,
  value,
  inputNumber,
  valueNumber,
}: EntryProps) => {
  const dispatch = useDispatch();
  const [valueCurrent, setValueCurrent] = useState(value);

  useEffect(() => {
    setValueCurrent(value);
  }, [value]);

  const handleChange = (currentValue: string) => {
    if (!edit) return setValueCurrent(valueCurrent);
    setValueCurrent(currentValue);

    dispatch(
      changeValueInput({
        inputNumber: inputNumber,
        valueNumber: valueNumber,
        value: currentValue,
      }),
    );
  };

  return (
    <Input.TextArea
      value={valueCurrent.toString()}
      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
        handleChange(e.target.value)
      }
      placeholder={entry}
      rows={5}
      style={{ marginTop: spacing.tinyPlus }}
    />
  );
};
