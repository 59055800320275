import styled from 'styled-components';

export const MapContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 60vh;
  position: relative;
`;

export const MapItem = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #e9eff4;
`;
