import {
  ActionCreatorWithoutPayload,
  createAction,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import {
  Input,
  inputTypes,
  Step,
  TableData,
} from 'Pages/form-builder/formShcema';
import { IUser, MyError } from 'Redux/user/type';
import { IContent } from 'Redux/projects/type';
import { refreshToken } from 'Utils/refreshToken';
import { refreshTokenAction } from 'Redux/user/thunk';
import {
  markPieceReady,
  updateEditing,
  updateProject,
  getForm,
  deleteStepDelete,
  createNewStepPost,
} from 'Utils/Api';
import { toggleLoading } from 'Redux/loading/thunk';
import { IEditResponse } from './type';

export const saveTextDescription = createAction<
  ActionCreatorWithoutPayload<'saveTextDescription'>
>('saveTextDescription');

export const editFormDescription = createAction<{
  descriptionIndex: number;
  content: string;
  inputIndex: number;
}>('form/editFormDescription');

export const deleteFormDescription = createAction<{
  descriptionIndex: number;
}>('form/deleteFormDescription');

export const addFormDescription = createAction('form/addFormDescription');

export const editFormTitleAndText = createAction<{
  head: string;
  text: string;
}>('form/editFormTitleAndText');

export const addInputDescription = createAction<{ index: number }>(
  'form/addInputDescription',
);

export const editInputDescription = createAction<{
  inputIndex: number;
  descriptionIndex: number;
  value: string;
}>('form/editInputDescription');

export const editFooterDescription = createAction<{
  inputIndex: number;
  descriptionIndex: number;
  value: string;
}>('form/editFooterDescription');

export const editFooterNode = createAction<{
  inputIndex: number;
  footerIndex: number;
  value: string;
}>('form/editFooterNode');

export const deleteInputDescription = createAction<{
  inputIndex: number;
  descriptionIndex: number;
}>('form/deleteInputDescription');

export const deleteFooterNode = createAction('form/deleteFooterNode');

export const deleteFooterDescription = createAction<{
  inputIndex: number;
  descriptionIndex: number;
}>('form/deleteFooterDescription');

export const addInputDescriptionFooter = createAction<{
  index: number;
}>('form/addInputDescriptionFooter');

export const createInputs = createAction<{
  type: inputTypes;
  values: string[];
  tableData?: TableData;
  descriptions: string[];
  footerInputTexts: string[];
}>('form/createInputs');

export const createTable = createAction<{
  descriptions: string[];
  type: inputTypes;
  tableData: TableData;
  footerInputTexts: string[];
}>('form/createTable');

export const deleteInput = createAction<{
  index: number;
}>('form/deleteInput');

export const changeInput = createAction<{
  inputIndex: number;
  data: Input;
}>('form/changeInput');

export const changeTable = createAction<{
  inputIndex: number;
  data: TableData;
}>('form/changeTable');

export const changeReady = createAction<{
  formId: number;
  ready: boolean;
}>('form/changeReady');

export const changeReadyAsync = createAsyncThunk<
  void,
  {
    projectId: number;
    id: number;
    isDone: boolean;
    history: (s: string) => void;
  },
  {
    rejectValue: MyError;
  }
>(
  'form/changeReadyAsync',
  async ({ projectId, id, isDone, history }, thunkAPI) => {
    thunkAPI.dispatch(toggleLoading({ loading: true }));

    if (refreshToken().needRefresh) {
      thunkAPI.dispatch(refreshTokenAction({ history: (s) => history(s) }));
    }

    const response = await markPieceReady(projectId, id, isDone);

    const data = await response.json();

    if (response.status > 300) {
      thunkAPI.dispatch(toggleLoading({ loading: false }));
      return thunkAPI.rejectWithValue(data as MyError);
    }

    thunkAPI.dispatch(changeReady({ formId: id, ready: isDone }));
    thunkAPI.dispatch(toggleLoading({ loading: false }));
  },
);

export const changeValueInput = createAction<{
  inputNumber: number;
  valueNumber: number;
  value: string | boolean;
}>('form/changeValueInput');

export const editFormSendToBack = createAsyncThunk<
  void,
  {
    step: Step;
    user: IUser;
    currentProjectsId: number;
    history: (s: string) => void;
  },
  {
    rejectValue: MyError;
  }
>(
  'form/editFormSendToBack',
  async ({ step, currentProjectsId, history, user }, thunkAPI) => {
    thunkAPI.dispatch(toggleLoading({ loading: true }));

    if (refreshToken().needRefresh) {
      thunkAPI.dispatch(refreshTokenAction({ history: (s) => history(s) }));
    }

    const response = await updateProject({
      project: currentProjectsId,
      data: {
        content: JSON.stringify(step.content),
        isDone: step.isDone,
        pieceId: step.id,
      },
    });

    const data = await response.json();

    if (response.status > 300) {
      thunkAPI.dispatch(toggleLoading({ loading: false }));
      return thunkAPI.rejectWithValue(data as MyError);
    }

    thunkAPI.dispatch(
      editForms({
        isEditingId: step.isEditing.id,
        user: user,
        projectId: currentProjectsId,
        id: step.id,
        isEditing: false,
        history,
        loading: false,
      }),
    );
  },
);

export const changePartDescriptionPoints = createAction<{
  valueNumber: number;
  value: string;
}>('form/changePartDescriptionPoints');

export const deletePartDescriptionPoints = createAction<{
  valueNumber: number;
}>('form/deletePartDescriptionPoints');

export const deletePartDescriptionTitle = createAction(
  'form/deletePartDescriptionTitle',
);

export const changePartDescriptionTitle = createAction<{
  value: string;
}>('form/changePartDescriptionTitle');

export const addPartDescriptionPoints = createAction(
  'form/addPartDescriptionPoints',
);

export const setCurrentForm = createAsyncThunk<
  Step[],
  { data: IContent[] },
  {
    rejectValue: MyError;
  }
>('form/setCurrentForm', ({ data }, thunkAPI) => {
  const currentSteps: Step[] = data.map((x) => ({
    ...x,
    content: JSON.parse(x.content),
  }));

  thunkAPI.dispatch(toggleLoading({ loading: false }));

  return currentSteps;
});

export const editFormsLocal = createAction<IEditResponse>(
  'form/editFormsLocal',
);

export const editForms = createAsyncThunk<
  IEditResponse,
  {
    projectId: number;
    id: number;
    isEditingId: number;
    user: IUser;
    isEditing: boolean;
    history: (s: string) => void;
    loading?: boolean;
  },
  {
    rejectValue: MyError;
  }
>(
  'form/editForms',
  async (
    { projectId, isEditingId, isEditing, id, history, user, loading },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(toggleLoading({ loading: true }));

    if (refreshToken().needRefresh) {
      thunkAPI.dispatch(refreshTokenAction({ history: (s) => history(s) }));
    }

    const response = await updateEditing({
      projectId,
      isEditingId,
      userId: user.id,
      isEditing,
    });

    if (response.status > 300) {
      thunkAPI.dispatch(toggleLoading({ loading: false }));
      return thunkAPI.rejectWithValue((await response.json()) as MyError);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const load = thunkAPI.getState().loading;

    if (!load.loading) {
      thunkAPI.dispatch(toggleLoading({ loading: false }));
    }
  },
);

export const saveFormAfterEditing = createAsyncThunk<
  Step,
  { projectId: number; pieceId: number; history: (s: string) => void },
  {
    rejectValue: MyError;
  }
>(
  'form/saveFormAfterEditing',
  async ({ pieceId, projectId, history }, thunkAPI) => {
    if (refreshToken().needRefresh) {
      thunkAPI.dispatch(refreshTokenAction({ history: (s) => history(s) }));
    }
    const response = await getForm({ projectId, pieceId });

    if (response.status > 300) {
      thunkAPI.dispatch(toggleLoading({ loading: false }));
      return thunkAPI.rejectWithValue((await response.json()) as MyError);
    }

    const data = await response.json();

    thunkAPI.dispatch(toggleLoading({ loading: false }));

    return { ...data, content: JSON.parse(data.content) };
  },
);

export const saveForm = createAsyncThunk<
  Step,
  { projectId: number; pieceId: number; history: (s: string) => void },
  { rejectValue: MyError }
>('form/saveForm', async ({ pieceId, projectId, history }, thunkAPI) => {
  thunkAPI.dispatch(toggleLoading({ loading: true }));

  if (refreshToken().needRefresh) {
    thunkAPI.dispatch(refreshTokenAction({ history: (s) => history(s) }));
  }
  const response = await getForm({ projectId, pieceId });

  if (response.status > 300) {
    thunkAPI.dispatch(toggleLoading({ loading: false }));
    return thunkAPI.rejectWithValue((await response.json()) as MyError);
  }

  const data = await response.json();
  thunkAPI.dispatch(toggleLoading({ loading: false }));

  return { ...data, content: JSON.parse(data.content) };
});

export const deleteStep = createAsyncThunk<
  { pieceId: number },
  { projectId: number; pieceId: number; history: (s: string) => void },
  {
    rejectValue: MyError;
  }
>('form/deleteStep', async ({ pieceId, projectId, history }, thunkAPI) => {
  if (refreshToken().needRefresh) {
    thunkAPI.dispatch(refreshTokenAction({ history: (s) => history(s) }));
  }

  const response = await deleteStepDelete({ projectId, pieceId });

  if (response.status > 300) {
    thunkAPI.dispatch(toggleLoading({ loading: false }));
    return thunkAPI.rejectWithValue((await response.json()) as MyError);
  }

  return { pieceId };
});

export const createNewStep = createAsyncThunk<
  Step,
  {
    closeModal: () => void;
    projectId: number;
    position: number;
    history: (s: string) => void;
  },
  {
    rejectValue: MyError;
  }
>(
  'projects/createNewStep',
  async ({ projectId, position, history, closeModal }, thunkAPI) => {
    thunkAPI.dispatch(toggleLoading({ loading: true }));
    if (refreshToken().needRefresh) {
      thunkAPI.dispatch(refreshTokenAction({ history: (s) => history(s) }));
    }

    const response = await createNewStepPost({ projectId, position });

    if (response.status > 300) {
      thunkAPI.dispatch(toggleLoading({ loading: false }));
      return thunkAPI.rejectWithValue((await response.json()) as MyError);
    }

    const data = await response.json();

    closeModal();

    thunkAPI.dispatch(toggleLoading({ loading: false }));

    return { ...data, content: JSON.parse(data.content) };
  },
);
