import React, { useEffect, useLayoutEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useQuery } from 'hooks/useQuery';
import { verifyAccount } from 'Redux/user/thunk';

export const VerifyAccount: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const token = useQuery('token');
  const email = useQuery('email');

  useEffect(() => {
    dispatch(
      verifyAccount({
        token: token,
        email: email,
        history: () => history.push('/login'),
      }),
    );
  }, []);

  return <></>;
};
