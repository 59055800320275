import React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => {
  const { color, width, height } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.6779 0.666626H3.14645C1.77574 0.666626 0.666664 1.78533 0.666664 3.16289V5.71632C0.666664 7.09332 1.77598 8.21258 3.14645 8.21258H5.6779C7.04837 8.21258 8.15768 7.09332 8.15768 5.71632V3.16289C8.15768 1.78533 7.04861 0.666626 5.6779 0.666626ZM3.14642 1.82943H5.67787C6.40413 1.82943 6.99486 2.4253 6.99486 3.16291V5.71633C6.99486 6.45345 6.40381 7.04981 5.67787 7.04981H3.14642C2.42048 7.04981 1.82943 6.45345 1.82943 5.71633V3.16291C1.82943 2.4253 2.42017 1.82943 3.14642 1.82943ZM14.8543 0.666626H12.3221C10.9514 0.666626 9.84232 1.78533 9.84232 3.16289V5.71632C9.84232 7.09332 10.9516 8.21258 12.3221 8.21258H14.8543C16.2242 8.21258 17.3333 7.09316 17.3333 5.71632V3.16289C17.3333 1.78549 16.2244 0.666626 14.8543 0.666626ZM12.3221 1.82943H14.8543C15.5799 1.82943 16.1705 2.42538 16.1705 3.16291V5.71633C16.1705 6.45337 15.5796 7.04981 14.8543 7.04981H12.3221C11.5961 7.04981 11.0051 6.45345 11.0051 5.71633V3.16291C11.0051 2.4253 11.5958 1.82943 12.3221 1.82943ZM3.14645 9.78734H5.6779C7.04877 9.78734 8.15768 10.9062 8.15768 12.2844V14.837C8.15768 16.2146 7.04861 17.3333 5.6779 17.3333H3.14645C1.77574 17.3333 0.666664 16.2146 0.666664 14.837V12.2844C0.666664 10.9062 1.77558 9.78734 3.14645 9.78734ZM5.67787 10.9501H3.14642C2.42008 10.9501 1.82943 11.5461 1.82943 12.2844V14.837C1.82943 15.5747 2.42017 16.1705 3.14642 16.1705H5.67787C6.40413 16.1705 6.99486 15.5747 6.99486 14.837V12.2844C6.99486 11.5461 6.40421 10.9501 5.67787 10.9501ZM14.8543 9.78734H12.3221C10.9512 9.78734 9.84232 10.9062 9.84232 12.2844V14.837C9.84232 16.2146 10.9514 17.3333 12.3221 17.3333H14.8543C16.2244 17.3333 17.3333 16.2144 17.3333 14.837V12.2844C17.3333 10.9064 16.2246 9.78734 14.8543 9.78734ZM12.3221 10.9501H14.8543C15.58 10.9501 16.1705 11.5462 16.1705 12.2844V14.837C16.1705 15.5746 15.5799 16.1705 14.8543 16.1705H12.3221C11.5958 16.1705 11.0051 15.5747 11.0051 14.837V12.2844C11.0051 11.5461 11.5957 10.9501 12.3221 10.9501Z"
      />
    </svg>
  );
};

export const Plasts = React.memo(SvgComponent);
