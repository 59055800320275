export const spacing = {
  none: '0',
  tinyMinus: '4px',
  tiny: '8px',
  tinyPlus: '12px',
  smaller: '16px',
  small: '24px',
  medium: '32px',
  mediumPlus: '40px',
  large: '48px',
  huge: '56px',
  massive: '64px',
};
