import React, { CSSProperties } from 'react';
import { ColumnContainer, ColumnTitleWrapper, CustomInput } from './style';
import { color } from 'theme';
import { TableData } from 'Pages/form-builder/formShcema';
import { Text } from 'Components/Text';
import { SvgIcon } from 'Components/svg-icon';
import {
  AddDescriptionButton,
  PoapDescriptionButton,
} from 'Components/Inputs/style';
import { style as styledForm } from './index';

interface IPropsCreateTable {
  table: TableData | undefined;
  setTable: (table: TableData) => void;
}
export const CreateTable = ({ table, setTable }: IPropsCreateTable) => {
  if (!table) return null;

  const handleAddLines = () => {
    const valueCol = table.columns.map(() => '');
    setTable({
      ...table,
      dataSource: [
        ...table.dataSource,
        {
          key: `${table.dataSource.length + 1}`,
          valueCol: valueCol,
        },
      ],
    });
  };

  const handleAddColumn = () => {
    table.dataSource.forEach((x) => (x.valueCol[x.valueCol.length] = ''));
    setTable({
      ...table,
      columns: [
        ...table.columns,
        {
          title: '',
          dataIndex: `col${table.columns.length + 1}`,
          key: `col${table.columns.length + 1}`,
        },
      ],
    });
  };

  const handleChangeInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const value = e.target.value;
    const currentColumns = [...table.columns];
    currentColumns[index] = { ...currentColumns[index], title: value };
    setTable({ ...table, columns: currentColumns });
  };

  const handleChangeInputTable = (
    e: React.ChangeEvent<HTMLInputElement>,
    indexLine: number,
    indexTable: number,
  ) => {
    const value = e.target.value;
    const currentDataSource = JSON.parse(JSON.stringify(table.dataSource));
    currentDataSource[indexLine].valueCol[indexTable] = value;
    setTable({ ...table, dataSource: [...currentDataSource] });
  };

  const handleDeleteColumn = (dataIndex: string, indexColumn: number) => {
    const currentColumn = table.columns
      .filter((x) => {
        return x.dataIndex !== dataIndex;
      })
      .map((x, index) => {
        return {
          title: x.title,
          dataIndex: `col${index + 1}`,
          key: `col${index + 1}`,
        };
      });

    const currentDataSource = [...table.dataSource].map((x) => {
      return {
        ...x,
        valueCol: x.valueCol.filter((y, index) => index !== indexColumn),
      };
    });

    setTable({
      dataSource: currentDataSource,
      columns: currentColumn,
    });
  };

  const handleDeleteLine = (indexLine: string) => {
    const currentDataSource = table.dataSource
      .filter((x) => x.key !== indexLine)
      .map((x, index) => {
        return {
          key: `${index}`,
          valueCol: x.valueCol,
        };
      });

    setTable({
      ...table,
      dataSource: currentDataSource,
    });
  };

  return (
    <div>
      {table.columns.length < 4 && (
        <div>
          <AddDescriptionButton onClick={handleAddColumn}>
            <PoapDescriptionButton>Add column</PoapDescriptionButton>

            <SvgIcon width={14} height={14} icon="plus" color={color.primary} />
          </AddDescriptionButton>
          <Text style={styledForm.buttonText}>add more column</Text>
        </div>
      )}
      <Text style={style.nameSection} color={color.text}>
        Tabel title
      </Text>
      <ColumnContainer>
        {table.columns.map((x, index) => (
          <ColumnTitleWrapper key={x.key}>
            {table.columns.length > 2 && (
              <AddDescriptionButton
                onClick={() => handleDeleteColumn(x.dataIndex, index)}
              >
                <PoapDescriptionButton>Delete column</PoapDescriptionButton>

                <SvgIcon
                  width={14}
                  height={14}
                  icon="delete"
                  color={color.primary}
                />
              </AddDescriptionButton>
            )}
            <CustomInput
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChangeInput(e, index);
              }}
              value={x.title}
            />
          </ColumnTitleWrapper>
        ))}
      </ColumnContainer>

      <div>
        <Text style={style.nameSection} color={color.text}>
          Tabel body
        </Text>
        {table.dataSource.map((x, indexLine) => (
          <>
            {table.dataSource.length > 2 && (
              <AddDescriptionButton onClick={() => handleDeleteLine(x.key)}>
                <PoapDescriptionButton>Delete line</PoapDescriptionButton>
                <SvgIcon
                  width={14}
                  height={14}
                  icon="delete"
                  color={color.primary}
                />
              </AddDescriptionButton>
            )}
            <ColumnContainer key={x.key}>
              {x.valueCol.map((y, indexTable) => (
                <CustomInput
                  key={indexTable}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChangeInputTable(e, indexLine, indexTable);
                  }}
                  value={y}
                />
              ))}
            </ColumnContainer>
          </>
        ))}
        <div>
          <AddDescriptionButton onClick={handleAddLines}>
            <PoapDescriptionButton>Add line</PoapDescriptionButton>
            <SvgIcon width={14} height={14} icon="plus" color={color.primary} />
          </AddDescriptionButton>
          <Text style={styledForm.buttonText}>add more lines</Text>
        </div>
      </div>
    </div>
  );
};

export const style = {
  nameSection: {
    fontWeight: 'bold',
    marginTop: '7px',
    marginBottom: '7px',
  } as CSSProperties,
};
