import * as React from 'react';

function SvgIcon(props: React.SVGProps<SVGSVGElement>) {
  const { color } = props;
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={50} cy={50} r={50} fill="#fff" />
      <path stroke={color} strokeWidth={10} d="M37 24v52M61 24v52" />
    </svg>
  );
}

export const Pause = React.memo(SvgIcon);
