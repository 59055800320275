import React, { useCallback, useEffect, useMemo } from 'react';

import {
  CustomSelectWrapper,
  OptionsCustom,
  OptionsCustomWrapper,
  SelectField,
} from './style';

export interface ICustomSelectProps {
  data: string[];
  placeholder: string;
  onSelect: (s: string) => void;
  children?: React.ReactNode;
  border?: boolean;
  onActive: (s: boolean) => void;
  active: boolean;
}

export const CustomSelect = ({
  data,
  placeholder,
  onSelect,
  children,
  border,
  onActive,
  active,
}: ICustomSelectProps) => {
  useEffect(() => {
    onSelect(placeholder);
  }, []);

  const handleClickOnOption = useCallback(
    (value: string) => {
      onSelect(value);
    },
    [placeholder],
  );

  const handleActive = useCallback(() => {
    onActive(!active);
  }, [active]);

  const options = useMemo(
    () =>
      data.map((element, index) => (
        <OptionsCustom key={index} onClick={() => handleClickOnOption(element)}>
          {element}
        </OptionsCustom>
      )),
    [data],
  );

  return (
    <CustomSelectWrapper onClick={handleActive}>
      <SelectField border={border}>
        {placeholder} {children}
      </SelectField>
      <OptionsCustomWrapper active={active}>{options}</OptionsCustomWrapper>
    </CustomSelectWrapper>
  );
};
