import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Input } from 'antd';
import {
  changePartDescriptionTitle,
  deletePartDescriptionTitle,
} from 'Redux/form/thunk';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { Text } from 'Components/Text';
import { color, spacing } from 'theme';
import {
  AddDescriptionButton,
  ButtonIconWrapper,
  PoapDescriptionButton,
} from 'Components/Inputs/style';
import { SvgIcon } from 'Components/svg-icon';
import { ModalAreUSure } from 'Components/Inputs/ModalDelete';
import { Modal } from 'Components/Modal/Modal';

interface ITitleDescriptionsProps {
  editForm: boolean;
  isEdit: boolean;
}

export const TitleDescriptions = ({
  editForm,
  isEdit,
}: ITitleDescriptionsProps) => {
  const { admin } = useTypedSelector((state) => state.user);
  const titleFormDescription = useTypedSelector(
    (state) => state.formBuilder.currentForm.content.header.title,
  );

  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [idDeleteInput, setIsDeleteInput] = useState(false);
  const [content, setContent] = useState('');

  useEffect(() => {
    setContent(titleFormDescription);
  }, [titleFormDescription]);

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.target.value);
  };

  const save = () => {
    setEdit(false);
    dispatch(
      changePartDescriptionTitle({
        value: content,
      }),
    );
  };

  const deleteDescription = () => {
    setEdit(false);
    dispatch(deletePartDescriptionTitle());
    setIsDeleteInput(false);
  };

  return (
    <>
      <Modal
        toggle={() => setIsDeleteInput(!idDeleteInput)}
        isModalVisible={idDeleteInput}
        title="Participants"
        childrenContent={
          <ModalAreUSure
            text=" Are you sure you want to delete this? This action cannot be undone."
            toggle={() => setIsDeleteInput(false)}
            actionTrue={deleteDescription}
          />
        }
      />
      <div
        style={{
          width: `100%`,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {!edit ? (
          <div>
            <Text
              color={isEdit ? color.text : color.textPale}
              text={content}
              preset="subtitle"
              style={{
                width: `100%`,
                marginTop: spacing.tinyPlus,
              }}
            />
            {admin && editForm && (
              <>
                <ButtonIconWrapper>
                  <AddDescriptionButton onClick={() => setEdit(true)}>
                    <PoapDescriptionButton>
                      Edit description
                    </PoapDescriptionButton>

                    <SvgIcon
                      width={12}
                      height={12}
                      icon="edit"
                      color={color.secondary}
                    />
                  </AddDescriptionButton>
                  <AddDescriptionButton onClick={() => setIsDeleteInput(true)}>
                    <PoapDescriptionButton>
                      Delete description
                    </PoapDescriptionButton>
                    <SvgIcon
                      width={12}
                      height={12}
                      icon="delete"
                      color={color.primary}
                    />
                  </AddDescriptionButton>
                </ButtonIconWrapper>
              </>
            )}
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Input.TextArea
              value={content}
              onChange={onChange}
              autoSize
              style={{ width: 550 }}
            />

            <AddDescriptionButton onClick={save}>
              <SvgIcon
                width={12}
                height={12}
                icon="edit"
                color={color.secondary}
              />
            </AddDescriptionButton>
          </div>
        )}
      </div>
    </>
  );
};
