import React, { useState } from 'react';
import { Select } from 'antd';
import { CreateCheckbox } from 'Components/ItselfInput/CreateCheckbox';
import {
  ButtonCreate,
  ColumnContainer,
  CustomInput,
} from 'Components/ItselfInput/style';
import { useDispatch } from 'react-redux';
import { Text } from 'Components/Text';
import { changeInput, changeTable } from 'Redux/form/thunk';
import {
  Input,
  inputTypes,
  optionsInput,
  TableData,
} from 'Pages/form-builder/formShcema';
import { CreateTable } from 'Components/ItselfInput/CreateTable';
import { AddDescriptionButton } from 'Components/Inputs/style';

const { Option } = Select;

interface IPropsInForm {
  input: Input;
  index: number;
  closeModal: () => void;
  footer: React.ReactNode;
  formDescription: React.ReactNode;
}

export const FormForEditMember = ({
  input,
  index,
  closeModal,
}: IPropsInForm) => {
  const [inputData, setInputData] = useState<Input>({ ...input });
  const dispatch = useDispatch();

  const handleChangeInputTable = (
    e: React.ChangeEvent<HTMLInputElement>,
    indexLine: number,
    indexTable: number,
  ) => {
    const value = e.target.value;
    const currentDataSource = JSON.parse(
      JSON.stringify(inputData.tableData.dataSource),
    );
    currentDataSource[indexLine].valueCol[indexTable] = value;
    setTable({ ...inputData.tableData, dataSource: [...currentDataSource] });
  };
  const setTable = (table: TableData) => {
    setInputData({ ...inputData, tableData: table });
  };

  const handleClickToChangeInputs = () => {
    const values = [...inputData.values];
    if (inputData.type === optionsInput.table) {
      if (!inputData.tableData) return;
      dispatch(changeTable({ inputIndex: index, data: inputData.tableData }));
      closeModal();
      return;
    }
    const data = { ...inputData, values: values };
    dispatch(changeInput({ inputIndex: index, data: data }));
    closeModal();
  };

  return (
    <div>
      {inputData.type === optionsInput.table && (
        <>
          <Text>Tabel body</Text>
          {inputData.tableData.dataSource.map((x, indexLine) => (
            <>
              <ColumnContainer key={x.key}>
                {x.valueCol.map((y, indexTable) => (
                  <CustomInput
                    key={indexTable}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChangeInputTable(e, indexLine, indexTable);
                    }}
                    value={y}
                  />
                ))}
              </ColumnContainer>
            </>
          ))}
        </>
      )}

      <ButtonCreate
        onClick={handleClickToChangeInputs}
        style={{ width: '100%' }}
      >
        <Text>Change</Text>
      </ButtonCreate>
    </div>
  );
};
