import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { saveAllProjects } from 'Redux/projects/thunk';
import { IProjects } from 'Redux/projects/type';

import { Project } from 'Blocks/Project/index';
import {
  ProjectsContainer,
  ProjectsHeader,
  ProjectsBlocks,
  ProjectsTitle,
  ViewAllProjects,
} from './styles';

export const LastProjects = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('common');
  const [lastTwoProjectsArr, setLastTwoProjectsArr] = useState<IProjects[]>([]);
  const projects = useTypedSelector((state) => state.projects.projects);

  useEffect(() => {
    dispatch(saveAllProjects({ history: (s) => history.push(s) }));
  }, []);

  useEffect(() => {
    setLastTwoProjectsArr(
      projects
        .map((x) => x)
        .sort(
          (p1, p2) => new Date(p2.date).getTime() - new Date(p1.date).getTime(),
        )
        .filter((x, index) => index <= 1),
    );
  }, [projects]);

  return (
    <ProjectsContainer>
      <ProjectsHeader>
        <ProjectsTitle>{t('projects.lastProjects')}</ProjectsTitle>
        <ViewAllProjects to="/dashboards">
          {t('projects.viewAll')}
          <FontAwesomeIcon
            icon={faLongArrowAltRight}
            style={{ marginLeft: '5px' }}
          />
        </ViewAllProjects>
      </ProjectsHeader>
      <ProjectsBlocks>
        {lastTwoProjectsArr.map((project) => (
          <Project project={project} key={project.id} small={true} />
        ))}
      </ProjectsBlocks>
    </ProjectsContainer>
  );
};
