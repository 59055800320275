import React from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { sendEmailUser } from 'Redux/projects/thunk';
import { InputField } from 'Components/FormAuth/style';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { useHistory } from 'react-router';
import { IParticipants } from 'Redux/projects/type';
import { ButtonSearch, SearchForm, WrapperModal } from './styles';
import { ParticipantsItem } from './ParticipantsItem';

interface IAddUserForm {
  email: string;
}
interface IModalContentProps {
  projectId: number;
  participants: IParticipants[];
}

const schemaEmail = yup.object().shape({
  email: yup.string().email().required('this field is required'),
});

export const ModalParticipants = ({
  projectId,
  participants,
}: IModalContentProps) => {
  const currentUser = useTypedSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    errors,
    control,
    reset,
  } = useForm<IAddUserForm>({
    resolver: yupResolver(schemaEmail),
    defaultValues: { email: '' },
  });

  const handleAddEmail = (value: { email: string }) => {
    if (!projectId) return;
    dispatch(
      sendEmailUser({
        projectId: projectId,
        email: value.email,
        history: (s) => history.push(s),
      }),
    );
    reset();
  };

  return (
    <WrapperModal>
      {(participants.filter((p) => p.role === 'owner')[0].user.id ===
        currentUser.id ||
        currentUser.admin) && (
        <SearchForm onSubmit={handleSubmit(handleAddEmail)}>
          <Controller
            control={control}
            name="email"
            render={() => (
              <InputField
                error={errors.email}
                placeholder="Email"
                type="email"
                name="email"
                ref={register}
              />
            )}
          />
          <ButtonSearch>Share</ButtonSearch>
        </SearchForm>
      )}
      <WrapperModal>
        {participants.map((el) => (
          <ParticipantsItem
            projectOwner={
              participants.filter((p) => p.role === 'owner')[0].user.id
            }
            user={el.user}
            status={el.status}
            projectId={projectId}
            key={el.id}
          />
        ))}
      </WrapperModal>
    </WrapperModal>
  );
};
