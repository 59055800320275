import { IUser } from 'Redux/user/type';

export interface ILocation {
  id: number;
  name: string;
  code: string;
}

export interface IContent {
  content: string;
  isDone: boolean;
  id: number;
  position: number;
}

export interface IParticipants {
  id: number;
  status: string;
  role: string;
  user: IUser;
  projectId: number;
}

export interface IProjects {
  content: IContent[];
  date: string;
  creator: IUser;
  location: ILocation;
  oc: string;
  name: string;
  id: number;
  status: 'develop' | 'complete';
  participants: IParticipants[];
}

export const dataOSProject = ['OC', 'OCA', 'OCB', 'OCBA', 'OCG', 'OCP'];
