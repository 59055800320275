import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { registerUser } from 'Redux/user/thunk';
import { Text } from 'Components/Text';
import { color } from 'theme';
import { IFormRegister } from 'Utils/Api/type';
import { InputPassword } from 'Components/InputPassword/InputPassword';
import { ButtonSubmit, Form, InputField, InputFieldPassword } from './style';

const schema = yup.object().shape({
  name: yup.string().required('this field is required'),
  email: yup.string().email().required('this field is required'),
  surname: yup.string().required('this field is required'),
  password: yup.string().min(6, 'min 6').required('this field is required'),
  confirmPassword: yup
    .string()
    .min(6, 'min 6')
    .required('this field is required')
    .oneOf([yup.ref('password'), null], 'The passwords do not match'),
});

const defaultValue = {
  email: '',
  name: '',
  surname: '',
  password: '',
  confirmPassword: '',
};

interface IPropsComponent {
  buttonText: string;
}

export const SingUpForm = ({ buttonText }: IPropsComponent) => {
  const dispatch = useDispatch();
  const [errorLogin, setErrorLogin] = useState('');
  const [singUp, setSingUp] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showPassConfirm, setShowPassConfirm] = useState(false);

  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
  } = useForm<IFormRegister>({
    resolver: yupResolver(schema),
    defaultValues: defaultValue,
  });

  const submitForm = (data: IFormRegister) => {
    dispatch(
      registerUser({
        value: data,
        setErrorLogin: (s) => setErrorLogin(s),
        history: () => setSingUp(true),
      }),
    );
  };

  return (
    <>
      {singUp ? (
        <Text preset="subtitle" style={{ marginTop: '20px' }}>
          Message with confirmation sent to your email
        </Text>
      ) : (
        <Form novalidate onSubmit={handleSubmit(submitForm)}>
          <Controller
            control={control}
            name="name"
            render={() => (
              <InputField
                error={errors.name}
                placeholder="Name"
                type="test"
                name="name"
                ref={register}
                defaultValue=""
              />
            )}
          />
          <Controller
            control={control}
            name="surname"
            render={() => (
              <InputField
                error={errors.surname}
                placeholder="Surname"
                type="text"
                name="surname"
                ref={register}
                defaultValue=""
              />
            )}
          />
          <Controller
            control={control}
            name="email"
            render={() => (
              <>
                <InputField
                  error={errors.email}
                  placeholder="Email"
                  type="text"
                  name="email"
                  ref={register}
                  defaultValue=""
                />
                {errors.email && (
                  <Text style={{ marginBottom: '10px' }} color={color.primary}>
                    {errors.email.message}
                  </Text>
                )}
              </>
            )}
          />
          <Controller
            control={control}
            name="password"
            render={() => (
              <InputPassword
                setShowPass={(b) => setShowPass(b)}
                showPass={showPass}
                error={errors.password}
                input={
                  <InputFieldPassword
                    error={errors.password}
                    placeholder="Password"
                    type={showPass ? 'input' : 'password'}
                    name="password"
                    ref={register}
                    defaultValue=""
                  />
                }
              />
            )}
          />
          <Text preset="bodyTiny" style={{ marginBottom: '20px' }}>
            Example: Qwerty_4 ( We recommend that your password be at least 8
            characters long and that you use a combination of letters, numbers
            and symbols)
          </Text>
          <Controller
            control={control}
            name="confirmPassword"
            render={() => (
              <InputPassword
                setShowPass={(b) => setShowPassConfirm(b)}
                showPass={showPassConfirm}
                error={errors.confirmPassword}
                input={
                  <InputFieldPassword
                    error={errors.confirmPassword}
                    placeholder="Confirm password"
                    type={showPassConfirm ? 'input' : 'password'}
                    name="confirmPassword"
                    ref={register({
                      validate: (value) =>
                        value === getValues('password') ||
                        'The passwords do not match',
                    })}
                    defaultValue=""
                  />
                }
              />
            )}
          />
          {errorLogin && (
            <Text style={{ marginBottom: '10px' }} color={color.primary}>
              {errorLogin}
            </Text>
          )}
          <ButtonSubmit type="submit">
            <Text>{buttonText}</Text>
          </ButtonSubmit>
        </Form>
      )}
    </>
  );
};
