import React, { useEffect, useState } from 'react';
import { StyledCheckbox } from 'Pages/createProject/style';
import { useDispatch } from 'react-redux';
import { changeValueInput } from 'Redux/form/thunk';
import { EntryProps } from './render-step';

export const Checkbox = ({
  entry,
  edit,
  value,
  inputNumber,
  valueNumber,
}: EntryProps) => {
  const [checked, setChecked] = useState(value);
  const dispatch = useDispatch();

  useEffect(() => {
    setChecked(value);
  }, [value]);

  const handleChange = () => {
    if (!edit) return setChecked(checked);
    setChecked(!checked);

    dispatch(
      changeValueInput({
        inputNumber: inputNumber,
        valueNumber: valueNumber,
        value: !checked,
      }),
    );
  };

  return (
    <StyledCheckbox edit={edit} onClick={handleChange} checked={checked}>
      {entry}
    </StyledCheckbox>
  );
};
