import styled from 'styled-components';
import { color } from 'theme';

export const ContainerPages = styled.div`
  display: flex;
  width: 100%;
`;

export const ContainerPagesWithNavBar = styled.div`
  margin-top: 77px;
  display: grid;
  grid-template-columns: 78px 1fr;
  box-sizing: border-box;
`;

export const ContainerColor = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: ${color.backgroundSecondary};
`;
