import { Back } from './back';
import { NeedHelp } from './need-help';
import { Pause } from './pause';
import { Setting } from './setting';
import { Download } from './download';
import { ProjectTitle } from './projectTitle';
import { Plus } from './plus';
import { Edit } from './edit';
import { Delete } from './delete';
import { Plasts } from './plasts';
import { AddUser } from './addUser';
import { Notification } from './notification';
import { missMessages } from './missMessages';
import { eyeHidden } from './eyeHidden';
import { eyeShow } from './eyeShow';

export const icons = {
  back: Back,
  needHelp: NeedHelp,
  pause: Pause,
  setting: Setting,
  download: Download,
  projectTitle: ProjectTitle,
  plus: Plus,
  edit: Edit,
  delete: Delete,
  plasts: Plasts,
  addUser: AddUser,
  notification: Notification,
  missMessages: missMessages,
  eyeHidden: eyeHidden,
  eyeShow: eyeShow,
};

export type IconTypes = keyof typeof icons;
