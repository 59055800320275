import React, { ReactNode, useCallback } from 'react';
import { FieldError } from 'react-hook-form';
import { SvgIcon } from 'Components/svg-icon';
import { color } from 'theme';
import { InputFieldWrapper, WrapperSvg } from './style';

interface IInputPasswordProps {
  input: ReactNode;
  showPass: boolean;
  setShowPass: (b: boolean) => void;
  error: FieldError;
}

export const InputPassword = ({
  input,
  error,
  showPass,
  setShowPass,
}: IInputPasswordProps) => {
  const handleClickToShowPass = useCallback(() => {
    setShowPass(!showPass);
  }, [showPass]);

  return (
    <InputFieldWrapper error={error}>
      {input}
      {showPass ? (
        <WrapperSvg onClick={handleClickToShowPass}>
          <SvgIcon icon="eyeHidden" size={20} color={color.textDarkGrey} />
        </WrapperSvg>
      ) : (
        <WrapperSvg onClick={handleClickToShowPass}>
          <SvgIcon icon="eyeShow" size={20} color={color.textDarkGrey} />
        </WrapperSvg>
      )}
    </InputFieldWrapper>
  );
};
