import React, { useMemo } from 'react';
import { MyTeam } from 'Components/MyTeam/index';
import { LastProjects } from 'Components/ProjectsWrapper/index';
import { NewProjectButton } from 'Components/NewProject';
import { ProjectsTitle } from 'Components/ProjectsWrapper/styles';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { Interventions } from 'Components/Interventions';
import { Welcome } from './welcome';
import { HomeWrapper, Page, ProjectAndTeam, RightSide } from './style';

export const Home: React.FC = () => {
  const { width } = useWindowDimensions();

  return (
    <Page>
      <HomeWrapper>
        <RightSide>
          <Welcome />
        </RightSide>

        <ProjectAndTeam>
          <ProjectsTitle>New project</ProjectsTitle>
          <NewProjectButton />
          <LastProjects />
          <MyTeam />
        </ProjectAndTeam>
      </HomeWrapper>
      <Interventions />
    </Page>
  );
};
