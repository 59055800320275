import React from 'react';
import { TeamMember } from 'Blocks/TeamMember/index';
import { useTranslation } from 'react-i18next';
// import userOne from 'Assets/images/team.png';
// import userTwo from 'Assets/images/teams1.png';
// import userThree from 'Assets/images/team2.png';
// import userFour from 'Assets/images/team3.png';
import { MembersWrapper, MembersWrapperTitle } from './styles';

export const MyTeam = () => {
  const { t } = useTranslation('common');

  return (
    <MembersWrapper>
      <MembersWrapperTitle>{t('team.myTeam')}</MembersWrapperTitle>
      <TeamMember
        // link={userOne}
        name="Hanna Phelan"
        position="Case Manager, SIU"
      />
      <TeamMember
        // link={userTwo}
        name="Marpe Tanaka"
        position="Innovation Advisor, SIU"
      />
      <TeamMember
        // link={userThree}
        name="Abiy Tamrat"
        position="Medical Technologies Lead, OCG"
      />
      <TeamMember
        name="Fabien Schneider"
        position="Senior Program Manager, Telemedicine Unit Canada"
        // link={userFour}
      />
    </MembersWrapper>
  );
};
