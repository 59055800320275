import React, { createContext, useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SingIn } from 'Pages/auth/login';
import { SingUp } from 'Pages/auth/register';
import { ForgetPassword } from 'Pages/forgetPassword';
import { Header } from 'Components/Header';
import { SideNavigation } from 'Components/SiderNav';
import { Dashboard } from 'Pages/dashboard';
import { Graph } from 'Pages/graph';
import { Users } from 'Pages/users';
import { Filters } from 'Pages/filters';
import { Settings } from 'Pages/settigs';
import { saveUser } from 'Redux/user/thunk';
import {
  ContainerColor,
  ContainerPages,
  ContainerPagesWithNavBar,
} from 'Components/Container/style';
import { AssignUser } from 'Pages/assign_user';
import { CreateProject } from 'Pages/createProject';
import { Home } from 'Pages/home';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { VerifyAccount } from 'Pages/verifyAccount/VerifyAccount';
import { Loading } from 'Components/Loading';
import { PrivateRoute } from './PrivateRoute';
import { PrivateRouteForAuth } from './PrivateRouteForAuth';
import { PublicRoute } from './PublicRoute';

export const LeftSiderShown = createContext({
  show: () => {
    // avoid Unexpected empty arrow function
  },
  hide: () => {
    // avoid Unexpected empty arrow function
  },
});

export const AppRoute = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [leftSiderShow, setLeftSiderShow] = useState(true);

  const isAuth = useTypedSelector((state) => state.user.isAuth);

  const siderContext = useMemo(
    () => ({
      show: () => setLeftSiderShow(true),
      hide: () => setLeftSiderShow(false),
    }),
    [],
  );

  useEffect(() => {
    if (isAuth) {
      dispatch(saveUser({ history: () => history.push('/login') }));
    }
  }, []);

  return (
    <LeftSiderShown.Provider value={siderContext}>
      <Loading />
      <Switch>
        <PrivateRouteForAuth exact path="/register" component={SingUp} />
        <PrivateRouteForAuth exact path="/login" component={SingIn} />
        <PrivateRouteForAuth
          exact
          path="/resetPass"
          component={ForgetPassword}
        />

        <Route
          path="/auth/reset_pass"
          render={() => {
            if (isAuth) {
              return <Redirect to="/" />;
            }
            return <ForgetPassword />;
          }}
        />

        <Route
          path="/auth/verify_account"
          render={() => {
            if (isAuth) {
              return <Redirect to="/" />;
            }
            return <VerifyAccount />;
          }}
        />

        <ContainerColor>
          <Header />
          <ContainerPagesWithNavBar>
            {leftSiderShow && <SideNavigation />}
            <PrivateRoute exact path="/dashboards" component={Dashboard} />
            <PrivateRoute exact path="/graphs" component={Graph} />
            <PrivateRoute exact path="/users" component={Users} />
            <PrivateRoute exact path="/filters" component={Filters} />
            <PrivateRoute exact path="/settings" component={Settings} />
            <PublicRoute path="/assign_user" component={AssignUser} />
            <PrivateRoute exact path="/" component={Home} />
          </ContainerPagesWithNavBar>
          <ContainerPages>
            <PrivateRoute exact path="/create" component={CreateProject} />
            <PrivateRoute
              exact
              path="/project/:projectID"
              component={CreateProject}
            />
          </ContainerPages>
          {/*<PublicRoute path="/error" component={Error} />*/}
        </ContainerColor>
      </Switch>
    </LeftSiderShown.Provider>
  );
};
