import React from 'react';
import { Input, optionsInput } from 'Pages/form-builder/formShcema';
import { InputTextArea } from 'Pages/form-builder/input-textarea';
import { Switcher } from 'Pages/form-builder/switch';
import { TableRender } from 'Pages/form-builder/table';
import { Checkbox } from 'Pages/form-builder/checkbox';

interface IPropsGenerateInputsByType {
  entry: string;
  input: Input;
  edit: boolean;
  value: string | boolean;
  valueNumber: number;
  inputNumber: number;
}

export const GenerateInputsByType = ({
  entry,
  value,
  input,
  edit,
  valueNumber,
  inputNumber,
}: IPropsGenerateInputsByType) => {
  if (input.type === optionsInput.checkbox) {
    return (
      <Checkbox
        value={value}
        valueNumber={valueNumber}
        inputNumber={inputNumber}
        edit={edit}
        entry={entry}
      />
    );
  } else if (input.type === optionsInput.input) {
    return (
      <InputTextArea
        valueNumber={valueNumber}
        inputNumber={inputNumber}
        value={value}
        edit={edit}
        entry={entry}
      />
    );
  } else if (input.type === optionsInput.switch) {
    return (
      <Switcher
        valueNumber={valueNumber}
        inputNumber={inputNumber}
        value={value}
        edit={edit}
        entry={entry}
      />
    );
  } else if (input.type === optionsInput.table) {
    return <TableRender edit={edit} tableData={input.tableData} />;
  }
  return <>no content</>;
};
