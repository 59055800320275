import React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => {
  const { color, width, height } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.23114 0.666992C4.7732 0.666992 2.79948 2.64041 2.79948 5.09866C2.79948 7.55634 4.77347 9.53032 7.23114 9.53032C9.68939 9.53032 11.6628 7.5566 11.6628 5.09866C11.6628 2.64015 9.68966 0.666992 7.23114 0.666992ZM7.23114 1.91699C8.9993 1.91699 10.4128 3.3305 10.4128 5.09866C10.4128 6.86629 8.99899 8.28033 7.23114 8.28033C5.46382 8.28033 4.04948 6.86598 4.04948 5.09866C4.04948 3.33081 5.46351 1.91699 7.23114 1.91699ZM7.23116 11.0472C3.03948 11.0472 0.666992 11.8426 0.666992 14.0964C0.666992 16.3527 3.05001 17.1631 7.23116 17.1631L7.76741 17.1585C11.6237 17.0911 13.7953 16.2691 13.7953 14.1139C13.7953 11.9067 11.5148 11.0832 7.50129 11.0484L7.23116 11.0472ZM7.23116 12.2972C10.7324 12.2972 12.5453 12.9138 12.5453 14.1139C12.5453 15.3066 10.7368 15.9131 7.23116 15.9131C3.72994 15.9131 1.91699 15.2965 1.91699 14.0964C1.91699 12.9355 3.62813 12.3304 6.95056 12.2986L7.23116 12.2972ZM15.0034 5.59966C15.3198 5.59966 15.5813 5.83479 15.6227 6.13985L15.6284 6.22466V7.27041H16.7083C17.0535 7.27041 17.3333 7.55024 17.3333 7.89541C17.3333 8.21183 17.0982 8.47332 16.7931 8.51471L16.7083 8.52041H15.6284V9.56633C15.6284 9.9115 15.3486 10.1913 15.0034 10.1913C14.687 10.1913 14.4255 9.9562 14.3841 9.65114L14.3784 9.56633V8.52041H13.3C12.9548 8.52041 12.675 8.24059 12.675 7.89541C12.675 7.579 12.9101 7.3175 13.2152 7.27612L13.3 7.27041H14.3784V6.22466C14.3784 5.87948 14.6582 5.59966 15.0034 5.59966Z" />
    </svg>
  );
};

export const AddUser = React.memo(SvgComponent);
