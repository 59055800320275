import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow';
import { color } from 'theme';
import { MapContainer, MapItem } from './style';
import { IProjectHover } from '../Interventions';

interface IMapProps {
  projectHover: IProjectHover;
}

export const Map = ({ projectHover }: IMapProps) => {
  const [map, setMap] = useState(false);

  const chartRef = useRef(null);
  const polygonSeries = useRef(null);

  useLayoutEffect(() => {
    if (!chartRef.current) {
      chartRef.current = am4core.create('chartdiv', am4maps.MapChart);

      chartRef.current.geodata = am4geodata_worldLow;

      chartRef.current.projection = new am4maps.projections.Miller();

      polygonSeries.current = chartRef.current.series.push(
        new am4maps.MapPolygonSeries(),
      );

      polygonSeries.current.useGeodata = true;

      const polygonTemplate = polygonSeries.current.mapPolygons.template;
      polygonTemplate.tooltipText = '{name}';
      polygonTemplate.nonScalingStroke = true;
      polygonTemplate.fill = am4core.color(color.secondary);
      setMap(!map);
      const hs = polygonTemplate.states.create('hover');
      hs.properties.fill = am4core.color(color.primary);

      polygonSeries.current.exclude = ['AQ'];

      chartRef.current.zoomControl = new am4maps.ZoomControl();

      chartRef.current.zoomControl.slider.height = 100;

      const home = chartRef.current.chartContainer.createChild(am4core.Button);
      home.label.text = 'Home';
      home.align = 'right';
      home.events.on('hit', function () {
        chartRef.current.goHome();
      });

      chartRef.current.events.on('change', function () {
        chartRef.current.zoomToMapObject(
          polygonSeries.current.getPolygonById('UA'),
        );
      });

      polygonTemplate.propertyFields.fill = 'fill';
    }
    return () => {
      chartRef.current && chartRef.current.dispose();
    };
  }, []);

  useEffect(() => {
    if (polygonSeries.current) {
      polygonSeries.current.data = [
        {
          id: projectHover.code,
          name: projectHover.name,
          fill: am4core.color(color.primary),
        },
      ];
    }
  }, [projectHover]);

  return (
    <MapContainer>
      <MapItem id="chartdiv" />
    </MapContainer>
  );
};
