import { createReducer } from '@reduxjs/toolkit';
import {
  forgotPassword,
  loginUser,
  logOut,
  refreshTokenAction,
  registerUser,
  saveUser,
  updateUserAvatar,
} from './thunk';
import { InitialState, UserPayload } from './type';

export const isAuthSave = () => {
  return localStorage.getItem('accessToken');
};

export const initialState: InitialState = {
  email: '',
  name: '',
  surname: '',
  isConfirmed: false,
  projects: [],
  error: '',
  password: '',
  admin: false,
  avatar: null,
  id: 0,
  isAuth: !!isAuthSave(),
};

const fulfilledUser = (state: InitialState, payload: UserPayload) => {
  state.error = '';
  state.email = payload.email;
  state.admin = payload.admin;
  state.isConfirmed = payload.isConfirmed;
  state.id = payload.id;
  state.name = payload.name;
  state.avatar = payload.avatar;
  state.surname = payload.surname;
};

const fulfilledUserReset = (state: InitialState) => {
  state.error = '';
  state.email = '';
  state.admin = false;
  state.id = 0;
  state.name = '';
  state.surname = '';
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(registerUser.rejected, (state: InitialState, action) => {
      if (action.payload) {
        state.error = action.payload.message;
      }
    })
    .addCase(loginUser.fulfilled, (state: InitialState, { payload }) => {
      state.isAuth = true;

      fulfilledUser(state, payload.user);
    })
    .addCase(loginUser.rejected, (state: InitialState, action) => {
      if (action.payload) {
        state.error = action.payload.message;
      }
    })
    .addCase(forgotPassword.rejected, (state: InitialState, action) => {
      if (action.payload) {
        state.error = action.payload.message;
      }
    })
    .addCase(saveUser.fulfilled, (state: InitialState, { payload }) => {
      state.isAuth = true;
      fulfilledUser(state, payload);
    })
    .addCase(saveUser.rejected, (state: InitialState, { payload }) => {
      state.isAuth = false;
    })
    .addCase(logOut, (state: InitialState) => {
      state.isAuth = false;
      fulfilledUserReset(state);
    })
    .addCase(
      refreshTokenAction.rejected,
      (state: InitialState, { payload }) => {
        state.isAuth = false;
      },
    )
    .addCase(updateUserAvatar.fulfilled, (state: InitialState, { payload }) => {
      state.avatar = payload.img;
    });
});
