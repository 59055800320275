import React from 'react';
import { Text } from 'Components/Text';
import { Close, ModalContainer, ModalWrapper, TitleWrapper } from './style';

interface IModalProps {
  toggle: () => void;
  isModalVisible: boolean;
  title: string;
  childrenContent: React.ReactNode;
}

export const Modal = ({
  toggle,
  isModalVisible,
  title,
  childrenContent,
}: IModalProps) => {
  const toggleEl = (e: React.ChangeEvent) => {
    if (e.target.id === 'close') {
      toggle();
    }
  };

  return (
    <>
      {isModalVisible && (
        <ModalContainer
          id="close"
          onClick={(e: React.ChangeEvent) => toggleEl(e)}
        >
          <ModalWrapper>
            <TitleWrapper>
              <Text preset="header2">{title}</Text>
              <Close onClick={toggle} id="close" />
            </TitleWrapper>
            {childrenContent}
          </ModalWrapper>
        </ModalContainer>
      )}
    </>
  );
};
