import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { color } from 'theme';
import { ButtonSubmit } from 'Components/FormAuth/style';

export const ProjectWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  margin: auto auto 20px auto;
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
  background: ${color.background};
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
`;

export const SvgIconWrapper = styled.ul`
  margin: 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: none;
  justify-content: flex-end;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.7);
`;

export const SvgIconItem = styled.li`
  list-style: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  & svg {
    fill: ${color.background};
  }

  &:last-child {
    margin: 0;
  }
`;

export const ProjectWrapperTop = styled.div`
  cursor: pointer;
  height: 117px;
  background-size: cover;
  background-position: center;
  border-radius: 6px 6px 0 0;
  ${(props) =>
    css`
      background-image: url(${props.image});
    `};

  &:hover ${SvgIconWrapper} {
    display: flex;
  }
`;

export const ProjectWrapperBottom = styled.div`
  border-radius: 0 0 6px 6px;
  display: flex;
  justify-content: space-between;
  height: calc(100% - 117px);
  align-items: center;
  padding: 0 10px;
  filter: drop-shadow(0px 2.4px 2.4px rgba(0, 0, 0, 0.25));
`;

export const ProjectWrapperBottomLeft = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
`;

export const ProjectWrapperBottomRight = styled.div`
  cursor: pointer;
  position: relative;
  height: 100%;
  max-width: 40%;
  min-width: 13%;
  display: flex;
  align-items: center;
`;

export const Title = styled(Link)`
  cursor: pointer;
  font-family: Raleway, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  color: ${color.text};
  margin-bottom: 5px;

  &:hover {
    color: ${color.text};
  }
`;
export const Time = styled.p`
  font-family: Raleway, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.078px;
  color: ${color.secondary};
`;

export const Subscriber = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: ${(p) => (p.static ? 'static' : 'relative')};
  min-width: ${(props) => (props.small ? '25px' : '40px')};
  border: 0.5px solid
    ${(props) => (props.static ? 'transparent' : color.background)};
  height: ${(props) => (props.small ? '25px' : '40px')};
  font-size: ${(props) => (props.small ? '11px' : '16px')};
  border-radius: 100%;
  background-size: cover;
  background-position: center;
  background-color: red;
  color: ${color.background};
  text-transform: uppercase;
  ${(props) =>
    css`
      left: ${(props) => !props.static && props.left}px;
      background-image: url(${props.image});
    `};
`;

export const SubscriberBlock = styled(Subscriber)`
  background-color: ${color.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${color.background};
`;

export const AvatarParticipants = styled.img`
  width: ${(props) => (props.small ? '25px' : '40px')};
  height: ${(props) => (props.small ? '25px' : '40px')};
  border-radius: 100%;
`;

export const WrapperModal = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ButtonSearch = styled(ButtonSubmit)`
  width: 85px;
  margin-left: 10px;
`;

export const SearchForm = styled.form`
  width: 100%;
  display: flex;
`;

export const ParticipantsWrapper = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RoleWrapper = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100px;
`;

export const ParticipantData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OptionsMenu = styled.div`
  display: flex;
  width: 20px;
  justify-content: space-around;
  align-items: center;
  position: relative;
`;

export const Point = styled.div`
  width: 3px;
  height: 3px;
  background-color: ${color.textPale};
  border-radius: 50%;
`;
