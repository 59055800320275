import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { useHistory } from 'react-router';
import { IProjects } from 'Redux/projects/type';
import { downloadProject, saveAllProjects } from 'Redux/projects/thunk';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { Cell, InterventionsTable, RowBodyWrapp, RowTable } from './style';
import { IProjectHover } from './index';

const tableCells = {
  title: [
    'Telehealth Intervention Description',
    'Location',
    'OC',
    'Project Lead',
    'Date Added',
    'Status',
  ],

  projects: [
    {
      description: 'Mental Health Hotline in Colombia',
      location: 'Buenaventura',
      oc: 'OCB',
      projectLead: 'Erika Chavez',
      dateAdded: 'December 2020',
      status: 'Complete - Click for PDF Summary',
    },
    {
      description: 'Mental Health Hotline in Colombia',
      location: 'Buenaventura',
      oc: 'OCB',
      projectLead: 'Erika Chavez',
      dateAdded: 'August 2020',
      status: 'Complete - Private: Contact Project Lead for Information',
    },
    {
      description: 'Teleassistance for SGBV',
      location: 'El Salvador',
      oc: 'OCB',
      projectLead: 'Chloe Massuel',
      dateAdded: 'Jan 2021',
      status: 'Complete - Click for PDF Summary',
    },
  ],
};

interface IProjectTableProps {
  setProjectHover: (s: IProjectHover) => void;
  projectHover: IProjectHover;
}

export const ProjectTable = ({
  setProjectHover,
  projectHover,
}: IProjectTableProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const projects = useTypedSelector((state) => state.projects.projects);

  useEffect(() => {
    dispatch(saveAllProjects({ history: (s) => history.push(s) }));
  }, []);

  const handleDownloadPdf = (s: 'develop' | 'complete', projectId: number) => {
    if (s === 'complete') {
      dispatch(downloadProject({ projectId, history: (s) => history.push(s) }));
    }
  };

  const handleonMouseEnter = useCallback(
    (project: IProjects) => {
      setProjectHover({
        code: project.location.code,
        name: project.name,
      });
    },
    [projectHover],
  );

  const handleoOnMouseLeave = useCallback(() => {
    setProjectHover({
      code: '',
      name: '',
    });
  }, [projectHover]);

  return (
    <InterventionsTable>
      <RowTable header>
        {tableCells.title.map((title, index) => (
          <Cell key={index} header>
            {title}
          </Cell>
        ))}
      </RowTable>
      <RowBodyWrapp>
        {projects.map((project, index) => (
          <RowTable
            onMouseEnter={() => handleonMouseEnter(project)}
            onMouseLeave={() => handleoOnMouseLeave}
            key={index}
          >
            <Cell key={index + 100}>{project.name}</Cell>
            <Cell key={index + 200}>{project.location.name}</Cell>
            <Cell key={index + 300}>{project.oc}</Cell>
            <Cell key={index + 400}>{project.creator.name}</Cell>
            <Cell key={index + 500}>
              {format(new Date(project.date), 'LLLL Y')}
            </Cell>
            <Cell
              isCursor
              onClick={() => handleDownloadPdf(project.status, project.id)}
              key={index + 600}
            >
              {project.status}
            </Cell>
          </RowTable>
        ))}
      </RowBodyWrapp>
    </InterventionsTable>
  );
};
