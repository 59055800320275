import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import AvatarDefault from 'Assets/images/avatar.png';
import { color } from 'theme';

export const HeaderContainer = styled.header`
  z-index: 100;
  position: fixed;
  width: 100%;
  background-color: ${color.background};
  border-bottom: 1px solid ${color.backgroundPaginator};
  display: flex;
  justify-content: space-between;
`;

export const HeaderLogo = styled.img`
  height: 76px;
`;

export const NavWrapper = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

export const NavItem = styled.li`
  list-style: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: flex;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  & svg {
    fill: ${color.secondary};
  }

  &:hover svg {
    fill: ${color.primary};
  }
`;

export const LinkMenu = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Avatar = styled.div`
  width: 40px;
  height: 40px;
  background-size: cover;
  background-position: center;
  border-radius: 100%;
  margin-left: 25px;
  ${(props) =>
    css`
      background-image: url(${props.link ? props.link : AvatarDefault});
    `};
`;

export const LogOutLink = styled.p`
  cursor: pointer;
  margin: 0;
  margin-left: 20px;
  margin-right: 20px;
  color: ${color.primary};
`;

export const AvatarWrapper = styled.div`
  cursor: pointer;
  position: relative;
`;

export const MenuWrapper = styled.div`
  position: absolute;
  top: 50px;
  left: -100px;
  width: 150px;
  border-radius: 7px;
  background-color: ${color.background};
  padding: 10px;
`;

export const MenuItem = styled.div`
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const ModalDownloadAvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalDownloadAvatarLabel = styled.label`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 100px;
  background-color: rgba(255, 0, 0, 0.05);
  border: 1px solid ${color.primary};
  border-radius: 8px;
  font-size: 18px;
  color: ${color.text};
  margin-bottom: 20px;
`;
