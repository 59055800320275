import { createReducer, current } from '@reduxjs/toolkit';
import { toggleLoading } from './thunk';

interface IState {
  loading: boolean;
}

const state: IState = {
  loading: false,
};

export const reducer = createReducer(state, (builder) => {
  builder.addCase(toggleLoading, (state, { payload }) => {
    state.loading = payload.loading;
  });
});
