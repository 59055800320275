import React, { useEffect } from 'react';
import {
  ColumnContainer,
  ColumnTitleWrapper,
  CustomInput,
} from 'Components/CreateInputModal/styles/style';
import { color } from 'theme';
import { TableData } from 'Pages/form-builder/formShcema';
import { Text } from 'Components/Text';
import { SvgIcon } from 'Components/svg-icon';
import {
  AddDescriptionButton,
  PoapDescriptionButton,
} from 'Components/Inputs/style';
import {
  style,
  styleTable,
} from 'Components/CreateInputModal/styles/styleReact';
import { nanoid } from 'nanoid';

interface IPropsCreateTable {
  table: TableData;
  setTable: (val: TableData) => void;
  defaultValues: string[];
  descriptions: string[];
  setDescriptions: (val: string[]) => void;
  footerInputTexts: string[];
  setFooterInputTexts: (val: string[]) => void;
}
export const TableElement = (props: IPropsCreateTable) => {
  const correctDefaultValue = () => {
    let valueTitle = '';
    let valueColumn: string[] = [];
    if (props.defaultValues.length > 1) {
      valueTitle = props.defaultValues[0];
      valueColumn = [...props.defaultValues].filter((i, index) => index !== 1);
    }
    return {
      columns: [
        {
          title: valueTitle,
          dataIndex: nanoid(),
          key: nanoid(),
        },
      ],
      dataSource: valueColumn.map((el) => {
        return { key: nanoid(), valueCol: [el] };
      }),
    };
  };

  useEffect(() => {
    props.setTable(correctDefaultValue());
  }, [props.defaultValues]);

  const handleAddLines = () => {
    const valueCol = props.table.columns.map(() => '');
    props.setTable({
      ...props.table,
      dataSource: [
        ...props.table.dataSource,
        {
          key: `${props.table.dataSource.length + 1}`,
          valueCol: valueCol,
        },
      ],
    });
  };

  const handleAddColumn = () => {
    props.table.dataSource.forEach((x) => (x.valueCol[x.valueCol.length] = ''));
    props.setTable({
      ...props.table,
      columns: [
        ...props.table.columns,
        {
          title: '',
          dataIndex: `col${props.table.columns.length + 1}`,
          key: `col${props.table.columns.length + 1}`,
        },
      ],
    });
  };

  const handleChangeInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const value = e.target.value;
    const currentColumns = [...props.table.columns];
    currentColumns[index] = { ...currentColumns[index], title: value };
    props.setTable({ ...props.table, columns: currentColumns });
  };

  const handleChangeInputTable = (
    e: React.ChangeEvent<HTMLInputElement>,
    indexLine: number,
    indexTable: number,
  ) => {
    const value = e.target.value;
    const currentDataSource = JSON.parse(
      JSON.stringify(props.table.dataSource),
    );
    currentDataSource[indexLine].valueCol[indexTable] = value;
    props.setTable({ ...props.table, dataSource: [...currentDataSource] });
  };

  const handleDeleteColumn = (dataIndex: string, indexColumn: number) => {
    const currentColumn = props.table.columns
      .filter((x) => {
        return x.dataIndex !== dataIndex;
      })
      .map((x, index) => {
        return {
          title: x.title,
          dataIndex: `col${index + 1}`,
          key: `col${index + 1}`,
        };
      });

    const currentDataSource = [...props.table.dataSource].map((x) => {
      return {
        ...x,
        valueCol: x.valueCol.filter((y, index) => index !== indexColumn),
      };
    });

    props.setTable({
      dataSource: currentDataSource,
      columns: currentColumn,
    });
  };

  const handleDeleteLine = (indexLine: string) => {
    const currentDataSource = props.table.dataSource
      .filter((x) => x.key !== indexLine)
      .map((x, index) => {
        return {
          key: `${index}`,
          valueCol: x.valueCol,
        };
      });

    props.setTable({
      ...props.table,
      dataSource: currentDataSource,
    });
  };

  if (!props.table) return null;
  return (
    <div>
      {props.table.columns.length < 4 && (
        <div>
          <AddDescriptionButton onClick={handleAddColumn}>
            <PoapDescriptionButton>Add column</PoapDescriptionButton>

            <SvgIcon width={14} height={14} icon="plus" color={color.primary} />
          </AddDescriptionButton>
          <Text style={style.buttonText}>add more column</Text>
        </div>
      )}
      <Text style={styleTable.nameSection} color={color.text}>
        Tabel title
      </Text>
      <ColumnContainer>
        {props.table.columns.map((x, index) => (
          <ColumnTitleWrapper key={x.key}>
            {props.table.columns.length > 2 && (
              <AddDescriptionButton
                onClick={() => handleDeleteColumn(x.dataIndex, index)}
              >
                <PoapDescriptionButton>Delete column</PoapDescriptionButton>

                <SvgIcon
                  width={14}
                  height={14}
                  icon="delete"
                  color={color.primary}
                />
              </AddDescriptionButton>
            )}
            <CustomInput
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChangeInput(e, index);
              }}
              value={x.title}
            />
          </ColumnTitleWrapper>
        ))}
      </ColumnContainer>

      <div>
        <Text style={styleTable.nameSection} color={color.text}>
          Tabel body
        </Text>
        {props.table.dataSource.map((x, indexLine) => (
          <>
            {props.table.dataSource.length > 2 && (
              <AddDescriptionButton onClick={() => handleDeleteLine(x.key)}>
                <PoapDescriptionButton>Delete line</PoapDescriptionButton>
                <SvgIcon
                  width={14}
                  height={14}
                  icon="delete"
                  color={color.primary}
                />
              </AddDescriptionButton>
            )}
            <ColumnContainer key={x.key}>
              {x.valueCol.map((y, indexTable) => (
                <CustomInput
                  key={indexTable}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChangeInputTable(e, indexLine, indexTable);
                  }}
                  value={y}
                />
              ))}
            </ColumnContainer>
          </>
        ))}
        <div>
          <AddDescriptionButton onClick={handleAddLines}>
            <PoapDescriptionButton>Add line</PoapDescriptionButton>
            <SvgIcon width={14} height={14} icon="plus" color={color.primary} />
          </AddDescriptionButton>
          <Text style={style.buttonText}>add more lines</Text>
        </div>
      </div>
    </div>
  );
};
