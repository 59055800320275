import React, { useEffect } from 'react';
import { color } from 'theme';
import { Text } from 'Components/Text';
import {
  AddDescriptionButton,
  PoapDescriptionButton,
} from 'Components/Inputs/style';
import { SvgIcon } from 'Components/svg-icon';
import { CustomInput, InputWrapp } from './style';
import { style } from './index';

type InputProps = {
  setValue: (v: string[]) => void;
  values: string[];
  edit?: boolean;
};

export const CreateCheckbox = ({ setValue, values }: InputProps) => {
  const handleAddDescription = () => {
    setValue([...values, '']);
  };

  useEffect(() => {
    if (values.length == 0) {
      handleAddDescription();
    }
  }, []);

  const handleDeleteDescription = (index: number) => {
    setValue(values.filter((el, i) => index !== i));
  };

  const handleChangeInputCustom = (valuesInput: string, index: number) => {
    const currentValues = [...values];
    currentValues[index] = valuesInput;
    setValue([...currentValues]);
  };

  return (
    <>
      {values.map((el, index) => (
        <InputWrapp key={index}>
          <label htmlFor={`description${index}`} style={{ width: '100%' }}>
            <Text style={style.description} color={color.text}>
              answer
            </Text>
            <CustomInput
              id={`description${index}`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInputCustom(e.target.value, index)
              }
              value={el}
              type="text"
              placeholder="User's answer"
            />
          </label>

          {values.length > 1 && (
            <div>
              <AddDescriptionButton
                onClick={() => handleDeleteDescription(index)}
              >
                <SvgIcon
                  width={14}
                  height={14}
                  icon="delete"
                  color={color.primary}
                />
              </AddDescriptionButton>
            </div>
          )}
        </InputWrapp>
      ))}

      <div>
        <AddDescriptionButton onClick={handleAddDescription}>
          <PoapDescriptionButton>Add line</PoapDescriptionButton>
          <SvgIcon width={14} height={14} icon="plus" color={color.primary} />
        </AddDescriptionButton>
        <Text style={style.buttonText}>add more checkbox</Text>
      </div>
    </>
  );
};
