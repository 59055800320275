import React from 'react';
import { IParticipants } from 'Redux/projects/type';
import { URL } from 'Utils/Api';
import { AvatarParticipants, Subscriber } from './styles';

interface IParticipantsSubscriber {
  small: boolean;
  participant: IParticipants;
  participantsLength: number;
  index: number;
}

export const ParticipantsSubscriber = ({
  small,
  participant,
  participantsLength,
  index,
}: IParticipantsSubscriber) => {
  return (
    <Subscriber
      small={small}
      left={small ? -10 * index - 1 : -12 * index - 1}
      image={''}
      static={participantsLength === 1 || index === 0}
    >
      {participant.user && participant.user.avatar ? (
        <AvatarParticipants
          small={small}
          alt="avatar"
          src={`${URL}/${participant.user.avatar}`}
        />
      ) : (
        <>
          {participant.user && participant.user.name && participant.user.surname
            ? `${participant.user.name[0]}${participant.user.surname[0]}`
            : ''}
        </>
      )}
    </Subscriber>
  );
};
