import React, { CSSProperties } from 'react';
import { flatten, mergeAll } from 'ramda';
import { colorPreset, presets } from './text.presets';
import { TextProps } from './text.props';
import { useTranslation } from 'react-i18next';

/**
 * For your text displaying needs.
 *
 * This component is a HOC over the web div one.
 */
export const Text = (
  props: TextProps,
): React.FunctionComponentElement<Text> => {
  // grab the props
  const {
    preset = 'default',
    tx,
    txOptions,
    text,
    children,
    color,
    style,
    ...rest
  } = props;

  const { t } = useTranslation('common');

  // figure out which text content to use
  const i18nText = tx && t(tx, txOptions);
  const content = i18nText || text || children;

  const colorStyle = { color };

  const styleOverride: CSSProperties = mergeAll(
    flatten([
      presets[preset] || presets.default,
      colorPreset[preset] || colorPreset.default,
      colorStyle,
    ]),
  );

  return (
    <div {...rest} style={{ ...style, ...styleOverride }}>
      {content}
    </div>
  );
};
