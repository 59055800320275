import jwtDecode, { JwtPayload } from 'jwt-decode';

export const refreshToken = (): {
  refreshToken?: string;
  needRefresh: boolean;
} => {
  const accessToken = JSON.parse(localStorage.getItem('accessToken')) as string;
  const refreshToken = JSON.parse(
    localStorage.getItem('refreshToken'),
  ) as string;

  const data = {
    refreshToken: refreshToken,
    needRefresh: true,
  };

  if (accessToken) {
    const jwtAssignToken = jwtDecode<JwtPayload>(accessToken);
    const jwtRefreshToken = jwtDecode<JwtPayload>(refreshToken);

    if (jwtAssignToken.exp < new Date().getTime() / 1000) {
      return data;
    } else if (jwtRefreshToken.exp < new Date().getTime() / 1000) {
      return { needRefresh: true, refreshToken: '' };
    }
  }
  return { needRefresh: false, refreshToken: '' };
};
