import styled from 'styled-components';
import { color, spacing } from 'theme';
import { InputField, Form } from 'Components/FormAuth/style';

export const MessageContainer = styled.div`
  height: calc(100vh - 230px);
  padding: 10px;
  overflow: scroll;
  flex: 1;
`;

export const MessageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  flex: 0 0 calc(100vh - 230px);
`;

export const ChatForm = styled(Form)`
  width: 100%;
`;

export const ChatInputField = styled(InputField)`
  background-color: ${color.chatBackground};
  box-shadow: inset 0 0 0 50px ${color.chatBackground};

  &::placeholder {
    font-size: 12px;
  }
`;

export const Bubble = styled.div`
  margin-bottom: 20px;
  padding: ${spacing.tinyPlus};
  background-color: ${color.backgroundSecondary};
  border-radius: 10px;
  ${(p) =>
    p.me ? 'border-top-right-radius: 0;' : 'border-top-left-radius: 0;'}
`;

export const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props) => (props.me ? 'flex-end' : 'flex-start')};
`;

export const ContainerChat = styled.div`
  border-radius: ${spacing.tiny};
  width: 260px;
  position: sticky;
  bottom: 0;
  background-color: ${color.background};
  padding: 20px;
`;

export const WrapperChat = styled.div``;

export const TitleChat = styled.h3`
  padding: 0;
  margin: 0;
  padding: ${spacing.tiny};
  font-size: 14px;
  line-height: 16px;
  text-align: center;
`;
