import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { IFormLogin } from 'Utils/Api/type';
import { InputPassword } from 'Components/InputPassword/InputPassword';
import {
  loginUser,
  forgotPassword as forgotPasswordThunk,
} from 'Redux/user/thunk';
import { Text } from 'Components/Text';
import { color } from 'theme';
import { CheckboxArrow } from 'Components/CheckboxArrow/CheckboxArrow';
import {
  ButtonForgotPassword,
  ButtonSubmit,
  CheckboxHidden,
  Form,
  InformationForm,
  InputField,
  InputFieldPassword,
  KeepLogin,
} from './style';

const schemaLogin = yup.object().shape({
  email: yup.string().email().required('this field is required'),
  password: yup.string().min(6, 'min 6').required('this field is required'),
});

const schemaResetPass = yup.object().shape({
  email: yup.string().email().required('this field is required'),
});

const defaultValueLogin = {
  email: '',
  password: '',
};

const defaultValueResetPass = {
  email: '',
  password: '',
};

const dataForm = {
  checkboxText: 'Keep me logged in',
  forgotPassword: 'Forgot your password?',
  button: 'Login',
};

interface ISingInFormProps {
  forgotPassword: boolean;
  setForgotPassword: (b: boolean) => void;
}

export const SingInForm = ({
  forgotPassword,
  setForgotPassword,
}: ISingInFormProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  const [sentLetter, setSentLetter] = useState(false);
  const [errorSingIn, setErrorSingIn] = useState<string>('');
  const [errorResetPass, setErrorResetPass] = useState<string>('');
  const [showPass, setShowPass] = useState<boolean>(false);

  const {
    register: registerLogin,
    handleSubmit: handleSubmitLogin,
    errors: errorsLogin,
    control: controlLogin,
  } = useForm<IFormLogin>({
    resolver: yupResolver(schemaLogin),
    defaultValues: defaultValueLogin,
  });

  const {
    register: registerResetPass,
    handleSubmit: handleSubmitResetPass,
    errors: errorsResetPass,
    control: controlResetPass,
  } = useForm<IFormLogin>({
    resolver: yupResolver(schemaResetPass),
    defaultValues: defaultValueResetPass,
  });

  const submitForm = (data: IFormLogin) => {
    dispatch(
      loginUser({
        history: (s) => history.push(s),
        value: { ...data, rememberMe: checked },
        setErrorSingIn: (s) => setErrorSingIn(s),
      }),
    );
  };

  const handleClickOnCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  };

  const handleForgotPassword = (data: { email: string }) => {
    dispatch(
      forgotPasswordThunk({
        data: data,
        setSentLetter: () => setSentLetter(true),
      }),
    );
  };

  const toggleSignIn = (b: boolean) => {
    setForgotPassword(b);
    setErrorSingIn('');
    setErrorResetPass('');
  };

  return (
    <>
      {forgotPassword ? (
        <Form onSubmit={handleSubmitResetPass(handleForgotPassword)}>
          <Controller
            control={controlResetPass}
            name="email"
            render={() => (
              <InputField
                error={errorsResetPass.email}
                placeholder="Email"
                type="email"
                name="email"
                ref={registerResetPass}
              />
            )}
          />
          {!!errorResetPass && (
            <Text style={{ marginBottom: '10px' }} color={color.primary}>
              {errorResetPass}
            </Text>
          )}

          {sentLetter && (
            <Text style={{ marginBottom: '10px' }} color={color.text}>
              Password has been sent
            </Text>
          )}
          <ButtonSubmit type="submit">
            <Text>Send email</Text>
          </ButtonSubmit>
        </Form>
      ) : (
        <Form onSubmit={handleSubmitLogin(submitForm)}>
          <Controller
            control={controlLogin}
            name="email"
            render={() => (
              <InputField
                error={errorsLogin.email}
                placeholder="Email"
                type="email"
                name="email"
                ref={registerLogin}
              />
            )}
          />
          <Controller
            control={controlLogin}
            name="password"
            render={() => (
              <InputPassword
                setShowPass={(b) => setShowPass(b)}
                showPass={showPass}
                error={errorsLogin.password}
                input={
                  <InputFieldPassword
                    error={errorsLogin.password}
                    placeholder="Password"
                    type={showPass ? 'input' : 'password'}
                    name="password"
                    ref={registerLogin}
                  />
                }
              />
            )}
          />
          <InformationForm>
            <KeepLogin>
              <CheckboxHidden
                defaultChecked={checked}
                onClick={handleClickOnCheckbox}
                type="checkbox"
                hidden
              />
              <CheckboxArrow
                colorBorder={color.primary}
                size="20px"
                colorBg={checked ? color.primary : color.background}
                checked={checked}
              />
              <Text>{dataForm.checkboxText}</Text>
            </KeepLogin>

            <ButtonForgotPassword onClick={() => toggleSignIn(true)}>
              <Text style={{ fontSize: '14px' }} color={color.primary}>
                {dataForm.forgotPassword}
              </Text>
            </ButtonForgotPassword>
          </InformationForm>
          {!!errorsLogin && (
            <Text style={{ marginBottom: '10px' }} color={color.primary}>
              {errorSingIn}
            </Text>
          )}
          <ButtonSubmit type="submit">
            <Text>{dataForm.button}</Text>
          </ButtonSubmit>
        </Form>
      )}
    </>
  );
};
