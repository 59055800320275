import React from 'react';
import { Text } from 'Components/Text';
import { SvgIcon } from 'Components/svg-icon';
import { ButtonSubmit } from 'Components/FormAuth/style';
import {
  IntroductionContentContainer,
  ParagrafStartProject,
  TextStartProject,
  TitleStartProject,
} from './style';

interface Introduction {
  setStartPressed: (value: boolean) => void;
}

export const IntroductionContent = ({ setStartPressed }: Introduction) => {
  return (
    <IntroductionContentContainer>
      <ParagrafStartProject>
        <TitleStartProject>A Quick Introduction</TitleStartProject>
      </ParagrafStartProject>

      <ParagrafStartProject>
        <TitleStartProject>
          Who is the intended user of the MSF Telehealth Toolkit?
        </TitleStartProject>
        <TextStartProject>
          The ultimate end-users of this toolkit are the field teams who have
          identified a potential need that is aligned with the launch of a
          Telehealth intervention. OCs are encouraged to communicate this
          document and toolkit to field teams, and support them in usage of this
          guidance.
        </TextStartProject>
      </ParagrafStartProject>
      <ParagrafStartProject>
        <TitleStartProject>When should this Toolkit be used?</TitleStartProject>
        <TextStartProject>
          This toolkit may be used at any point during the process of exploring,
          implementing and/or evaluating a telehealth service within MSF. The
          Toolkit is structured in three parts, illustrated below, which aim to
          provide high level guidance on key considerations that should be taken
          into account in an effort to ease the development and evaluation of
          such interventions.
        </TextStartProject>
      </ParagrafStartProject>

      <ParagrafStartProject>
        <TitleStartProject>
          How should the Toolkit be leveraged by MSF teams?
        </TitleStartProject>
        <TextStartProject>
          This platform is intended to serve as an evergreen resource for
          Telehealth in MSF, periodically updated with new insights as programs
          and MSF expert understanding further matures. If you feel there is any
          important information that should be included in this resource please
          email the MSF Sweden Innovation Unit at siu@stockholm.msf.org.
        </TextStartProject>
      </ParagrafStartProject>

      <ParagrafStartProject>
        <TextStartProject>
          Once you have completed relevant sections with your team you can
          export and share the full summary documents with others who may
          require or benefit from this information. You will also have the
          option to share your finished Telehealth plan with other MSF users on
          the system to help advance shared knowledge and understanding of this
          space. You are now ready to get started, select the button below to
          enter the first section for your Pre-Launch assessment.
        </TextStartProject>
      </ParagrafStartProject>

      <SvgIcon icon="projectTitle" />

      <ButtonSubmit
        type="primary"
        danger
        size="large"
        style={{
          marginTop: '20px',
          width: 340,
          alignSelf: 'center',
          borderRadius: 10,
          padding: '25px 30px',
        }}
        onClick={() => {
          setStartPressed(true);
        }}
      >
        <Text text="Get Started" />
      </ButtonSubmit>
    </IntroductionContentContainer>
  );
};
