import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ProjectsContainer = styled.div`
  width: 100%;
`;
export const ProjectsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 260px;
  width: 100%;
`;
export const ProjectsBlocks = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
  justify-content: space-between;
  margin: 20px 0;
`;
export const ProjectsTitle = styled.h3`
  font-family: Raleway, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: flex-end;
  color: #000000;
  margin-bottom: 0;
`;
export const ViewAllProjects = styled(Link)`
  font-family: Raleway, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  text-align: right;
  color: #000000;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: grey;
  }
`;
