import React from 'react';
import { Modal } from 'antd';
import { ModalProps } from 'Utils/interfaces';

export const ModalComponent = ({
  toggle,
  isModalVisible,
  title,
  childrenContent,
  width,
}: ModalProps) => {
  return (
    <>
      <Modal
        width={width}
        title={title}
        visible={isModalVisible}
        onOk={() => toggle(false)}
        onCancel={() => toggle(false)}
        className="modal-participants"
      >
        {childrenContent}
      </Modal>
    </>
  );
};

export type { ModalProps };
