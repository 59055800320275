import React, { useState } from 'react';
import { color } from 'theme';
import { Modal } from 'Components/Modal/Modal';
import { Text } from 'Components/Text';
import { CreateProjectButton, Plus } from './style.js';
import { ModalNewProject } from './ModalNewPeoject';

export const NewProjectButton = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <Modal
        toggle={() => setIsModalVisible(false)}
        isModalVisible={isModalVisible}
        title="New Project"
        childrenContent={
          <ModalNewProject toggle={() => setIsModalVisible(false)} />
        }
      />
      <CreateProjectButton onClick={() => setIsModalVisible(true)}>
        <Plus color={color.textDarkGrey}>+</Plus>
        <Text color={color.textDarkGrey}>Create new project</Text>
      </CreateProjectButton>
    </>
  );
};
