import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Text } from 'Components/Text';
import { AuthContainer } from 'Components/AuthContainer';
import { SingInForm } from 'Components/FormAuth/singInForm';
import { LinkToRegister } from './style';

interface Data {
  linkToSingUp: {
    test: string;
    link: string;
  };
}

const data: Data = {
  linkToSingUp: {
    test: 'Dont have an account',
    link: 'Sign Up',
  },
};

export const SingIn = () => {
  const [forgotPassword, setForgotPassword] = useState(false);
  return (
    <AuthContainer
      toLogin={() => setForgotPassword(false)}
      toLoginButton={forgotPassword}
    >
      <SingInForm
        forgotPassword={forgotPassword}
        setForgotPassword={(b) => setForgotPassword(b)}
      />
      <Text
        style={{
          fontSize: '14px',
          marginTop: '10px',
          textAlign: 'center',
        }}
        color={'#666666'}
      >
        {data.linkToSingUp.test}
        <Link to="/register">
          <LinkToRegister> {data.linkToSingUp.link}</LinkToRegister>
        </Link>
      </Text>
    </AuthContainer>
  );
};
