import { color } from 'theme';
import { CSSProperties } from 'react';

export const style = {
  select: {
    width: '100%',
    marginBottom: '20px',
    borderRadius: '5px',
    color: color.secondary,
    display: 'flex',
    alignContent: 'center',
  } as CSSProperties,
  options: { display: 'inline-block' } as CSSProperties,
  description: { fontWeight: 'bold', marginBottom: '7px' } as CSSProperties,
  buttonText: {
    display: 'inline-block',
    marginLeft: '4px',
  } as CSSProperties,
};

export const styleTable = {
  nameSection: {
    fontWeight: 'bold',
    marginTop: '7px',
    marginBottom: '7px',
  } as CSSProperties,
};
