import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ButtonSubmit } from 'Components/FormAuth/style';
import { Text } from 'Components/Text';
import { updateUserAvatar } from 'Redux/user/thunk';
import { color } from 'theme';
import {
  ModalDownloadAvatarContainer,
  ModalDownloadAvatarLabel,
} from './styles';

interface IModalDownloadAvatarProps {
  close: () => void;
}

export const ModalDownloadAvatar = ({ close }: IModalDownloadAvatarProps) => {
  const fileRef = useRef<HTMLInputElement>();
  const [isLoadingFile, setIsLoadingFile] = useState('');
  const dispatch = useDispatch();

  const handleClickToSendAvatar = () => {
    dispatch(
      updateUserAvatar({
        file: fileRef.current.files[0],
        setIsLoadingFile: (s) => setIsLoadingFile(s),
        close: () => close(),
      }),
    );
  };

  return (
    <ModalDownloadAvatarContainer>
      <ModalDownloadAvatarLabel htmlFor="myfile">
        Choose file
      </ModalDownloadAvatarLabel>
      <input
        ref={fileRef}
        hidden={true}
        type="file"
        accept="image/jpeg,image/png,image/gif"
        id="myfile"
        name="myfile"
        multiple
      />
      <Text color={color.primary} preset="bodyTiny">
        {isLoadingFile}
      </Text>
      <ButtonSubmit onClick={handleClickToSendAvatar}>
        Change avatar
      </ButtonSubmit>
    </ModalDownloadAvatarContainer>
  );
};
