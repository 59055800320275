import React, { CSSProperties } from 'react';
import { useDispatch } from 'react-redux';
import { Text } from 'Components/Text';
import { SvgIcon } from 'Components/svg-icon';
import { spacing } from 'theme';
import { FormBuilder } from 'Pages/form-builder';
import { useHistory } from 'react-router';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { saveForm } from 'Redux/form/thunk';
import {
  BackNext,
  ButtonProject,
  DocumentPaginatorWrapper,
  LeftGroup,
  Paginator,
} from './style';

interface IPropsDocumentPaginator {
  setReady: (ready: number) => void;
  setEdit: (ready: number) => void;
}

export const DocumentPaginator = ({
  setReady,
  setEdit,
}: IPropsDocumentPaginator) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { forms, currentForm } = useTypedSelector((state) => state.formBuilder);
  const { currentProjects } = useTypedSelector((state) => state.projects);
  const { id: userId } = useTypedSelector((state) => state.user);

  const validationPreviousFormNumber = () => {
    const findIndex = forms?.findIndex((x) => x.id === currentForm.id);
    if (!forms) return false;
    return findIndex != 0;
  };

  const validationNextFormNumber = () => {
    const findIndex = forms?.findIndex((x) => x.id === currentForm.id);
    if (!forms) return false;
    return findIndex != forms.length - 1;
  };

  const handleClickToPreviousStep = () => {
    if (
      !validationPreviousFormNumber() &&
      (currentForm.isEditing.edit ||
        (!currentForm.isEditing.edit &&
          currentForm.isEditing.user.id === userId))
    )
      return;
    const previousForm = forms.filter(
      (f) => f.position === currentForm.position - 1,
    )[0];

    dispatch(
      saveForm({
        projectId: currentProjects.id,
        pieceId: previousForm.id,
        history: (s) => history.push(s),
      }),
    );
  };

  const handleClickToNextStep = () => {
    if (
      !validationNextFormNumber() &&
      (currentForm.isEditing.edit ||
        (!currentForm.isEditing.edit &&
          currentForm.isEditing.user.id === userId))
    )
      return;
    const nextForm = forms.filter(
      (f) => f.position === currentForm.position + 1,
    )[0];

    dispatch(
      saveForm({
        projectId: currentProjects.id,
        pieceId: nextForm?.id,
        history: (s) => history.push(s),
      }),
    );
  };

  return (
    <DocumentPaginatorWrapper>
      <FormBuilder />
      <Paginator>
        <LeftGroup>
          <BackNext
            disabled={
              currentForm.isEditing.edit &&
              currentForm.isEditing.user.id === userId
            }
            onClick={handleClickToPreviousStep}
          >
            <SvgIcon icon="back" />
            <Text text="Previous Step" style={style.text} />
          </BackNext>
          <ButtonProject
            onClick={() => setEdit(currentForm.id)}
            type="primary"
            danger
            disabled={
              currentForm.isEditing.edit ||
              (currentForm.isEditing.edit &&
                currentForm.isEditing.user.id === userId) ||
              currentForm.isDone
            }
            style={style.button}
            edit={!currentForm.isEditing.edit}
          >
            Edit
          </ButtonProject>
          <ButtonProject
            edit={currentForm.isEditing.edit}
            onClick={
              currentForm.isEditing.edit
                ? () => setEdit(currentForm.id)
                : () => setReady(currentForm.id)
            }
            danger
            disabled={
              (currentProjects && currentProjects.status === 'complete') ||
              (currentForm.isEditing.edit &&
                currentForm.isEditing.user.id !== userId) ||
              currentForm.isDone
            }
            style={style.button}
          >
            {currentForm.isEditing.edit ? 'Save' : 'Ready'}
          </ButtonProject>
          {currentForm.isEditing.edit && (
            <Text
              preset="bodyRegularPale"
              text={`Editing by ${currentForm.isEditing.user.name} ${currentForm.isEditing.user.surname}`}
            />
          )}
        </LeftGroup>
        <BackNext
          disabled={
            currentForm.isEditing.edit &&
            currentForm.isEditing.user.id === userId
          }
          onClick={handleClickToNextStep}
        >
          <Text text="Next Step" style={style.text} />
          <SvgIcon icon="back" style={style.back} />
        </BackNext>
      </Paginator>
    </DocumentPaginatorWrapper>
  );
};

const style = {
  back: {
    transform: 'rotate(180deg)',
  } as CSSProperties,
  button: {
    borderRadius: spacing.tiny,
    marginRight: spacing.tinyPlus,
  } as CSSProperties,
  text: {
    marginLeft: spacing.tinyPlus,
    marginRight: spacing.tinyPlus,
  } as CSSProperties,
};
