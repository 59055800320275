import styled from 'styled-components';

export const InputFieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${(props) => props.heightElement || 'content-box'};
  border-radius: 10px;
  margin-bottom: 20px;
  border: 1px solid ${(p) => (!p.error ? '#E9EFF4;' : 'red')};
`;

export const WrapperSvg = styled.div`
  margin-right: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
