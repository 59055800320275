import React from 'react';
import { Text } from 'Components/Text';
export const Settings = () => {
  return (
    <>
      <Text preset="header1">Settings</Text>
      <Text preset="header2" color="red" text="Settings" />
    </>
  );
};
