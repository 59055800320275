import React from 'react'; // we need this to make JSX compile
import { TeamMemberProps } from 'Utils/interfaces';
import {
  MemberWrapper,
  MemberWrapperLeft,
  Avatar,
  Name,
  Position,
} from './styles.js';

export const TeamMember = ({ link, name, position }: TeamMemberProps) => (
  <MemberWrapper>
    <MemberWrapperLeft>
      <Avatar link={link} />
      <Name>{name}</Name>
    </MemberWrapperLeft>
    <Position>{position}</Position>
  </MemberWrapper>
);

export type { TeamMemberProps };
