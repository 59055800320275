import React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => {
  const { color, width, height } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.6985 0.5H7.8015C3.56983 0.5 0.75 3.52488 0.75 7.86646V18.1335C0.75 22.4792 3.5624 25.5 7.8015 25.5H18.6985C22.9376 25.5 25.75 22.4792 25.75 18.1335V7.86646C25.75 3.52081 22.9376 0.5 18.6985 0.5ZM7.80147 2.24419H18.6985C21.9453 2.24419 24.0058 4.45734 24.0058 7.86646V18.1335C24.0058 21.5427 21.9453 23.7558 18.6985 23.7558H7.80147C4.55462 23.7558 2.49416 21.5427 2.49416 18.1335V7.86646C2.49416 4.46187 4.56151 2.24419 7.80147 2.24419ZM13.25 7.85734C13.6915 7.85734 14.0564 8.18542 14.1141 8.61109L14.1221 8.72943V12.1168H17.5136C17.9952 12.1168 18.3857 12.5073 18.3857 12.9889C18.3857 13.4304 18.0576 13.7953 17.6319 13.8531L17.5136 13.861H14.1221V17.2484C14.1221 17.7301 13.7316 18.1205 13.25 18.1205C12.8085 18.1205 12.4436 17.7925 12.3858 17.3668L12.3779 17.2484V13.861H8.98644C8.5048 13.861 8.11435 13.4706 8.11435 12.9889C8.11435 12.5474 8.44244 12.1826 8.8681 12.1248L8.98644 12.1168H12.3779V8.72943C12.3779 8.24778 12.7683 7.85734 13.25 7.85734Z"
        fill={color || '#666666'}
      />
    </svg>
  );
};

export const Plus = React.memo(SvgComponent);
