import React from 'react';
import { Page } from 'Components/Page';
import { Empty } from 'antd';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { RenderStep } from './render-step';

export const FormBuilder = () => {
  const { error, forms } = useTypedSelector((state) => state.formBuilder);

  const loading = useTypedSelector((state) => state.loading);

  return (
    <Page style={{ width: '100%' }}>
      {error && <div>{error}</div>}
      {forms === null && !error && loading && <Empty />}
      <RenderStep />
    </Page>
  );
};
