import styled from 'styled-components';
import { spacing, color } from 'theme';

export const WrapperInput = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${spacing.tiny};
`;

export const PoapDescriptionButton = styled.p`
  display: none;
  padding: 5px;
  justify-content: center;
  border-radius: 10px;
  width: 150px;
  border: 1px solid ${color.primary};
  background-color: ${color.background};
  font-size: 12px;
  position: absolute;
  top: -34px;
  left: -20px;
  z-index: 100000;
  &:after {
    width: 5px;
    height: 5px;
    content: '';
    position: absolute;
    left: calc(100px / 2 - 28px);
    bottom: -3px;
    background-color: ${color.background};
    border-bottom: 1px solid ${color.primary};
    border-right: 1px solid ${color.primary};
    transform: rotate(45deg);
  }
`;

export const AddDescriptionButton = styled.div`
  cursor: pointer;
  margin-top: 10px;
  position: relative;
  display: inline-block;
  &:hover ${PoapDescriptionButton} {
    display: flex;
  }
`;
export const ButtonIconWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 20px);
  grid-gap: ${spacing.tiny};
`;
