import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { saveAllProjects } from 'Redux/projects/thunk';
import { refreshToken } from 'Utils/refreshToken';
import {
  loginFetch,
  registerFetch,
  forgotPassword as forgotPasswordFetch,
  getUser as getUserGet,
  verifyAccountPost,
  refreshTokenPost,
  resetPassword as resetPasswordFetch,
  postUserAvatar,
} from 'Utils/Api';
import {
  IFormRegister,
  IIForgotPasswordFetch,
  IResetPassword,
} from 'Utils/Api/type';
import { toggleLoading } from 'Redux/loading/thunk';

import {
  ILoginUserPayload,
  ILoginUserProps,
  IRegisterUserProps,
  MyError,
  UserPayload,
} from './type';

export const registerUser = createAsyncThunk<
  void,
  IRegisterUserProps<IFormRegister>,
  {
    rejectValue: MyError;
  }
>('user/registerUser', async ({ value, history, setErrorLogin }, thunkAPI) => {
  thunkAPI.dispatch(toggleLoading({ loading: false }));

  const response = await registerFetch(value);

  if (response.status > 300) {
    thunkAPI.dispatch(toggleLoading({ loading: false }));
    const data = await response.json();
    if (Array.isArray(data.message)) {
      setErrorLogin(data.message[0]);
    } else {
      setErrorLogin(data.message);
    }
    return thunkAPI.rejectWithValue((await response.json()) as MyError);
  }

  history();

  thunkAPI.dispatch(toggleLoading({ loading: false }));
});

export const loginUser = createAsyncThunk<
  ILoginUserPayload,
  ILoginUserProps,
  {
    extra: {
      jwt: string;
    };
    rejectValue: MyError;
  }
>(
  'user/loginUser',
  async ({ value, setErrorSingIn, history }: ILoginUserProps, thunkAPI) => {
    thunkAPI.dispatch(toggleLoading({ loading: true }));

    const response = await loginFetch(value);
    const data = await response.json();

    if (response.status > 300) {
      thunkAPI.dispatch(toggleLoading({ loading: false }));
      if (Array.isArray(data.message)) {
        setErrorSingIn(data.message[0]);
      } else {
        setErrorSingIn(data.message);
      }

      return thunkAPI.rejectWithValue(data as MyError);
    }

    localStorage.setItem(
      'accessToken',
      JSON.stringify(data.tokens.accessToken),
    );
    localStorage.setItem(
      'refreshToken',
      JSON.stringify(data.tokens.refreshToken),
    );

    thunkAPI.dispatch(saveAllProjects({ history }));
    thunkAPI.dispatch(toggleLoading({ loading: false }));

    return data as ILoginUserPayload;
  },
);

export const forgotPassword = createAsyncThunk<
  void,
  IIForgotPasswordFetch,
  {
    extra: {
      jwt: string;
    };
    rejectValue: MyError;
  }
>(
  'user/forgotPassword',
  async ({ data: value, setSentLetter }: IIForgotPasswordFetch, thunkAPI) => {
    thunkAPI.dispatch(toggleLoading({ loading: true }));

    const response = await forgotPasswordFetch(value.email);

    if (response.status > 300) {
      thunkAPI.dispatch(toggleLoading({ loading: false }));
      return thunkAPI.rejectWithValue((await response.json()) as MyError);
    }

    setSentLetter();

    thunkAPI.dispatch(toggleLoading({ loading: false }));
  },
);

export const resetPassword = createAsyncThunk<
  void,
  IRegisterUserProps<IResetPassword>,
  {
    rejectValue: MyError;
  }
>('user/resetPassword', async ({ value, history, setErrorLogin }, thunkAPI) => {
  thunkAPI.dispatch(toggleLoading({ loading: true }));

  const response = await resetPasswordFetch(value);

  if (response.status > 300) {
    const dataError = await response.json();
    if (Array.isArray(dataError.message)) {
      setErrorLogin(dataError.message[0]);
    } else {
      setErrorLogin(dataError.message);
    }
    thunkAPI.dispatch(toggleLoading({ loading: false }));
    return thunkAPI.rejectWithValue(dataError as MyError);
  }

  history();

  thunkAPI.dispatch(toggleLoading({ loading: false }));
});

export const refreshTokenAction = createAsyncThunk<
  void,
  { history: (s: string) => void },
  {
    rejectValue: MyError;
  }
>('user/refreshTokenAction', async ({ history }, thunkApi) => {
  const response = await refreshTokenPost(refreshToken().refreshToken);

  if (response.status > 300) {
    history('/login');
    thunkApi.dispatch(logOut());
    localStorage.clear();
  } else {
    const data = await response.json();
    localStorage.setItem('accessToken', `"${data.accessToken}"`);
    localStorage.setItem('refreshToken', `"${data.refreshToken}"`);
  }
});

export const saveUser = createAsyncThunk<
  UserPayload,
  { history: (s: string) => void },
  {
    rejectValue: MyError;
  }
>('user/saveUser', async ({ history }, thunkAPI) => {
  thunkAPI.dispatch(toggleLoading({ loading: true }));
  if (refreshToken().needRefresh) {
    thunkAPI.dispatch(refreshTokenAction({ history: (s) => history(s) }));
  }

  const response = await getUserGet();

  const data = await response.json();

  if (response.status > 300) {
    history('/error');
    thunkAPI.dispatch(toggleLoading({ loading: false }));
    return thunkAPI.rejectWithValue(data as MyError);
  }

  thunkAPI.dispatch(toggleLoading({ loading: false }));

  return data as UserPayload;
});

export const logOut = createAction('user/logOut');

export const verifyAccount = createAsyncThunk<
  void,
  { email: string; token: string; history: () => void },
  {
    rejectValue: MyError;
  }
>('user/verifyAccount', async ({ email, token, history }, thunkAPI) => {
  const response = await verifyAccountPost(email, token);

  if (response.status > 300) {
    return thunkAPI.rejectWithValue((await response.json()) as MyError);
  }

  history();
});

export const updateUserAvatar = createAsyncThunk<
  { img: string },
  { file: File; setIsLoadingFile: (s: string) => void; close: () => void },
  {
    rejectValue: MyError;
  }
>(
  'user/updateUserAvatar',
  async ({ file, setIsLoadingFile, close }, thunkAPI) => {
    thunkAPI.dispatch(toggleLoading({ loading: true }));

    const formData = new FormData();

    formData.append('file', file);

    const response = await postUserAvatar(formData);

    if (response.status > 300) {
      setIsLoadingFile(' File did not load ');
      thunkAPI.dispatch(toggleLoading({ loading: false }));
      return thunkAPI.rejectWithValue((await response.json()) as MyError);
    }
    setIsLoadingFile('File loaded');
    const pdfAvatar = await response.text();

    thunkAPI.dispatch(toggleLoading({ loading: false }));
    close();
    return { img: pdfAvatar };
  },
);
