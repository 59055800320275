import styled from 'styled-components';
import { color } from 'theme';
import { ButtonSubmit } from 'Components/FormAuth/style';

export const CustomInput = styled.input`
  display: block;
  border: 1px solid ${color.backgroundApp};
  color: ${color.textPale};
  margin-bottom: 10px;
  padding: 5px 7px;
  outline: none;
  width: 100%;
  border-radius: 10px;
`;

export const InputWrapp = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonCreate = styled.button`
  margin-top: 20px;
  cursor: pointer;
  color: ${color.background};
  font-size: 18px;
  border: none;
  background: ${color.primary};
  pudding: 20px 0;
  border-radius: 10px;
  width: 250px;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

export const ColumnTitleWrapper = styled.div`
  width: 100%;
`;

export const ButtonCreateInput = styled(ButtonSubmit)`
  margin-top: 20px;
  padding: 2px 5px;
  font-size: 14px;
`;
