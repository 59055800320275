import { color, typography } from 'theme';
import { CSSProperties } from 'react';

const BASE: CSSProperties = {
  fontSize: '1em',
  fontWeight: 500,
  lineHeight: '1.5em',
  color: color.text,
};

export const colorPreset = {
  default: {
    color: color.text,
  } as CSSProperties,
  button: {
    color: color.secondary,
  } as CSSProperties,
  link: {
    color: color.primary,
  } as CSSProperties,
  header1: {
    color: color.text,
  } as CSSProperties,
  header2: {
    color: color.text,
  } as CSSProperties,
  header3: {
    color: color.text,
  } as CSSProperties,
  header4: {
    color: color.text,
  } as CSSProperties,
  subtitle: {
    color: color.text,
  } as CSSProperties,
  bodyStrong: {
    color: color.text,
  } as CSSProperties,
  bodyRegular: {
    color: color.text,
  } as CSSProperties,
  bodyTiny: {
    color: color.text,
  } as CSSProperties,
  bodyRegularPale: {
    color: color.text,
  } as CSSProperties,
};

export const presets = {
  default: BASE,
  header1: {
    ...BASE,
    fontSize: '2em',
    lineHeight: '2.25em',
  } as CSSProperties,
  header2: {
    ...BASE,
    fontWeight: 700,
    fontSize: '1.5em',
    lineHeight: '1.8em',
  } as CSSProperties,
  header3: {
    ...BASE,
    fontWeight: 'bold',
    fontSize: '1.17em',
    lineHeight: '1.4em',
  } as CSSProperties,
  header4: {
    ...BASE,
    fontWeight: 'bold',
  } as CSSProperties,
  subtitle: {
    ...BASE,
    fontSize: '1.2em',
    lineHeight: '1.44em',
    color: color.secondary,
  } as CSSProperties,
  bodyStrong: { ...BASE, fontWeight: 700 } as CSSProperties,
  bodyRegular: { ...BASE, fontWeight: 400 } as CSSProperties,
  bodyRegularPale: {
    ...BASE,
    color: color.textPale,
    fontWeight: 300,
  } as CSSProperties,
  bodyTiny: {
    ...BASE,
    fontSize: '0.8em',
    lineHeight: '1.2em',
  } as CSSProperties,
  button: { ...BASE, fontSize: '1.2em', lineHeight: '1.8em' } as CSSProperties,
  link: {
    ...BASE,
    textDecorationLine: 'underline',
  } as CSSProperties,
};

export type TextPresets = keyof typeof presets;
