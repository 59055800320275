import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  const { color } = props;
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.646 3.646a.5.5 0 000 .708l3.182 3.182a.5.5 0 10.708-.708L1.707 4l2.829-2.828a.5.5 0 10-.708-.708L.646 3.646zM12 3.5H1v1h11v-1z"
        fill={color}
      />
    </svg>
  );
}

export const Back = React.memo(SvgComponent);
