import React, { useCallback } from 'react';
import { useLocation } from 'react-router';
import { DashboardsHeaderIcon, HomeHeaderIcon } from 'Assets/icons/sider-icons';
import { color } from 'theme';
import { Menu, MenuItem, SelectItem, Sider } from './style';

export const SideNavigation = () => {
  const location = useLocation();

  const isSelected: (value: string | number) => boolean = useCallback(
    (one) => {
      return one === location.pathname;
    },
    [location.pathname],
  );

  return (
    <Sider>
      <Menu>
        <MenuItem to={'/'}>
          <SelectItem active={isSelected('/')} />
          <HomeHeaderIcon
            fill={isSelected('/') ? color.primary : color.secondary}
          />
        </MenuItem>

        <MenuItem to={'/dashboards'}>
          <SelectItem active={isSelected('/dashboards')} />
          <DashboardsHeaderIcon
            fill={isSelected('/dashboards') ? color.primary : color.secondary}
          />
        </MenuItem>
      </Menu>
    </Sider>
  );
};
