import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { createProjects, saveLocations } from 'Redux/projects/thunk';
import { ButtonSubmit, InputField } from 'Components/FormAuth/style';
import { CustomSelect } from 'Components/CustomSelect/CustomSelect';
import { CustomSearch } from 'Components/CustomSearch/CustomSearch';
import { Text } from 'Components/Text';
import { color } from 'theme';
import { dataOSProject, ILocation } from 'Redux/projects/type';
import {
  ArrowDown,
  CustomEmail,
  FieldWrapper,
  FormModal,
  TextAreaClear,
} from './style';

interface INewProject {
  name: string;
  oc: string;
  location: string;
  participants: string;
}

const schemaNewProject = yup.object().shape({
  name: yup.string().required('this field is required'),
  oc: yup.string(),
  location: yup.string(),
  participants: yup.string(),
});

const defaultValueNewProject = {
  name: '',
  oc: 'OC',
  location: '',
  participants: '',
};

interface IModalContentProps {
  toggle: () => void;
}

export interface ILocationProps {
  id: number;
  name: string;
}

export const ModalNewProject = ({ toggle }: IModalContentProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = useTypedSelector((state) => state.user.id);

  const {
    register,
    handleSubmit,
    errors,
    control,
    reset,
  } = useForm<INewProject>({
    resolver: yupResolver(schemaNewProject),
    defaultValues: defaultValueNewProject,
  });
  const [valueLocation, setValueLocation] = useState<ILocationProps>({
    id: null,
    name: '',
  });

  const [valueOC, setValueOC] = useState<string>('OC');
  const [valueEmail, setValueEmail] = useState<string>('');
  const [valueEmailsArrow, setValueEmailsArrow] = useState<string[]>([]);
  const [activeSearch, setActiveSearch] = useState<boolean>(false);
  const [activeSelect, setActiveSelect] = useState<boolean>(false);
  const [searchLocation, setSearchLocation] = useState<ILocation[]>([]);
  const [errorLocation, setErrorLocation] = useState('');

  const resetAllFields = () => {
    setActiveSelect(false);
    setActiveSearch(false);
    setValueEmailsArrow([]);
    setValueEmail('');
    setValueOC('OC');
    setValueLocation({ name: '', id: null });
    reset();
    toggle();
  };

  useEffect(() => {
    dispatch(
      saveLocations({
        history: (s) => history.push(s),
        setLocations: (s: ILocation[]) => setSearchLocation(s),
      }),
    );
  }, []);

  useEffect(() => {
    () => {
      resetAllFields();
    };
  });

  const handleActiveSelect = () => {
    setActiveSelect(!activeSelect);
    setActiveSearch(false);
  };

  const handleSelectOC = (value: string) => {
    setValueOC(value);
  };

  const handleCreateProject = (value: INewProject) => {
    if (!valueLocation.name || !valueLocation.id) {
      setErrorLocation('Choose location');
      return;
    }

    dispatch(
      createProjects({
        history: (s) => history.push(s),
        value: {
          ...value,
          participants: valueEmailsArrow,
          locationId: valueLocation.id,
          status: 'complete',
          oc: valueOC,
        },
        userId: userId,
      }),
    );

    resetAllFields();
  };

  const handleChengeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value[0] === ',') return setValueEmail('');
    setValueEmail(e.target.value);
  };

  const handleKeywordKeypress = (e: React.KeyboardEvent) => {
    if (e.code === 'Comma' || e.code === 'Enter' || e.code === 'Space') {
      setValueEmailsArrow([...valueEmailsArrow, valueEmail]);
      setValueEmail('');
    }
  };

  const handleClickToEmail = (email: string) => {
    setValueEmailsArrow(valueEmailsArrow.filter((e) => e !== email));
    setValueEmail(email);
  };

  return (
    <FormModal onSubmit={handleSubmit(handleCreateProject)}>
      <Controller
        control={control}
        name="name"
        render={() => (
          <InputField
            ref={register}
            error={errors.name}
            type="text"
            name="name"
            placeholder="Project Name and MSF Mission"
          />
        )}
      />

      <Controller
        control={control}
        name="oc"
        render={() => (
          <CustomSelect
            border={true}
            onSelect={(s: string) => handleSelectOC(s)}
            data={dataOSProject}
            placeholder={valueOC}
            onActive={() => handleActiveSelect()}
            active={activeSelect}
          >
            <ArrowDown />
          </CustomSelect>
        )}
      />

      <Controller
        control={control}
        name="location"
        render={() => (
          <CustomSearch
            border={true}
            data={searchLocation}
            value={valueLocation}
            setValue={(s) => setValueLocation(s)}
            onSelect={(s) => setValueLocation(s)}
            placeholder={'Location'}
            onActive={setActiveSearch}
            active={activeSearch}
          >
            <ArrowDown onClick={setActiveSearch} />
          </CustomSearch>
        )}
      />

      <label htmlFor="participants">
        <Text style={{ marginBottom: '10px' }} color={color.text}>
          Invite people to project using email separate by commas, enter, space
        </Text>
        <FieldWrapper>
          {valueEmailsArrow.map((email, index) => (
            <CustomEmail key={index} onClick={() => handleClickToEmail(email)}>
              <Text color={color.background} preset="bodyTiny">
                {email}
              </Text>
            </CustomEmail>
          ))}
          <TextAreaClear
            value={valueEmail}
            onKeyPress={handleKeywordKeypress}
            onChange={handleChengeValue}
            error={errors.participants}
            type="text"
            name="participants"
            placeholder="User email address"
          />
        </FieldWrapper>
      </label>
      {!!errorLocation && (
        <Text style={{ marginBottom: '10px' }} color={color.primary}>
          {errorLocation}
        </Text>
      )}
      <ButtonSubmit type="submit">
        <Text preset="bodyRegular">Create new project</Text>
      </ButtonSubmit>
    </FormModal>
  );
};
