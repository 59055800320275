import React from 'react';
import { useDispatch } from 'react-redux';
import { addPartDescriptionPoints } from 'Redux/form/thunk';
import {
  AddDescriptionButton,
  PoapDescriptionButton,
} from 'Components/Inputs/style';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { Header, Step } from 'Pages/form-builder/formShcema';
import { FormDescriptions } from 'Pages/form-builder/form-descriptions';
import { SvgIcon } from 'Components/svg-icon';
import { color } from 'theme';
import { TitleDescriptions } from './TitleDescriptions';
import { Point } from './style';

interface IPartDescriptionProps {
  step: Step;
  noNeedIndex: number;
  data: Header;
}

export const PartDescription = ({
  step,
  noNeedIndex,
  data,
}: IPartDescriptionProps) => {
  const dispatch = useDispatch();
  const { isEditing } = useTypedSelector(
    (state) => state.formBuilder.currentForm,
  );
  const { admin, id: userId } = useTypedSelector((state) => state.user);

  const handleAddPoint = () => {
    dispatch(addPartDescriptionPoints());
  };

  return (
    <>
      <TitleDescriptions
        isEdit={isEditing.edit && isEditing.user.id === userId}
        key={12}
        editForm={isEditing.edit}
      />
      <ul>
        {data.points.map((p) => (
          <li key={p.id}>
            <FormDescriptions
              description={p.label}
              isEdit={isEditing.edit && isEditing.user.id === userId}
              editForm={isEditing.edit && isEditing.user.id === userId}
              id={{
                descriptionIndex: p.id,
                stepId: step.id,
                inputIndex: noNeedIndex,
              }}
              type={'headerPart'}
            />
          </li>
        ))}
        {isEditing.edit && isEditing.user.id === userId && admin && (
          <AddDescriptionButton onClick={handleAddPoint}>
            <PoapDescriptionButton>Add point</PoapDescriptionButton>
            <SvgIcon width={14} height={14} icon="plus" color={color.primary} />
          </AddDescriptionButton>
        )}
      </ul>
    </>
  );
};
