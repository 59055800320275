import React from 'react';
import { Text } from 'Components/Text';
export const Filters = () => {
  return (
    <>
      <Text preset="header1">Filters</Text>
      <Text preset="header2" text="Filters" />
    </>
  );
};
