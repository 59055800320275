import React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => {
  const { color, width, height } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1747 0.677105C10.138 0.670382 10.1001 0.66687 10.0614 0.66687C10.0228 0.66687 9.98491 0.670382 9.94818 0.677105H4.73699C2.66425 0.677105 0.916992 2.35863 0.916992 4.40961V13.3571C0.916992 15.5217 2.58436 17.2213 4.73699 17.2213H11.3937C13.4401 17.2213 15.127 15.4363 15.127 13.3571V5.69877C15.127 5.5374 15.0646 5.38229 14.9528 5.26589L10.7311 0.869227C10.6133 0.746486 10.4505 0.677105 10.2803 0.677105H10.1747ZM9.43643 1.92596L4.73699 1.92663C3.34411 1.92663 2.16699 3.05946 2.16699 4.40913V13.3566C2.16699 14.8362 3.28012 15.9708 4.73699 15.9708H11.3937C12.7334 15.9708 13.877 14.7607 13.877 13.3566L13.8767 6.4861L13.1463 6.48859C12.8684 6.48826 12.5523 6.48767 12.2009 6.48687C10.7257 6.48375 9.52206 5.3281 9.44081 3.87304L9.43643 3.71604V1.92596ZM13.1925 5.23673L12.2037 5.23687C11.3651 5.2351 10.6864 4.55484 10.6864 3.71604V2.62687L13.1925 5.23673ZM7.70068 6.24231C8.0171 6.24231 8.27859 6.47744 8.31998 6.7825L8.32568 6.86731V10.8305L9.62323 9.52835C9.86679 9.28376 10.2625 9.28292 10.5071 9.52648C10.7295 9.7479 10.7504 10.0951 10.5693 10.3401L10.509 10.4104L8.14774 12.7816C8.1406 12.7889 8.13329 12.796 8.12581 12.803C7.90323 13.0101 7.56627 13.0248 7.3275 12.847L7.32075 12.8411C7.30074 12.8258 7.2817 12.8093 7.2637 12.7917L7.25732 12.7861L4.89232 10.4103C4.6488 10.1657 4.6497 9.76993 4.89434 9.52641C5.11673 9.30503 5.464 9.28565 5.70826 9.46775L5.77822 9.52843L7.07568 10.8325V6.86731C7.07568 6.52213 7.35551 6.24231 7.70068 6.24231Z"
      />
    </svg>
  );
};

export const Download = React.memo(SvgComponent);
