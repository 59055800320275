import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { color } from 'theme';

export const Sider = styled.div`
  width: 78px;
  min-height: calc(100vh - 78px);
  background-color: ${color.background};
`;

export const Menu = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  margin-top: 115px;
`;

export const MenuItem = styled(Link)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  height: 44px;
`;

export const SelectItem = styled.div`
  display: ${(p) => (p.active ? 'block' : 'none')};
  position: absolute;
  left: 0;
  height: 100%;
  width: 11px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: ${color.primary};
`;
