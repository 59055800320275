import styled, { keyframes } from 'styled-components';
import { color } from 'theme';

export const LoadingContainer = styled.div`
  position: fixed;
  background-color: rgba(255, 255, 255, 0.8);
  height: 100vh;
  width: 100vw;
  z-index: 1000000;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingWrapper = styled.div`
  width: 128px;
  height: 128px;
`;

const rotate = keyframes`
   from {
    width: 16px;
  height: 16px;
}
  to {
    width: 0px;
  height: 0px;
}
`;

export const Item = styled.span`
  position: absolute;
  top: 56px;
  left: 112px;
  background: ${color.primary};
  border-radius: 16px;
  animation: ${rotate} 1s linear infinite;
`;

export const ContainerItem = styled.div`
  width: 128px;
  height: 128px;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  margin: auto;

  &:nth-child(1) {
    transform: rotate(0deg);
  }
  &:nth-child(2) {
    transform: rotate(18deg);
  }
  &:nth-child(3) {
    transform: rotate(36deg);
  }
  &:nth-child(4) {
    transform: rotate(54deg);
  }
  &:nth-child(5) {
    transform: rotate(72deg);
  }
  &:nth-child(6) {
    transform: rotate(90deg);
  }
  &:nth-child(7) {
    transform: rotate(108deg);
  }
  &:nth-child(8) {
    transform: rotate(126deg);
  }
  &:nth-child(9) {
    transform: rotate(144deg);
  }
  &:nth-child(10) {
    transform: rotate(162deg);
  }
  &:nth-child(11) {
    transform: rotate(180deg);
  }
  &:nth-child(12) {
    transform: rotate(198deg);
  }
  &:nth-child(13) {
    transform: rotate(216deg);
  }
  &:nth-child(14) {
    transform: rotate(234deg);
  }
  &:nth-child(15) {
    transform: rotate(252deg);
  }
  &:nth-child(16) {
    transform: rotate(270deg);
  }
  &:nth-child(17) {
    transform: rotate(288deg);
  }
  &:nth-child(18) {
    transform: rotate(306deg);
  }
  &:nth-child(19) {
    transform: rotate(324deg);
  }
  &:nth-child(20) {
    transform: rotate(342deg);
  }

  &:nth-child(1) ${Item} {
    animation-delay: 0s;
  }
  &:nth-child(2) ${Item} {
    animation-delay: 0.05s;
  }
  &:nth-child(3) ${Item} {
    animation-delay: 0.1s;
  }
  &:nth-child(4) ${Item} {
    animation-delay: 0.15s;
  }
  &:nth-child(5) ${Item} {
    animation-delay: 0.2s;
  }
  &:nth-child(6) ${Item} {
    animation-delay: 0.25s;
  }
  &:nth-child(7) ${Item} {
    animation-delay: 0.3s;
  }
  &:nth-child(8) ${Item} {
    animation-delay: 0.35s;
  }
  &:nth-child(9) ${Item} {
    animation-delay: 0.4s;
  }
  &:nth-child(10) ${Item} {
    animation-delay: 0.45s;
  }
  &:nth-child(11) ${Item} {
    animation-delay: 0.5s;
  }
  &:nth-child(12) ${Item} {
    animation-delay: 0.55s;
  }
  &:nth-child(13) ${Item} {
    animation-delay: 0.6s;
  }
  &:nth-child(14) ${Item} {
    animation-delay: 0.65s;
  }
  &:nth-child(15) ${Item} {
    animation-delay: 0.7s;
  }
  &:nth-child(16) ${Item} {
    animation-delay: 0.75s;
  }
  &:nth-child(17) ${Item} {
    animation-delay: 0.8s;
  }
  &:nth-child(18) ${Item} {
    animation-delay: 0.85s;
  }
  &:nth-child(19) ${Item} {
    animation-delay: 0.9s;
  }
  &:nth-child(20) ${Item} {
    animation-delay: 0.95s;
  }
`;
