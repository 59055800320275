import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { color } from 'theme';
import { addFormDescription } from 'Redux/form/thunk';
import { Inputs } from 'Components/Inputs/Inputs';
import { SvgIcon } from 'Components/svg-icon';
import { ItselfInput } from 'Components/ItselfInput';
import {
  AddDescriptionButton,
  PoapDescriptionButton,
} from 'Components/Inputs/style';
import { PartDescription } from 'Components/PartDescription';
import { Input as InputType, Step } from './formShcema';
import { FormDescriptions } from './form-descriptions';
import { StepTitle } from './step-title';
import {
  FooterNodeWrapper,
  FormProjectContainer,
  HeaderHighlighter,
} from './style';
import { CreateInputModal } from 'Components/CreateInputModal';

export interface EntryProps {
  entry: string;
  edit: boolean;
  value: string | boolean;
  valueNumber: number;
  inputNumber: number;
}

export const RenderStep = () => {
  const noNeedIndex = 999;

  const { admin, id: userId } = useTypedSelector((state) => state.user);

  const currentStep = useTypedSelector(
    (state) => state.formBuilder.currentForm,
  );
  const dispatch = useDispatch();

  const addDescription = () => {
    dispatch(addFormDescription());
  };

  return (
    <>
      {currentStep.content.header && (
        <PartDescription
          data={currentStep.content.header}
          noNeedIndex={noNeedIndex}
          step={currentStep}
        />
      )}

      <FormProjectContainer key={currentStep.id}>
        <StepTitle
          editForm={
            currentStep.isEditing.edit &&
            currentStep.isEditing.user.id === userId
          }
          title={currentStep.content.title}
        />
        <HeaderHighlighter width={140} />
        {currentStep.content.descriptions.map(
          (formDescriptionParagraph, index) => (
            <FormDescriptions
              isEdit={
                currentStep.isEditing.edit &&
                currentStep.isEditing.user.id === userId
              }
              description={formDescriptionParagraph}
              key={index}
              editForm={
                currentStep.isEditing.edit &&
                currentStep.isEditing.user.id === userId
              }
              id={{
                descriptionIndex: index,
                stepId: currentStep.id,
                inputIndex: noNeedIndex,
              }}
              type="form"
            />
          ),
        )}
        {admin &&
          currentStep.isEditing.edit &&
          currentStep.isEditing.user.id === userId && (
            <AddDescriptionButton onClick={addDescription}>
              <PoapDescriptionButton>Add description</PoapDescriptionButton>
              <SvgIcon
                width={14}
                height={14}
                icon="plus"
                color={color.primary}
              />
            </AddDescriptionButton>
          )}

        {currentStep.content.inputs.map((input: InputType, inx) => (
          <Inputs
            key={inx}
            stepId={currentStep.id}
            indexInput={inx}
            input={input}
          />
        ))}
        {/*{currentStep.isEditing.edit &&*/}
        {/*  currentStep.isEditing.user.id === userId &&*/}
        {/*  admin && <ItselfInput />}*/}

        {currentStep.isEditing.edit &&
          currentStep.isEditing.user.id === userId &&
          admin && <CreateInputModal />}

        {currentStep.content.footerStepNote &&
          currentStep.content.footerStepNote.map((fs, index) =>
            currentStep.content.footerStepNote.length < 2 ? (
              <FooterNodeWrapper>
                <SvgIcon
                  icon="pause"
                  size={50}
                  width={50}
                  color={color.backgroundForms}
                />
                <FormDescriptions
                  style={{
                    backgroundColor: color.backgroundForms,
                  }}
                  description={fs}
                  id={{
                    descriptionIndex: index,
                    stepId: currentStep.id,
                    inputIndex: noNeedIndex,
                  }}
                  type="footerNode"
                  editForm={currentStep.isEditing.edit}
                />
              </FooterNodeWrapper>
            ) : (
              <li key={index}>
                <FooterNodeWrapper>
                  <SvgIcon
                    icon="pause"
                    size={50}
                    width={50}
                    color={color.backgroundForms}
                  />
                  <FormDescriptions
                    style={{
                      backgroundColor: color.backgroundForms,
                    }}
                    description={fs}
                    id={{
                      descriptionIndex: index,
                      stepId: currentStep.id,
                      inputIndex: noNeedIndex,
                    }}
                    type="footerNode"
                    editForm={currentStep.isEditing.edit}
                  />
                </FooterNodeWrapper>
              </li>
            ),
          )}
      </FormProjectContainer>
    </>
  );
};
