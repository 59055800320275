import React from 'react';
import LogoImg from 'Assets/images/logo.png';
import LogoHeaderImg from 'Assets/images/logo-header.png';
import { color, spacing } from 'theme';
import img from 'Assets/images/auth-img.jpg';
import { SvgIcon } from 'Components/svg-icon';
import { Text } from 'Components/Text';
import {
  WrapperPage,
  LogoCompany,
  WrapperLogoHeader,
  WrapperLogo,
  ContainerPage,
  LeftSide,
  RightSide,
  ImgRegister,
  ButtonToLogin,
  TextTitle,
  TextSubtitle,
} from './style';

interface contentAuthPageInterface {
  companyLogo: string;
  companyLogoHeader: string;
  title: string;
  subtitle: string;
}

const contentAuthPage: contentAuthPageInterface = {
  companyLogo: LogoImg,
  companyLogoHeader: LogoHeaderImg,
  title: 'Welcome',
  subtitle: 'MSF TELEHEALTH TOOLKIT PLATFORM',
};

interface IAuthContainerProps {
  toLoginButton?: boolean;
  toLogin?: () => void;
  children: React.ReactNode;
}

export const AuthContainer = ({
  toLoginButton,
  children,
  toLogin,
}: IAuthContainerProps) => {
  return (
    <WrapperPage>
      <WrapperLogoHeader>
        <LogoCompany src={contentAuthPage.companyLogoHeader} />
      </WrapperLogoHeader>
      <ContainerPage>
        <LeftSide>
          <ImgRegister src={img} alt="" />
        </LeftSide>
        <RightSide>
          {toLoginButton && (
            <ButtonToLogin onClick={toLogin || null}>
              <SvgIcon icon="back" color={color.primary} size={14} />
              <Text
                style={{ fontSize: '14px', marginLeft: spacing.tiny }}
                color={color.primary}
              >
                Back to login
              </Text>
            </ButtonToLogin>
          )}
          <WrapperLogo>
            <LogoCompany src={contentAuthPage.companyLogo} />
          </WrapperLogo>
          <TextTitle>{contentAuthPage.title}</TextTitle>
          <TextSubtitle>{contentAuthPage.subtitle}</TextSubtitle>
          {children}
        </RightSide>
      </ContainerPage>
    </WrapperPage>
  );
};
