import React, { CSSProperties } from 'react';
import { Arrow, Checkbox } from './style';

interface IProps {
  checked: boolean;
  ready?: boolean;
  current?: boolean;
  size?: string;
  colorBg?: string;
  colorBorder?: string;
}

export const CheckboxArrow = ({
  checked,
  ready,
  current,
  size,
  colorBg,
  colorBorder,
}: IProps) => {
  return (
    <Checkbox
      colorBorder={colorBorder}
      colorBg={colorBg}
      size={size}
      ready={ready}
      checked={checked}
      current={current}
    >
      <Arrow ready={ready} checked={checked} current={current} />
    </Checkbox>
  );
};
