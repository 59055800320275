import styled from 'styled-components';
import { spacing, color } from 'theme';

export const CreateProjectButton = styled.button`
  outline: none;
  cursor: pointer;
  margin-top: 20px;
  width: 238px;
  height: 118px;
  border-radius: ${spacing.tiny};
  border: 0 solid transparent;
  background-image: url("data:image/svg+xml,%3csvg 
width='100%25' 
  height='100%25' 
  xmlns='http://www.w3.org/2000/svg'%3e%3crect 
  width='100%25' 
  height='100%25' 
  fill='none'
  rx='8' 
  ry='8' 
  stroke='darkGrey'
  stroke-width='4' 
  stroke-dasharray='6%2c 14' 
  stroke-dashoffset='0' 
  stroke-linecap='square'/%3e%3c/svg%3e
");
  margin-bottom: ${spacing.small};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${color.background};

  &:hover {
    color: darkgrey;
    background-color: rgba(184, 184, 184, 0.2);
    background-image: url("data:image/svg+xml,%3csvg 
width='100%25' 
  height='100%25' 
  xmlns='http://www.w3.org/2000/svg'%3e%3crect 
  width='100%25' 
  height='100%25' 
  fill='none'
  rx='8' 
  ry='8' 
  stroke='darkgrey'
  stroke-width='4' 
  stroke-dasharray='6%2c 14' 
  stroke-dashoffset='0' 
  stroke-linecap='square'/%3e%3c/svg%3e
");
  }
`;

export const FormModal = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  height: 200px;
`;

export const ArrowDown = styled.div`
  cursor: pointer;
  position: absolute;
  top: calc(50% - 3px);
  right: 10px;
  border: solid ${(p) => p.color || color.text};
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
`;

export const FieldWrapper = styled.div`
  box-shadow: inset 0 0 0 50px #fff;
  width: 100%;
  height: 100px;
  border-radius: 10px;
  font-size: 14px;
  color: ${color.secondary};
  padding: 10px 15px;
  margin-bottom: 20px;
  outline: none;
  border: 1px solid ${(p) => (!p.error ? '#E9EFF4;' : 'red')};
`;

export const TextAreaClear = styled.textarea`
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  resize: none;
`;

export const CustomEmail = styled.div`
  margin-right: 5px;
  display: inline-block;
  padding: 4px;
  background-color: ${color.primary};
  color: ${color.background};
  border-radius: 7px;
`;

export const Plus = styled.p`
  font-size: 30px;
  margin: 0;
  color: ${color.textDarkGrey};
`;
