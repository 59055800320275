import React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => {
  const { color, width, height } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.333496 7.00969C0.333496 3.49775 3.14016 0.333008 7.0135 0.333008C10.8002 0.333008 13.6668 3.43766 13.6668 6.98966C13.6668 11.1092 10.3068 13.6663 7.00016 13.6663C5.90683 13.6663 4.6935 13.3726 3.72016 12.7984C3.38016 12.5914 3.0935 12.4378 2.72683 12.558L1.38016 12.9586C1.04016 13.0654 0.733496 12.7984 0.833496 12.4378L1.28016 10.9423C1.3535 10.7353 1.34016 10.5149 1.2335 10.3414C0.660163 9.28644 0.333496 8.13137 0.333496 7.00969ZM6.13351 7.00971C6.13351 7.48376 6.51351 7.86433 6.98685 7.871C7.46018 7.871 7.84018 7.48376 7.84018 7.01639C7.84018 6.54234 7.46018 6.16177 6.98685 6.16177C6.52018 6.1551 6.13351 6.54234 6.13351 7.00971ZM9.2068 7.01635C9.2068 7.48372 9.5868 7.87096 10.0601 7.87096C10.5335 7.87096 10.9135 7.48372 10.9135 7.01635C10.9135 6.5423 10.5335 6.16173 10.0601 6.16173C9.5868 6.16173 9.2068 6.5423 9.2068 7.01635ZM3.91346 7.87096C3.44679 7.87096 3.06012 7.48372 3.06012 7.01635C3.06012 6.5423 3.44012 6.16173 3.91346 6.16173C4.38679 6.16173 4.76679 6.5423 4.76679 7.01635C4.76679 7.48372 4.38679 7.86429 3.91346 7.87096Z"
        fill={color}
      />
    </svg>
  );
};

export const missMessages = React.memo(SvgComponent);
