import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useTypedSelector } from 'hooks/useTypedSelector';

export const PrivateRouteForAuth = ({ component: Component, ...rest }) => {
  const isAuth = useTypedSelector((state) => state.user.isAuth);

  return (
    <Route
      {...rest}
      render={() => {
        if (isAuth) {
          return <Redirect to="/" />;
        }
        return <Component {...rest} />;
      }}
    />
  );
};
