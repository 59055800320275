import { createAsyncThunk } from '@reduxjs/toolkit';
import { IUser, MyError } from 'Redux/user/type';
import { saveAs } from 'file-saver';
import { setCurrentForm } from 'Redux/form/thunk';
import { refreshToken } from 'Utils/refreshToken';
import { refreshTokenAction } from 'Redux/user/thunk';
import {
  assignUserPut,
  deleteParticipantFromProject,
  getAllMessages,
  getLastProject,
  getLocations,
  getProjectPdf,
  getProjects,
  postProject,
  sendEmailToUser,
  updateStatus,
} from 'Utils/Api';
import { ICreateProjectsProps } from 'Utils/Api/type';
import { toggleLoading } from 'Redux/loading/thunk';
import { ILocation, IProjects } from './type';
import { IMessagesMissed } from 'hooks/useChat/type';

export const lastTwoProjects = createAsyncThunk<
  IProjects[],
  { history: (s: string) => void },
  {
    rejectValue: MyError;
  }
>('projects/lastTwoProjects', async ({ history }, thunkAPI) => {
  if (refreshToken().needRefresh) {
    thunkAPI.dispatch(refreshTokenAction({ history: (s) => history(s) }));
  }

  const response = await getLastProject();

  if (response.status > 300) {
    return thunkAPI.rejectWithValue((await response.json()) as MyError);
  }

  return (await response.json()) as IProjects[];
});

export const setCurrentProjects = createAsyncThunk<
  { project: IProjects },
  { project: IProjects; history: (s: string) => void },
  {
    rejectValue: MyError;
  }
>('projects/setCurrentProjects', async ({ project, history }, thunkAPI) => {
  thunkAPI.dispatch(toggleLoading({ loading: true }));

  if (refreshToken().needRefresh) {
    thunkAPI.dispatch(refreshTokenAction({ history: (s) => history(s) }));
  }

  thunkAPI.dispatch(setCurrentForm({ data: project.content }));
  thunkAPI.dispatch(toggleLoading({ loading: false }));

  return { project: project };
});

export const deleteParticipant = createAsyncThunk<
  { projectId: number; userEmail: string; userId: number },
  {
    projectId: number;
    userEmail: string;
    userId: number;
    history: (s: string) => void;
  },
  {
    rejectValue: MyError;
  }
>(
  'projects/deleteParticipant',
  async ({ projectId, userEmail, userId, history }, thunkAPI) => {
    if (refreshToken().needRefresh) {
      thunkAPI.dispatch(refreshTokenAction({ history: (s) => history(s) }));
    }

    const response = await deleteParticipantFromProject(projectId, userEmail);

    if (response.status > 300) {
      return thunkAPI.rejectWithValue((await response.json()) as MyError);
    }

    return { projectId, userEmail, userId };
  },
);

export const assignUser = createAsyncThunk<
  { project: IProjects },
  { projectId: number; emailUser: string; history: (s: string) => void },
  {
    rejectValue: MyError;
  }
>(
  'projects/assignUser',
  async ({ projectId, emailUser, history }, thunkAPI) => {
    thunkAPI.dispatch(toggleLoading({ loading: true }));

    if (refreshToken().needRefresh) {
      thunkAPI.dispatch(refreshTokenAction({ history: (s) => history(s) }));
    }
    const response = await assignUserPut(emailUser, projectId);

    if (response.status > 300) {
      thunkAPI.dispatch(toggleLoading({ loading: false }));
      return thunkAPI.rejectWithValue((await response.json()) as MyError);
    }

    const data = await response.json();

    thunkAPI.dispatch(saveAllProjects({ history }));

    thunkAPI.dispatch(
      setCurrentProjects({ project: data, history: (s) => history(s) }),
    );

    history(`/project/${projectId}`);

    return data;
  },
);

export const saveAllProjects = createAsyncThunk<
  IProjects[],
  { history: (s: string) => void },
  {
    rejectValue: MyError;
  }
>('projects/saveAllProjects', async ({ history }, thunkAPI) => {
  thunkAPI.dispatch(toggleLoading({ loading: true }));
  if (refreshToken().needRefresh) {
    thunkAPI.dispatch(refreshTokenAction({ history: (s) => history(s) }));
  }

  const response = await getProjects();

  if (response.status > 300) {
    thunkAPI.dispatch(toggleLoading({ loading: false }));
    return thunkAPI.rejectWithValue((await response.json()) as MyError);
  }

  const data: IProjects[] = await response.json();
  thunkAPI.dispatch(toggleLoading({ loading: false }));
  return data;
});

export const createProjects = createAsyncThunk<
  { project: IProjects },
  ICreateProjectsProps,
  {
    rejectValue: MyError;
  }
>(
  'projects/createProjects',
  async ({ value, userId, history }: ICreateProjectsProps, thunkAPI) => {
    thunkAPI.dispatch(toggleLoading({ loading: true }));
    if (refreshToken().needRefresh) {
      thunkAPI.dispatch(refreshTokenAction({ history: (s) => history(s) }));
    }

    const response = await postProject({ value: value, userId: userId });

    if (response.status > 300) {
      thunkAPI.dispatch(toggleLoading({ loading: false }));
      return thunkAPI.rejectWithValue((await response.json()) as MyError);
    }

    const data: IProjects = await response.json();

    thunkAPI.dispatch(
      setCurrentProjects({ project: data, history: (s) => history(s) }),
    );

    history(`/project/${data.id}`);
    thunkAPI.dispatch(toggleLoading({ loading: false }));
    return { project: data };
  },
);

export const sendEmailUser = createAsyncThunk<
  { user: IUser; projectId: number },
  { email: string; projectId: number; history: (s: string) => void },
  {
    rejectValue: MyError;
  }
>('projects/sendEmailUser', async ({ email, projectId, history }, thunkAPI) => {
  thunkAPI.dispatch(toggleLoading({ loading: true }));

  if (refreshToken().needRefresh) {
    thunkAPI.dispatch(refreshTokenAction({ history: (s) => history(s) }));
  }

  const response = await sendEmailToUser(email, projectId);
  if (response.status > 300) {
    thunkAPI.dispatch(toggleLoading({ loading: false }));
    return thunkAPI.rejectWithValue((await response.json()) as MyError);
  }

  const data = await response.json();

  thunkAPI.dispatch(saveAllProjects({ history: (s) => history(s) }));
  thunkAPI.dispatch(toggleLoading({ loading: false }));

  return { user: data, projectId: projectId };
});

export const saveLocations = createAsyncThunk<
  void,
  { setLocations: (s: ILocation[]) => void; history: (s: string) => void },
  {
    rejectValue: MyError;
  }
>('projects/saveLocations', async ({ setLocations, history }, thunkAPI) => {
  if (refreshToken().needRefresh) {
    thunkAPI.dispatch(refreshTokenAction({ history: (s) => history(s) }));
  }

  const response = await getLocations();

  if (response.status > 300) {
    thunkAPI.dispatch(toggleLoading({ loading: false }));
    return thunkAPI.rejectWithValue((await response.json()) as MyError);
  }

  const data = await response.json();
  setLocations(data);
});

export const downloadProject = createAsyncThunk<
  void,
  { projectId: number; history: (s: string) => void },
  {
    rejectValue: MyError;
  }
>('projects/downloadProject', async ({ projectId, history }, thunkAPI) => {
  thunkAPI.dispatch(toggleLoading({ loading: true }));
  if (refreshToken().needRefresh) {
    thunkAPI.dispatch(refreshTokenAction({ history: (s) => history(s) }));
  }

  const response = await getProjectPdf(projectId);

  if (response.status > 300) {
    thunkAPI.dispatch(toggleLoading({ loading: false }));
    return thunkAPI.rejectWithValue((await response.json()) as MyError);
  }

  const pdfBlob = await response.blob();

  saveAs(pdfBlob, 'newPdf.pdf');
  thunkAPI.dispatch(toggleLoading({ loading: false }));
});

export const updateStatusProject = createAsyncThunk<
  void,
  {
    projectId: number;
    status: 'develop' | 'complete';
    history: (s: string) => void;
  },
  {
    rejectValue: MyError;
  }
>(
  'projects/updateStatusProject',
  async ({ projectId, status, history }, thunkAPI) => {
    thunkAPI.dispatch(toggleLoading({ loading: true }));

    if (refreshToken().needRefresh) {
      thunkAPI.dispatch(toggleLoading({ loading: false }));
      thunkAPI.dispatch(refreshTokenAction({ history: (s) => history(s) }));
    }

    const response = await updateStatus(projectId, status);

    if (response.status > 300) {
      return thunkAPI.rejectWithValue((await response.json()) as MyError);
    }

    history('/');

    thunkAPI.dispatch(toggleLoading({ loading: false }));
  },
);

export const getAllMessagesForProject = createAsyncThunk<
  void,
  {
    projectId: number;
    history: (s: string) => void;
    setMessagesMissed: (s: IMessagesMissed[]) => void;
  },
  {
    rejectValue: MyError;
  }
>(
  'projects/getAllMessagesForProject',
  async ({ projectId, history, setMessagesMissed }, thunkAPI) => {
    // thunkAPI.dispatch(toggleLoading({ loading: true }));
    if (refreshToken().needRefresh) {
      // thunkAPI.dispatch(toggleLoading({ loading: false }));
      thunkAPI.dispatch(refreshTokenAction({ history: (s) => history(s) }));
    }

    const response = await getAllMessages(projectId);

    if (response.status > 300) {
      // thunkAPI.dispatch(toggleLoading({ loading: false }));
      return thunkAPI.rejectWithValue((await response.json()) as MyError);
    }
    const data = await response.json();

    setMessagesMissed(data);

    // thunkAPI.dispatch(toggleLoading({ loading: false }));
  },
);
