export const color = {
  primary: '#ff0000',
  secondary: '#666666',
  background: '#ffffff',
  backgroundSecondary: '#F6F8FA',
  backgroundPaginator: '#E9EFF4',
  backgroundForms: '#BEEEE8',
  text: '#000000',
  textPale: '#646777',
  success: '#009C44',
  accent: '#0081FF',
  chatBackground: '#F2F4F7',
  backgroundApp: '#E5E5E5',
  textDarkGrey: '#666666',
};

export type Color = typeof color;
