/* eslint-disable no-param-reassign */
import { createReducer, current } from '@reduxjs/toolkit';
import { optionsInput, Step } from 'Pages/form-builder/formShcema';
import {
  addFormDescription,
  deleteFormDescription,
  editFormDescription,
  saveTextDescription,
  editFormTitleAndText,
  addInputDescription,
  editInputDescription,
  deleteInputDescription,
  addInputDescriptionFooter,
  deleteFooterDescription,
  createInputs,
  deleteInput,
  changeInput,
  createTable,
  changeTable,
  changeReady,
  changeValueInput,
  changePartDescriptionPoints,
  deletePartDescriptionTitle,
  changePartDescriptionTitle,
  deletePartDescriptionPoints,
  addPartDescriptionPoints,
  editFooterDescription,
  editFooterNode,
  deleteFooterNode,
  setCurrentForm,
  saveForm,
  deleteStep,
  createNewStep,
  editFormsLocal,
  saveFormAfterEditing,
} from './thunk';

interface InitialState {
  error: string | null;
  forms: Step[];
  currentForm: Step;
}

const initialState: InitialState = {
  error: null,
  forms: [],
  currentForm: {
    position: 0,
    content: {
      name: '',
      title: {
        head: '',
        text: '',
      },
      id: 0,
      descriptions: [],
      inputs: [],
      footerStepNote: null,
      ready: false,
      edit: false,
    },
    isDone: false,
    isEditing: {
      edit: false,
      user: null,
      id: 0,
    },
    id: 0,
  },
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setCurrentForm.fulfilled, (state, { payload }) => {
      state.forms = payload;
      state.currentForm = payload[0];
    })
    .addCase(saveTextDescription, (state) => {
      state.error = null;
    })
    .addCase(editFormDescription, (state: InitialState, { payload }) => {
      const { descriptionIndex, content } = payload;

      state.currentForm.content.descriptions[descriptionIndex] = content;
    })
    .addCase(deleteFormDescription, (state: InitialState, { payload }) => {
      const { descriptionIndex } = payload;
      const { currentForm } = current(state);
      state.currentForm.content.descriptions = currentForm.content.descriptions.filter(
        (el, idx) => idx !== descriptionIndex,
      );
    })
    .addCase(addFormDescription, (state: InitialState) => {
      const { currentForm } = current(state);
      state.currentForm.content.descriptions = [
        ...currentForm.content.descriptions,
        'edit this line to add content here',
      ];
    })
    .addCase(addInputDescriptionFooter, (state: InitialState, { payload }) => {
      const { currentForm } = current(state);
      state.currentForm.content.inputs[payload.index].footerInputTexts = [
        ...currentForm.content.inputs[payload.index].footerInputTexts,
        'edit this line to add content here',
      ];
    })
    .addCase(editFormTitleAndText, (state: InitialState, { payload }) => {
      state.currentForm.content.title.head = payload.head;
      state.currentForm.content.title.text = payload.text;
    })
    .addCase(addInputDescription, (state: InitialState, { payload }) => {
      const { currentForm } = current(state);
      state.currentForm.content.inputs[payload.index].descriptions = [
        ...currentForm.content.inputs[payload.index].descriptions,
        'edit this mock input description',
      ];
    })
    .addCase(editInputDescription, (state: InitialState, { payload }) => {
      const { currentForm, forms } = current(state);
      state.currentForm.content.inputs[payload.inputIndex].descriptions[
        payload.descriptionIndex
      ] = payload.value;
      const indexCurrentForm = forms.findIndex((x) => x.id === currentForm.id);

      state.currentForm.content.inputs[payload.inputIndex].descriptions[
        payload.descriptionIndex
      ] = payload.value;
    })
    .addCase(editFooterDescription, (state: InitialState, { payload }) => {
      const { currentForm, forms } = current(state);
      state.currentForm.content.inputs[payload.inputIndex].footerInputTexts[
        payload.descriptionIndex
      ] = payload.value;

      const indexCurrentForm = forms.findIndex((x) => x.id === currentForm.id);
      state.currentForm.content.inputs[payload.inputIndex].footerInputTexts[
        payload.descriptionIndex
      ] = payload.value;
    })
    .addCase(editFooterNode, (state: InitialState, { payload }) => {
      state.currentForm.content.footerStepNote[payload.footerIndex] =
        payload.value;
    })
    .addCase(deleteInputDescription, (state: InitialState, { payload }) => {
      const { inputIndex, descriptionIndex } = payload;

      state.currentForm.content.inputs[
        inputIndex
      ].descriptions = state.currentForm.content.inputs[
        inputIndex
      ].descriptions.filter((x, index) => index !== descriptionIndex);
    })
    .addCase(deleteFooterNode, (state: InitialState) => {
      const { currentForm, forms } = current(state);

      const indexCurrentForm = forms.findIndex((x) => x.id === currentForm.id);
      state.forms[indexCurrentForm].content.footerStepNote = null;

      state.currentForm.content.footerStepNote = null;
    })
    .addCase(deleteFooterDescription, (state: InitialState, { payload }) => {
      const { inputIndex, descriptionIndex } = payload;

      state.currentForm.content.inputs[
        inputIndex
      ].footerInputTexts = state.currentForm.content.inputs[
        inputIndex
      ].footerInputTexts.filter((x, index) => index !== descriptionIndex);
    })
    .addCase(createInputs, (state: InitialState, { payload }) => {
      const { type, values } = payload;
      const { currentForm } = current(state);
      state.currentForm.content.inputs = [
        ...currentForm.content.inputs,
        {
          descriptions: payload?.descriptions || [],
          type: type,
          values: values.map((x) => ({
            label: x,
            value: type === optionsInput.input ? '' : false,
          })),
          footerInputTexts: payload?.footerInputTexts || [],
        },
      ];
    })
    .addCase(changeTable, (state: InitialState, { payload }) => {
      const { inputIndex, data } = payload;
      const descriptions: string[] = [];
      const footerInputTexts: string[] = [];
      state.currentForm.content.inputs[inputIndex] = {
        descriptions: descriptions,
        type: optionsInput.table,
        values: [
          {
            label: 'for Type = table should be only one damn string in array',
            value: false,
          },
        ],
        tableData: data,
        footerInputTexts: footerInputTexts,
      };
    })
    .addCase(createTable, (state: InitialState, { payload }) => {
      const { type, tableData, descriptions, footerInputTexts } = payload;
      const { currentForm } = current(state);
      state.currentForm.content.inputs = [
        ...currentForm.content.inputs,
        {
          descriptions: descriptions,
          type: type,
          values: [
            {
              label: 'for Type = table should be only one damn string in array',
              value: false,
            },
          ],
          tableData: tableData,
          footerInputTexts: footerInputTexts,
        },
      ];
    })
    .addCase(deleteInput, (state: InitialState, { payload }) => {
      const { currentForm, forms } = current(state);
      if (!forms) return;
      state.forms = forms?.map((form) => {
        if (form.id === currentForm.id) {
          const currentInput = form.content.inputs.filter(
            (x, i) => i !== payload.index,
          );
          return { ...form, inputs: [...currentInput] };
        }
        return form;
      });
      state.currentForm.content.inputs = currentForm.content.inputs.filter(
        (x, i) => i !== payload.index,
      );
    })
    .addCase(changeInput, (state: InitialState, { payload }) => {
      const { inputIndex, data } = payload;
      state.currentForm.content.inputs[inputIndex] = {
        descriptions: data.descriptions,
        type: data.type,
        values: data.values,
        // values: [{ id: 1, label: 'LABEL', value: 'value' }],
        footerInputTexts: data.footerInputTexts,
      };
    })
    .addCase(editFormsLocal, (state: InitialState, { payload }) => {
      const { currentForm, forms } = current(state);
      if (!forms) return;

      const indexCurrentForm = forms.findIndex((s) => s.id === currentForm.id);
      state.forms[indexCurrentForm].content.edit = !currentForm.isEditing.edit;
      state.forms[indexCurrentForm].isEditing.edit = !currentForm.isEditing
        .edit;
      state.forms[indexCurrentForm].isEditing.user = payload.user;

      state.currentForm.content.edit = !currentForm.isEditing.edit;
      state.currentForm.isEditing.edit = !currentForm.isEditing.edit;
      state.currentForm.isEditing.user = payload.user;
    })
    .addCase(changeReady, (state: InitialState) => {
      const { currentForm, forms } = current(state);
      if (!forms) return;

      const indexCurrentForm = forms.findIndex((s) => s.id === currentForm.id);

      state.forms[indexCurrentForm].content.ready = !currentForm.content.ready;
      state.forms[indexCurrentForm].isDone = !currentForm.content.ready;

      state.currentForm.isDone = !currentForm.content.ready;

      state.currentForm.content.ready = !currentForm.content.ready;
    })
    .addCase(changeValueInput, (state: InitialState, { payload }) => {
      const { valueNumber, inputNumber, value } = payload;
      const { currentForm } = current(state);
      state.forms.filter((x) => x.id === currentForm.id)[0].content.inputs[
        inputNumber
      ].values[valueNumber].value = value;
      state.currentForm.content.inputs[inputNumber].values[
        valueNumber
      ].value = value;
    })
    .addCase(
      changePartDescriptionPoints,
      (state: InitialState, { payload }) => {
        const { valueNumber, value } = payload;
        const { currentForm } = current(state);
        const id = currentForm.content.header.points.findIndex(
          (x) => x.id === valueNumber,
        );
        state.currentForm.content.header.points[id] = {
          id: valueNumber,
          label: value,
        };
      },
    )
    .addCase(
      deletePartDescriptionPoints,
      (state: InitialState, { payload }) => {
        const { valueNumber } = payload;
        const { currentForm } = current(state);

        state.currentForm.content.header.points = currentForm.content.header.points.filter(
          (x) => x.id !== valueNumber,
        );
      },
    )
    .addCase(deletePartDescriptionTitle, (state: InitialState) => {
      state.currentForm.content.header.title = '';
    })
    .addCase(changePartDescriptionTitle, (state: InitialState, { payload }) => {
      const { value } = payload;

      state.currentForm.content.header.title = value;
    })
    .addCase(addPartDescriptionPoints, (state: InitialState) => {
      const { currentForm } = current(state);

      state.currentForm.content.header.points = [
        ...currentForm.content.header.points,
        { id: currentForm.content.header.points.length + 1, label: '' },
      ];
    })
    .addCase(saveForm.fulfilled, (state: InitialState, { payload }) => {
      const { forms } = current(state);
      const indexCurrentForm = forms.findIndex((f) => f.id === payload.id);

      state.forms[indexCurrentForm] = payload;
      state.currentForm = payload;
    })
    .addCase(deleteStep.fulfilled, (state: InitialState, { payload }) => {
      const { forms } = current(state);

      state.forms = forms.filter((f) => f.id !== payload.pieceId);
    })
    .addCase(createNewStep.fulfilled, (state: InitialState, { payload }) => {
      const { forms } = current(state);
      state.forms = [...forms, payload];
    })
    .addCase(
      saveFormAfterEditing.fulfilled,
      (state: InitialState, { payload }) => {
        const { forms, currentForm } = current(state);
        const indexCurrentForm = forms.findIndex((f) => f.id === payload.id);
        console.log(payload);
        state.forms[indexCurrentForm] = payload;

        if (currentForm.id === payload.id) {
          state.currentForm = payload;
        }
      },
    );
});
